import React, { useState, useContext } from "react";
import {
    Card,
    CardContent,
    CircularProgress,
    makeStyles,
    Divider
} from "@material-ui/core";
import { OfficeContext } from "../../OfficeContext";
import { BillingContext } from "../BillingContext";
import { appContext } from "../../../../../contexts/app";
import insyncHttps from "../../../../../insyncHttps/insyncHttps";

import CustomTextField from "../../../../CustomTextField";
import CustomButton from "../../../../CustomButton";

const useStyles = makeStyles({
    title: {
        fontSize: 22,
        fontWeight: 'bold',
        margin: 0,
        wordBreak: 'break-all'
    },
    subTitle: {
        fontSize: 16,
        fontWeight: 'normal',
        margin: 0,
        color: '#979797',
        marginLeft: 30
    },
    cardContainer: {
        margin: "auto",
        paddingBottom: 38,
        paddingTop: 28,
        paddingLeft: 20,
        paddingRight: 20,
        minWidth: 400,
        maxWidth: 500,
        overflowY: 'auto',
        fontFamily: 'SF Pro Display',
        '&::-webkit-scrollbar': {
            width: 4,
            height: 0,
        },
        '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#DFE2E2',
            borderRadius: 4,
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#888',
            borderRadius: 4,
        },
        '& .MuiCardContent-root': {
            padding: 0
        }
    }
});

export default React.forwardRef((props, ref) => {
    const classes = useStyles();
    const { handleClose, subscription } = props;

    const { subscriptions } = useContext(BillingContext);
    const { officeId } = useContext(OfficeContext);
    const { error } = useContext(appContext);

    const [loading, setLoading] = useState(false);
    const [errorField, setErrorField] = useState(false);
    const [quantity, setQuantity] = useState(subscription.plan_quantity);

    const subminUpdateSuscription = () => {
        const subscriptionId = subscription.id
        setLoading(true);
        if (quantity === '') {
            setErrorField(true)
            setLoading(false);
        } else {
            insyncHttps.billing
                .changeSubscriptionQuantity({
                    officeId,
                    subscriptionId,
                    quantity
                })
                .then(result => {
                    setLoading(false);
                    handleClose();
                    subscriptions.getSubs();
                })
                .catch(err => {
                    setLoading(false);
                    console.log(err);
                    error.setMessage(err.message);
                });
        }
    }

    const subminDeleteSuscription = () => {
        const subscriptionId = subscription.id
        setLoading(true);
        insyncHttps.billing
            .cancelSubscription({
                subscriptionId,
                officeId
            })
            .then(result => {
                setLoading(false);
                subscriptions.getSubs();
                handleClose();
            })
            .catch(err => {
                setLoading(false);
                error.setMessage(err.message);
            });
    }

    return (
        <Card className={classes.cardContainer}>
            <CardContent>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginLeft: 25,
                        marginRight: 25
                    }}
                >
                    <p style={{ marginBottom: 23 }} className={classes.title}>
                        Update subscription
                    </p>
                    <p
                        style={{ marginBottom: 23, cursor: 'pointer' }}
                        onClick={handleClose}
                        className={classes.subTitle}
                    >
                        Cancel
                    </p>
                </div>
                <Divider style={{ backgroundColor: '#EDEEF0' }}/>
                <div style={{ marginBottom: 38, paddingLeft: 25, paddingRight: 25 }}>
                    <p style={{ margin: 0, marginTop: 31 }}>
                        {`Unit Price: ${(
                            subscription.plan_unit_price / 100
                        ).toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD"
                        })}`}
                    </p>
                    <p>
                        {`Total Cost: ${(
                            subscription.plan_amount / 100
                        ).toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD"
                        })}`}
                    </p>
                    {subscription.has_scheduled_changes && (
                        <p style={{ color: "#9DCD2A" }}>
                            Has scheduled changes
                        </p>
                    )}
                    <CustomTextField
                        type="number"
                        value={quantity}
                        error={quantity === '' ? errorField : false}
                        onChange={e => {
                            setQuantity(e.target.value);
                        }}
                    />
                </div>
                <Divider style={{ marginBottom: 32 }} />
                {loading ?
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress size={30} style={{ color: '#9DCD2A' }} />
                    </div>
                    :
                    <div style={{ paddingLeft: 25, paddingRight: 25 }}>
                        <CustomButton
                            label='Update Subscription'
                            width='full'
                            onClick={subminUpdateSuscription}
                        />
                        <div style={{ marginTop: 15 }}>
                            {subscription.status === "active" ?
                                <CustomButton
                                    label='Cancel Subscription'
                                    backgroundred='#F86F46'
                                    width='full'
                                    onClick={subminDeleteSuscription}
                                />
                                : ''}
                        </div>
                    </div>
                }
            </CardContent>
        </Card>
    );
});

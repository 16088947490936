import React, { useEffect, useContext } from "react";

import {
  makeStyles, CircularProgress, Grid
} from '@material-ui/core';

import { useHistory, useParams } from 'react-router-dom';
import { appContext } from '../../../../contexts/app';
import { useSubscriptions } from '../Billing/BillingContext';
import insyncHttps from "../../../../insyncHttps/insyncHttps";

const useStyles = makeStyles({
  infoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '2%'
  },
});

function OfficeExtendPlan() {
  const classes = useStyles();
  const params = useParams();
  const history = useHistory();
  const { officeId } = params;
  const { error } = useContext(appContext);
  const subscriptions = useSubscriptions(officeId);

  useEffect(() => {
    subscriptions.getSubs();
  }, [])

  useEffect(() => {
    function fetch() {
      if ((subscriptions && subscriptions.subs && subscriptions.subs.length > 0) && officeId) {
        subminPlan()
      }
    }
    fetch();
  }, [officeId, subscriptions.subs]);
  // --------------------------------------------

  const subminPlan = () => {
    const subscriptionId = subscriptions.subs && subscriptions.subs.length > 0 ? subscriptions.subs[0].id : '';
    insyncHttps.billing
      .updateSubscription({
        chargebeePlanId: 'enterprise-free-trial',
        planQuantity: 1,
        planId: 'F6yuWWPr61GDrTK931vL',
        officeId,
        subscriptionId,
        renew: true
      })
      .then(result => {
        history.replace(`/office/${officeId}`);
      })
      .catch(err => {
        error.setMessage(err.message);
        history.replace(`/office/${officeId}`);
      });
  }

  return (
    <Grid
      container
      style={{
        paddingBottom: 30,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 20,
      }}
    >
      <Grid
        item
        xs={11} lg={10} xl={9}
        className={classes.infoContainer}
      >
        <p style={{ fontSize: 20 }}>
          Your test time for the office is being updated...
        </p>
        <div style={{ display: 'flex', margin: 10 }}>
          <CircularProgress size={50} style={{ color: '#9DCD2A' }} />
        </div>
      </Grid>
    </Grid>
  );
}

export default OfficeExtendPlan;

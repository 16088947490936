import { isEmpty } from "ramda";
import { createContext, useState } from "react";

import insyncHttps from "../../../insyncHttps/insyncHttps";

export const PlanContext = createContext({
    planItem: {},
    loadingPlan: false,
    getPlanById: () => {},
    errorPlan: null,
});

export const usePlanId = (officeId) => {
    const [planItem, setPlanItem] = useState({});
    const [loadingPlan, setLoadingPlan] = useState(false);
    const [errorPlan, setErrorPlan] = useState(false);

    const getPlanById = () => {
        setErrorPlan(null);
        setLoadingPlan(true);
        insyncHttps.billing
            .getPlanId(officeId)
            .then(result => {
                if(result && result.data && !isEmpty(result.data)){
                    setLoadingPlan(false);
                    setPlanItem(result.data);
                }
            })
            .catch(err => {
                setLoadingPlan(false);
                setErrorPlan(err.message);
                setPlanItem({});
            });
    };

    return { planItem, getPlanById, errorPlan, loadingPlan };

};
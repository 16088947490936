import React, { createContext, useState } from 'react';
import firebase from '../firebase';

export const authContext = createContext({ user: null, initializing: true });

export const useAuth = () => {
  const [initializing, setInitializing] = useState(true);
  const [user, setUser] = useState(null);
  const [claims, setClaims] = useState(null);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  async function onChange(u) {
    if (u) {
      const tokenResult = await firebase
        .auth()
        .currentUser.getIdTokenResult(true);
      setClaims(tokenResult.claims);
      setUser(u);
      setInitializing(false);

      if (
        tokenResult.claims &&
        tokenResult.claims.globalRoles &&
        tokenResult.claims.globalRoles.includes('superAdmin')
      ) {
        setIsSuperAdmin(true);
      } else {
        setIsSuperAdmin(false);
      }
    } else {
      setInitializing(false);
      setUser(null);
    }
  }

  function reload() {
    return user.getIdTokenResult(true);
  }

  React.useEffect(() => {
    const unsubscribe_onAuthStateChanged = firebase
      .auth()
      .onAuthStateChanged(onChange);
    const unsubscribe_onIdTokenChanged = firebase
      .auth()
      .onIdTokenChanged(onChange);
    return () => {
      unsubscribe_onAuthStateChanged();
      unsubscribe_onIdTokenChanged();
    };
  }, []);

  return { user, claims, initializing, isSuperAdmin, reload };
};

import React, { useContext, useState, useEffect } from "react";
import { isEmpty } from 'ramda';
import moment from 'moment';
import {
   IconButton,
   CircularProgress,
   Dialog,
   Modal,
   makeStyles,
} from "@material-ui/core";
import AutorenewRoundedIcon from '@material-ui/icons/AutorenewRounded';
import DesktopMacRoundedIcon from '@material-ui/icons/DesktopMacRounded';

import { appContext } from "../../../../../contexts/app";
import { BillingContext } from "../BillingContext";
import { OfficeContext } from "../../OfficeContext";
import { PlanContext } from "../../PlanContext";
import insyncHttps from "../../../../../insyncHttps/insyncHttps";

import CustomBannerPlan from "../../../../CustomBannerPlan";
import CustomDialogActions from "../../../../CustomDialogActions";
import CustomButton from "../../../../CustomButton";
import AddSubscription from "./AddSubscription";
import SubscriptionDetails from "./SubscriptionDetails";
import ErrorStateDisplay from "../../../../ErrorStateDisplay/ErrorStateDisplay";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
   infoContainer: {
      borderRadius: 4,
      paddingTop: 34,
      paddingBottom: 34,
      paddingLeft: 30,
      paddingRight: 26,
      background: 'white',
      border: '1px solid #EDEEF0',
      marginTop: 30,
      userSelect: 'none',
      fontFamily: 'SF Pro Display'
   },
   title: {
      fontSize: 24,
      fontWeight: 'bold',
      margin: 0,
      wordBreak: 'break-all'
   },
   subTitle: {
      fontSize: 16,
      fontWeight: 'normal',
      marginTop: 5,
      marginBottom: 5,
      marginLeft: 0,
      marginRight: 0,
      color: '#979797'
   },
   numberSub: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 25,
      width: 25,
      background: '#F7F8F9',
      color: '#9DA2B4',
      borderRadius: 15,
      marginRight: 10
   },

});

export default function Subscriptions(props) {
   const classes = useStyles();
   const history = useHistory();
   const { subscriptions } = useContext(BillingContext);
   const { officeId } = useContext(OfficeContext);
   const { error } = useContext(appContext);

   // ------------------------------------------
   const { getPlanById, planItem, loadingPlan } = useContext(PlanContext);
   const currentDate = moment(new Date()).format('YYYY/MM/DD');
   // ------------------------------------------

   const { subs, loading } = subscriptions;
   const [addSubDialogOpen, setAddSubDialogOpen] = useState(false);
   const [viewedSubscription, setViewedSubscription] = useState(null);

   const [loadingAction, setLoadingAction] = useState(false);
   const [dialogConfirmation, setDialogConfirmation] = useState(false);
   const [cancelSubscription, setCancelSubscription] = useState(null);

   useEffect(() => {
      getPlanById();
   }, []);

   const subminDeleteSuscription = () => {
      const subscriptionId = cancelSubscription.id
      setLoadingAction(true);
      insyncHttps.billing
         .cancelSubscription({
            subscriptionId,
            officeId
         })
         .then(result => {
            setCancelSubscription(null)
            setLoadingAction(false);
            subscriptions.getSubs();
         })
         .catch(err => {
            setCancelSubscription(null)
            setLoadingAction(false);
            error.setMessage(err.message);
         });
   }


   let content;
   if (subscriptions.error) {
      content = (
         <ErrorStateDisplay
            title="Error Getting Subscriptions"
            message={subscriptions.error}
            retry={subscriptions.getSubs}
         />
      );
   } else {
      content = (
         <React.Fragment>

            {loadingPlan ?
               <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <CircularProgress size={30} style={{ color: '#9DCD2A' }} />
               </div>
               :
               <div>
                  {!isEmpty(planItem) && (
                     <div>
                        {planItem.showBannerTrial && (
                           <CustomBannerPlan
                              boxBackground={'#197FE8'}
                              boxText={`${planItem.planName}`}
                              daysOff={true} // Boolean
                              textDaysOff={`${moment(planItem.trialEnds).diff(currentDate, 'days')} days left`}
                              colorDaysOff={'#358FEB'}
                              btnText={'CHOOSE A PLAN'}
                              btnOnClick={`/office/${officeId}/plans`}
                              btnTextColor={'#197FE8'}
                           />
                        )}
                     </div>
                  )}
               </div>
            }

            {loading ? (
               <div style={{ display: 'flex', marginTop: 30, justifyContent: 'center' }}>
                  <CircularProgress size={30} style={{ color: '#9DCD2A' }} />
               </div>
            ) : (
               <div>
                  {subs
                     .sort((a, b) => {
                        const ranks = {
                           active: 3,
                           non_renewing: 2
                        };
                        if (a.status !== b.status) {
                           if (
                              (ranks[a.status] || 0) >
                              (ranks[b.status] || 0)
                           ) {
                              return -1;
                           } else {
                              return 1;
                           }
                        } else {
                           if (a.id > b.id) {
                              return 1;
                           } else {
                              return -1;
                           }
                        }
                     })
                     .map(sub => {
                        return (
                           <div
                              key={sub.id}
                              style={{
                                 display: 'flex',
                                 justifyContent: 'space-between',
                                 alignItems: 'center',
                                 paddingBottom: 10,
                                 marginTop: 10,
                                 paddingTop: 10,
                                 borderTop: '1px solid #EDEEF0',
                              }}
                           >
                              <div style={{ display: 'flex', flexDirection: 'column', height: 60 }}>
                                 <p className={classes.subTitle}>{sub.plan_id}</p>
                                 <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <DesktopMacRoundedIcon style={{ color: '#979797' }} />
                                    <div className={classes.numberSub}>
                                       {sub.plan_quantity}
                                    </div>
                                    <div style={{ margin: 0, }}>
                                       {(
                                          sub.plan_unit_price / 100
                                       ).toLocaleString("en-US", {
                                          style: "currency",
                                          currency: "USD"
                                       })} =&nbsp;
                                       <strong>
                                          {(
                                             sub.plan_amount / 100
                                          ).toLocaleString("en-US", {
                                             style: "currency",
                                             currency: "USD"
                                          })}
                                       </strong>
                                    </div>
                                 </div>
                              </div>
                              <div style={{ display: 'flex', height: 60, alignItems: 'center' }}>
                                 <span
                                    style={{
                                       fontSize: 20, textTransform: 'capitalize', marginRight: 20,
                                       color: sub.status === "active" ? "#9DCD2A" : "#EC5829"
                                    }}
                                 >
                                    <strong>{sub.status}</strong>
                                 </span>
                                 <CustomButton
                                    label='View Plans'
                                    width='full'
                                    onClick={() => {
                                       history.push(`/office/${officeId}/plans`);
                                    }}
                                 />
                                 {/* <Tooltip title="Update subscription">
                                    <IconButton
                                       style={{ height: 40, width: 40 }}
                                       onClick={() => {
                                          history.push(`/office/${officeId}/plans`);
                                       }}
                                    >
                                       <CreateRoundedIcon style={{ color: '#9DA2B4' }} />
                                    </IconButton>
                                 </Tooltip> */}
                                 {/* <Tooltip title="Cancel Subscription">
                                    <IconButton
                                       style={{ width: 40, height: 40 }}
                                       onClick={() => {
                                          setCancelSubscription(sub)
                                          setDialogConfirmation(true);
                                       }}
                                    >
                                       <BlockRoundedIcon style={{ color: '#9DA2B4' }} />
                                    </IconButton>
                                 </Tooltip> */}
                              </div>
                           </div>
                        );
                     })}
               </div>
            )
            }
            {
               viewedSubscription != null && (
                  <Modal
                     open={viewedSubscription != null}
                     style={{
                        justifyContent: "center",
                        justifyItems: "center",
                        display: "flex"
                     }}
                  >
                     <SubscriptionDetails
                        handleClose={() => {
                           setViewedSubscription(null);
                        }}
                        subscription={viewedSubscription}
                     />
                  </Modal>
               )
            }
            {
               addSubDialogOpen && (
                  <Dialog
                     open={addSubDialogOpen}
                  >
                     <AddSubscription
                        handleClose={() => {
                           setAddSubDialogOpen(false);
                        }}
                     />
                  </Dialog>
               )
            }
            {
               dialogConfirmation && (
                  <Modal
                     style={{ display: "flex" }}
                     open={dialogConfirmation}
                     onClose={() => {
                        setCancelSubscription(null)
                        setDialogConfirmation(false);
                     }}
                  >
                     <CustomDialogActions
                        title="Cancel Subscription"
                        description="Are you sure you want to unsubscribe?"
                        titleAccept="Yes"
                        titleCancel="Cancel"
                        handleAction={subminDeleteSuscription}
                        handleClose={() => {
                           setCancelSubscription(null)
                           setDialogConfirmation(false);
                        }}
                        loading={loadingAction}
                     />
                  </Modal>
               )
            }
         </React.Fragment >
      );
   }
   return (
      <div className={classes.infoContainer}>
         <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <p className={classes.title}>
               Subscriptions
            </p>
            <div>
               {/* <IconButton
                  onClick={() => { setAddSubDialogOpen(true) }}
                  style={{ background: 'rgba(157, 162, 180, 0.08)', padding: 5, height: 40, width: 40 }}
               >
                  <AddRoundedIcon style={{ color: '#9DA2B4' }} fontSize='medium' />
               </IconButton> */}
               <IconButton
                  onClick={() => { subscriptions.getSubs() }}
                  style={{ padding: 5, marginRight: 10, height: 40, width: 40 }}
               >
                  <AutorenewRoundedIcon style={{ color: '#9DA2B4' }} fontSize='medium' />
               </IconButton>
            </div>
         </div>
         {content}
      </div>
   );
}

import firebase from "../../firebase";

export default function({ coupon, officeId, planId, planQuantity, chargebeePlanId, subscriptionId, renew, chargebeeCouponId }) {
    return firebase.functions().httpsCallable("allFunctions")({
        funcRole: "billingAdmin",
        funcName: "updateSubscription",
        data: {
            officeId,
            planId,
            planQuantity,
            chargebeePlanId, 
            subscriptionId,
            renew,
            coupon,
            chargebeeCouponId
        }
    });
}

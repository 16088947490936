import firebase from "../../firebase";
import { isNil, isEmpty } from "ramda";

export default function ({ email, officeId, guest }) {
    let data = {}
    if (!isNil(guest) && !isEmpty(guest)) {
        data = { email, officeId, guest }
    } else {
        data = { email, officeId }
    }
    return firebase.functions().httpsCallable("allFunctions")({
        funcRole: "officeAdmin",
        funcName: "createUser",
        data
    });
}

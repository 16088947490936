import React, { useRef, useCallback } from "react";
import ReactPlayer from "react-player";
import { Dialog, DialogTitle, DialogContent, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialog-paperWidthSm': {
      maxWidth: 1000,
      maxHeight: 620,
      width: '100%',
      height: '100%',
      overflow: 'hidden'
    },
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    padding: '4px',
  },
}));

const Player = (props) => {

  const { open, toggleModal, url } = props;
  const classes = useStyles();
  const playerRef = useRef();

  return (
    <Dialog
      open={open}
      onClose={toggleModal}
      className={classes.dialog}
    >
      <div id="scroll-dialog-title" className={classes.typeModal}>
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          onClick={toggleModal}
          className={classes.closeButton}
          style={{ background: '#fff', color: '#197fe8', border: '1px solid #197fe8' }}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <ReactPlayer
        ref={playerRef}
        className='react-player'
        controls
        playing
        url={`${url}`}
        width="100%"
        height="calc(100vh - 100px)"
      />
    </Dialog>
  );
}

export default Player;
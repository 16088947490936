import React, { useState, useContext, forwardRef, useEffect } from "react";
import { SketchPicker } from "react-color";
import {
    Card,
    CardContent,
    CardActions,
    Divider,
    CircularProgress,
    makeStyles,
    IconButton,
    Popover,
    RadioGroup,
    FormControlLabel,
    Radio,
} from "@material-ui/core";
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import { OfficeContext } from "../../OfficeContext";
import { PatientTrackingContext } from "../PatientTrackingContext";
import { appContext } from "../../../../../contexts/app";
import insyncHttps from "../../../../../insyncHttps/insyncHttps";
import { ingestEncharge } from '../../../../../utils/encharge';
import { useDashboard } from "../../../Dashboard/DashboardContext";

import CustomTextField from "../../../../CustomTextField";
import CustomButton from "../../../../CustomButton";
import { useAuth } from "../../../../../contexts/v2/auth";

const useStyles = makeStyles({
    cardContainer: {
        margin: "auto",
        paddingBottom: 38,
        paddingTop: 28,
        paddingLeft: 20,
        paddingRight: 20,
        minWidth: 400,
        maxWidth: 500,
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: 4,
            height: 0,
        },
        '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#DFE2E2',
            borderRadius: 4,
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#888',
            borderRadius: 4,
        },
        '& .MuiCardContent-root': {
            padding: 0
        }
    },
    title: {
        fontSize: 22,
        fontWeight: 'bold',
        margin: 0,
        wordBreak: 'break-all',
        fontFamily: 'SF Pro Display'
    },
    subTitle: {
        fontSize: 16,
        fontWeight: 'normal',
        margin: 0,
        color: '#979797',
        fontFamily: 'SF Pro Display'
    },
    textField: {
        fontSize: 16,
        marginBottom: 6,
        marginTop: 15,
        textAlign: 'left',
    },
    selected: {
        border: '1px solid #ECEEF2',
        height: 48,
        borderRadius: 4,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingLeft: 12,
    },
    customPopover: {
        '& .MuiPopover-paper': {
            display: 'flex',
            flexWrap: 'wrap',
            maxWidth: 280,
            justifyContent: 'center',
            paddingTop: 15,
            paddingBottom: 15,
        },
    },
    customRadio: {
        color: '#ECEEF2',
        padding: 0,
        boxShadow: 'inset 2px 4px 6px rgba(201, 203, 217, 0.15)',
        '&.MuiRadio-colorSecondary.Mui-checked': {
            color: '#197FE8',
        },
        '& .MuiRadio-colorSecondary.Mui-checked': {
            color: '#197FE8',
        },
        '&.Mui-disabled': {
            color: '#ECEEF2 !important',
        }
    },
});

const EditStage = forwardRef((props, ref) => {
    const { handleClose, data, stageId } = props;
    const classes = useStyles();

    const { user } = useAuth();
    const { error } = useContext(appContext);
    const { officeId } = useContext(OfficeContext);
    const { getPatientTracking } = useContext(PatientTrackingContext);
    const { officesMeta, getOfficeList } = useDashboard();

    const [loading, setLoading] = useState(false);
    const [errorField, setErrorField] = useState(false);
    const [stages, setStages] = useState([
        {
            name: data && data.name ? data.name : "name stage",
            backgroundColor: data && data.backgroundColor ? data.backgroundColor : `#${Math.floor(Math.random() * 16777215).toString(16)}`,
            textColor: data && data.textColor ? data.textColor : ''
        }
    ]);

    useEffect(() => {
        getOfficeList()
    }, []);

    const subminNewStage = () => {
        setLoading(true);
        if (stages[0].name === '' || stages[0].name === undefined) {
            setErrorField(true)
            setLoading(false)
        } else {
            insyncHttps.patientTracking
                .updateStage({
                    officeId,
                    stageId,
                    name: stages[0].name,
                    backgroundColor: stages[0].backgroundColor,
                    textColor: stages[0].textColor
                })
                .then(async result => {
                    // ---------------------------------------------------
                    const payloadRegister = {
                        "name": "Modified stage",
                        "user": {
                            "email": user.email,
                            // "userId": officeId,
                            "tags": "renamed_stage"
                        }
                    }
                    const [first] = Object.keys(officesMeta).map(id => id);
                    if(first === officeId){
                        await ingestEncharge(payloadRegister)
                    }
                    // ---------------------------------------------------
                    setLoading(false);
                    handleClose()
                    getPatientTracking();
                })
                .catch(err => {
                    console.error(err);
                    setLoading(false);
                    error.setMessage(err.message);
                });
        }
    }

    return (
        <Card ref={ref} className={classes.cardContainer}>
            <CardContent>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginLeft: 25,
                        marginRight: 25
                    }}
                >
                    <p style={{ marginBottom: 20 }} className={classes.title}>
                        Update stage
                    </p>
                    <p
                        style={{ marginBottom: 20, cursor: 'pointer' }}
                        onClick={handleClose}
                        className={classes.subTitle}
                    >
                        Cancel
                    </p>
                </div>
                <Divider style={{ backgroundColor: '#EDEEF0' }} />
                <div style={{ marginBottom: 46, paddingLeft: 25, paddingRight: 25, fontFamily: 'SF Pro Display' }}>
                    <p style={{ fontSize: 16, marginBottom: 10, textAlign: 'left', marginTop: 30 }}>
                        Name for you stage
                    </p>
                    {stages.map((stage, index) => {
                        return (
                            <div key={index}>
                                <Stage
                                    stage={stages[index]}
                                    handleChange={stage => {
                                        const newstages = [...stages];
                                        newstages[index] = { ...stage };
                                        setStages(newstages);
                                    }}
                                    errorField={errorField}
                                />
                                <ModifyColorStage
                                    stage={stages[index]}
                                    handleChange={stage => {
                                        const newstages = [...stages];
                                        newstages[index] = { ...stage };
                                        setStages(newstages);
                                    }}
                                />
                            </div>
                        );
                    })}
                </div>
                <Divider style={{ backgroundColor: '#EDEEF0' }} />
            </CardContent>
            <CardActions style={{ marginTop: 32, paddingLeft: 25, paddingRight: 25 }}>
                {loading ?
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress size={30} style={{ color: '#9DCD2A' }} />
                    </div>
                    :
                    <CustomButton
                        disabled={loading}
                        width='full'
                        label='submit'
                        onClick={subminNewStage}
                    />
                }
            </CardActions>
        </Card>
    );
});
export default EditStage

function Stage(props) {
    const classes = useStyles();
    const { stage, handleChange, errorField } = props;

    return (
        <div>
            <CustomTextField
                placeholder="Name stage"
                value={stage.name}
                onChange={e => {
                    stage.name = e.target.value;
                    handleChange(stage);
                }}
                error={stage.name === '' || stage.name === undefined ? errorField : null}
            />
            <div style={{ paddingLeft: 10, marginTop: 14 }}>
                <RadioGroup
                    value={stage.textColor}
                    onChange={e => {
                        stage.textColor = e.target.value;
                        handleChange(stage);
                    }}
                >
                    <FormControlLabel
                        value={"#000000"}
                        control={<Radio className={classes.customRadio} />}
                        label="Black Text"
                        style={{ marginBottom: 10 }}
                    />
                    <FormControlLabel
                        value={"#FFFFFF"}
                        control={<Radio className={classes.customRadio} />}
                        label="White Text"
                    />
                </RadioGroup>
            </div>
        </div>
    );
}


function ModifyColorStage(props) {
    const { handleChange, stage } = props;
    const classes = useStyles();
    const [changeColorAnchorEl, setChangeColorAnchorEl] = useState(null);

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between'
            }}
        >
            <div style={{ width: '100%' }}>
                <p className={classes.textField}>
                    Background color
                </p>
                <div
                    className={classes.selected}
                    onClick={e => {
                        setChangeColorAnchorEl(e.currentTarget);
                    }}
                >
                    {stage.backgroundColor ?
                        <div style={{ width: '80%', borderRadius: 7, height: 26, background: stage.backgroundColor }} />
                        : <p style={{ color: '#979797', fontSize: 14 }}>Color</p>
                    }
                    <IconButton
                        onClick={e => {
                            setChangeColorAnchorEl(e.currentTarget);
                        }}
                    >
                        <ExpandMoreRoundedIcon style={{ color: '#3A3E4B' }} />
                    </IconButton>
                </div>
                <Popover
                    anchorEl={changeColorAnchorEl}
                    open={Boolean(changeColorAnchorEl)}
                    onClose={() => {
                        setChangeColorAnchorEl(null);
                    }}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                >
                    <SketchPicker
                        width={280}
                        style={{ width: 280 }}
                        color={stage.backgroundColor}
                        onChangeComplete={color => {
                            stage.backgroundColor = color.hex;
                            handleChange(stage);
                        }}
                    />
                </Popover>
            </div>
        </div>
    );
}

import React, { useState, useEffect, useContext } from "react";
import { Grid } from '@material-ui/core';
import {
    useSubscriptions,
    BillingContext,
    usePaymentSources,
    usePlansChargebee,
    useCustomer,
    useChangeOffice,
} from "./BillingContext";
import firebase from "../../../../firebase";
import { OfficeContext } from "../OfficeContext";

import Customer from "./Customer";
import PaymentSources from "./PaymentSources";
import Subscriptions from "./Subscriptions";
import Licenses from "./Licenses";

export default function (props) {
    const [billingDocState, setBillingDocState] = useState("loading");
    const [licenseDocState, setLicenseDocState] = useState(null);
    const licenses =
        licenseDocState && licenseDocState.licenses
            ? licenseDocState.licenses
            : {};
    const { officesMeta, getOfficeList } = useChangeOffice();
    const { officeId, officeName } = useContext(OfficeContext);
    const subscriptions = useSubscriptions(officeId);
    const paymentSources = usePaymentSources(officeId);
    const customer = useCustomer(officeId);
    const plans = usePlansChargebee();

    useEffect(() => {
        subscriptions.getSubs();
        plans.getPlans();
        paymentSources.getSources();
        customer.getCustomer();
        getOfficeList();

        const unsubBilling = firebase
            .firestore()
            .doc(`/offices/${officeId}/admin/billing`)
            .onSnapshot(
                doc => {
                    if (doc.data().active) {
                        setBillingDocState("Active Office");
                    } else {
                        setBillingDocState("Inactive Office");
                    }
                },
                err => {
                    console.error(err);
                    setBillingDocState("error");
                }
            );

        const unsubLicenses = firebase
            .firestore()
            .doc(`/offices/${officeId}/admin/licenses`)
            .onSnapshot(doc => {
                setLicenseDocState(doc.data());
            });

        return () => {
            unsubBilling();
            unsubLicenses();
        };
    }, [officeId]);

    return (
        <Grid
            container
            style={{ paddingBottom: 30, marginTop: 20, }}
            justifyContent='center'
        >
            <Grid
                item
                xs={11} lg={8} xl={7}
                style={{ display: 'flex', flexDirection: 'column' }}
            >
                <BillingContext.Provider
                    value={{
                        subscriptions,
                        paymentSources,
                        plans, customer,
                        officesMeta,
                        getOfficeList,
                    }}
                >
                    <Customer
                        officeId={officeId}
                        title={officeName}
                        subheader={billingDocState}
                    />
                    <PaymentSources
                        officeId={officeId}
                    />
                    <Subscriptions
                        officeId={officeId}
                        subscriptions={subscriptions.subs}
                        loading={subscriptions.loading}
                    />
                    {/* <Licenses
                        licenses={licenses}
                        officeId={officeId}
                    /> */}

                </BillingContext.Provider>
            </Grid>
        </Grid>
    );
}

import React, { useState, useContext } from "react";
import {
    Card,
    CardContent,
    makeStyles,
    CircularProgress,
    Divider
} from "@material-ui/core";
import CreditCardRoundedIcon from '@material-ui/icons/CreditCardRounded';

import { BillingContext } from "../BillingContext";
import { appContext } from "../../../../../contexts/app";
import { OfficeContext } from "../../OfficeContext";
import insyncHttps from "../../../../../insyncHttps/insyncHttps";

import CustomSelect from '../../../../CustomSelect';
import CustomTextField from "../../../../CustomTextField";
import CustomButton from "../../../../CustomButton";

const useStyles = makeStyles({
    title: {
        fontSize: 22,
        fontWeight: 'bold',
        margin: 0,
        wordBreak: 'break-all'
    },
    subTitle: {
        fontSize: 16,
        fontWeight: 'normal',
        margin: 0,
        color: '#979797'
    },
    textField: {
        fontSize: 16,
        marginBottom: 10,
        marginTop: 20,
        textAlign: 'left',
    },
    cardContainer: {
        margin: "auto",
        paddingBottom: 38,
        paddingTop: 28,
        paddingLeft: 20,
        paddingRight: 20,
        minWidth: 400,
        maxWidth: 500,
        overflowY: 'auto',
        fontFamily: 'SF Pro Display',
        '&::-webkit-scrollbar': {
            width: 4,
            height: 0,
        },
        '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#DFE2E2',
            borderRadius: 4,
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#888',
            borderRadius: 4,
        },
        '& .MuiCardContent-root': {
            padding: 0
        }
    }
});

export default function (props) {
    const classes = useStyles();

    const { officeId } = useContext(OfficeContext);
    const { plans, subscriptions } = useContext(BillingContext);
    const { error } = useContext(appContext);
    const handleClose = props.handleClose;

    const [loading, setLoading] = useState(false);
    const [selectedPlanId, setSelectedPlanId] = useState('');
    const [plan_quantity, setPlan_quantity] = useState(1);
    const [errorField, setErrorField] = useState(false);

    const changePlan = (e) => {
        setSelectedPlanId(e.target.value);
    };

    let planPrice
    let totalPrice

    const selectedPlan = plans.plans.filter(e => e.plan.id === selectedPlanId);

    selectedPlan.map((a) => {
        totalPrice = a.plan.price * plan_quantity
        planPrice = a.plan.price
    })

    const plansOptions = plans.plans.map((t) => (
        {
            label: `${t.plan.name} - ${(t.plan.price / 100).toLocaleString("en-US", { style: "currency", currency: "USD" })}`,
            value: t.plan.id,
        }
    ));

    const subminSubscription = () => {
        setLoading(true)
        if (selectedPlanId === '' || plan_quantity === '') {
            setErrorField(true)
            setLoading(false)
        } else {
            insyncHttps.billing
                .createSubscription({
                    officeId,
                    planId: selectedPlanId,
                    planQuantity: plan_quantity
                })
                .then(async result => {
                    subscriptions.getSubs();
                    handleClose();
                    setLoading(false)
                })
                .catch(err => {
                    error.setMessage(err.message);
                    setLoading(false)
                });
        }
    }

    return (
        <Card className={classes.cardContainer}>
            <CardContent>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginLeft: 25,
                        marginRight: 25
                    }}
                >
                    <p style={{ marginBottom: 23 }} className={classes.title}>
                        Add Subscriptions
                    </p>
                    <p
                        style={{ marginBottom: 23, cursor: 'pointer' }}
                        onClick={handleClose}
                        className={classes.subTitle}
                    >
                        Cancel
                    </p>
                </div>
                <Divider style={{ backgroundColor: '#EDEEF0' }} />
                <div style={{ marginBottom: 38, paddingLeft: 25, paddingRight: 25 }}>
                    <p className={classes.textField} style={{ marginTop: 31 }}>
                        Quantity
                    </p>
                    <CustomTextField
                        placeholder="Quantity"
                        type="number"
                        value={plan_quantity}
                        onChange={e => {
                            setPlan_quantity(e.target.value);
                        }}
                        error={plan_quantity === '' ? errorField : false}
                    />
                    <p className={classes.textField}>
                        Plan
                    </p>
                    <CustomSelect
                        value={selectedPlanId}
                        name='selectedPlanId'
                        options={plansOptions}
                        onChange={changePlan}
                        noOptionsMessage={() => '- Sin resultados -'}
                        error={selectedPlanId === '' ? errorField : false}
                    />
                    <div style={{ paddingTop: 10, display: 'flex', justifyContent: 'center' }}>
                        {planPrice ?
                            <div>
                                <span style={{ color: '#9DA2B4' }}>
                                    {`${plan_quantity} *  ${(planPrice / 100).toLocaleString("en-US",
                                        { style: "currency", currency: "USD" })} = `}

                                </span>
                                <span style={{ color: '#EC5829', fontWeight: 'bold' }}>
                                    {(totalPrice / 100).toLocaleString("en-US",
                                        { style: "currency", currency: "USD" })}

                                </span>
                            </div>
                            : ''
                        }
                    </div>
                </div>
                <Divider style={{ marginBottom: 32 }} />
                {loading ?
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress size={30} style={{ color: '#9DCD2A' }} />
                    </div>
                    :
                    <div style={{ paddingLeft: 25, paddingRight: 25 }}>
                        <CustomButton
                            label='Add Subscription'
                            width='full'
                            endIcon={<CreditCardRoundedIcon style={{ color: 'white' }} />}
                            onClick={subminSubscription}
                        />
                    </div>
                }
            </CardContent>
        </Card>
    );
}

import React, { useState, useContext } from "react";
import firebase from "../../firebase";
import {
    AppBar,
    Toolbar,
    Popover,
    Avatar,
    ListItemIcon,
    MenuItem,
    Divider,
    Tab,
    Tabs,
    makeStyles,
    useMediaQuery
} from "@material-ui/core";
import { mainRouterHistory } from "../../routerHistories";
import { authContext } from "../../contexts/auth";
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import HelpOutlineRoundedIcon from '@material-ui/icons/HelpOutlineRounded';
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import ListAltRoundedIcon from '@material-ui/icons/ListAltRounded';
const useStyles = makeStyles({
    customTabs: {
        maxWidth: 800,
        '& .MuiTab-root': {
            fontFamily: 'SF Pro Display',
            textTransform: 'none',
            fontSize: 16,
            minWidth: 90,
            padding: 6
        },
        '& .MuiTabs-indicator': {
            backgroundColor: 'transparent',
        },
    }
});
export default function (props) {
    const { tabs, value, handleChange } = props;
    const classes = useStyles();
    const lgDown = useMediaQuery('(max-width:1280px)');
    const { user } = useContext(authContext);
    const [userMenu, setUserMenu] = useState(null);
    const userMenuClick = event => {
        setUserMenu(event.currentTarget);
    };
    const userMenuClose = () => {
        setUserMenu(null);
    };
    return (
        <React.Fragment>
            <AppBar
                position="relative"
                style={{ width: "100%", margin: 0, boxShadow: 'none' }}
            >
                <Toolbar
                    variant="dense"
                    disableGutters
                    style={{
                        minHeight: 104,
                        background: 'transparent',
                        position: 'absolute',
                        display: 'block',
                        justifyContent: user ? 'space-between' : 'center',
                    }}
                >
                    <a href='/'>
                        <img src='/assets/logo.svg' width='180' height='75' alt='logo' id='logo' />
                    </a>
                    {user ?
                        <div style={{ marginRight: '5%' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {tabs ?
                                    <div style={{ display: 'flex', marginRight: 15, alignItems: 'center' }}>
                                        <Tabs
                                            value={value}
                                            onChange={handleChange}
                                            className={classes.customTabs}
                                        >
                                            <Tab
                                                value={0}
                                                label="Billing"
                                                style={{ 
                                                    color: value === 0 ? '#197FE8' : '#979797', 
                                                    minWidth: lgDown ? 90 : 130 
                                                }}
                                                disableRipple={true}
                                                
                                            />
                                            <Tab
                                                value={1}
                                                label="Roles"
                                                style={{ 
                                                    color: value === 1 ? '#197FE8' : '#979797' ,
                                                    minWidth: lgDown ? 90 : 130 
                                                }}
                                                disableRipple={true}
                                            />
                                            <Tab
                                                value={2}
                                                label="Paging"
                                                style={{ 
                                                    color: value === 2 ? '#197FE8' : '#979797',
                                                    minWidth: lgDown ? 90 : 130  
                                                }}
                                                disableRipple={true}
                                            />
                                            <Tab
                                                value={3}
                                                label="Messaging"
                                                style={{ 
                                                    color: value === 3 ? '#197FE8' : '#979797',
                                                    minWidth: lgDown ? 90 : 130  
                                                }}
                                                disableRipple={true}
                                            />
                                            <Tab
                                                value={4}
                                                label="Patient Traking"
                                                style={{ 
                                                    color: value === 4 ? '#197FE8' : '#979797',
                                                    minWidth: lgDown ? 90 : 130  
                                                }}
                                                disableRipple={true}
                                            />
                                            <Tab
                                                value={5}
                                                label="Report"
                                                style={{ 
                                                    color: value === 5 ? '#197FE8' : '#979797',
                                                    minWidth: lgDown ? 90 : 130  
                                                }}
                                                disableRipple={true}
                                            />
                                        </Tabs>
                                        <div style={{ height: 33, width: 1, background: '#EDEEF0' }} />
                                    </div>
                                    :
                                    ''
                                }
                                <Avatar style={{ background: '#197FE8', textTransform: 'capitalize' }}>
                                    {user.email.slice(0, 1)}
                                </Avatar>
                                <ExpandMoreRoundedIcon
                                    style={{ color: '#197FE8', cursor: 'pointer' }}
                                    onClick={userMenuClick}
                                />
                                <Popover
                                    open={Boolean(userMenu)}
                                    anchorEl={userMenu}
                                    onClose={userMenuClose}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                >
                                    <MenuItem
                                        style={{ color: '#979797', fontFamily: 'SF Pro Display' }}
                                        onClick={() => {
                                            mainRouterHistory.push("/dashboard");
                                            setUserMenu(null);
                                        }}
                                    >
                                        <ListItemIcon style={{ minWidth: 30 }}>
                                            <ListAltRoundedIcon
                                                fontSize="small"
                                                style={{ color: '#979797' }}
                                            />
                                        </ListItemIcon>
                                        Offices
                                    </MenuItem>
                                    <Divider style={{ backgroundColor: '#EDEEF0' }} />
                                    {/* <MenuItem style={{ color: '#979797', fontFamily: 'SF Pro Display' }}>
                                        <ListItemIcon style={{ minWidth: 30 }}>
                                            <HelpOutlineRoundedIcon
                                                fontSize="small"
                                                style={{ color: '#979797' }}
                                            />
                                        </ListItemIcon>
                                        Onboarding
                                    </MenuItem> */}
                                    <MenuItem
                                        style={{ color: '#979797', fontFamily: 'SF Pro Display' }}
                                        onClick={() => {
                                            firebase.auth().signOut();
                                            mainRouterHistory.push("/");
                                            setUserMenu(null);
                                        }}
                                    >
                                        <ListItemIcon style={{ minWidth: 30 }}>
                                            <ExitToAppRoundedIcon
                                                fontSize="small"
                                                style={{ color: '#979797' }}
                                            />
                                        </ListItemIcon>
                                        Logout
                                    </MenuItem>
                                </Popover>
                            </div>
                        </div> : ''
                    }
                </Toolbar>
            </AppBar>
        </React.Fragment>
    );
}
import { objOf, isEmpty } from 'ramda';
import { createContext, useState } from 'react';
import insyncHttps from '../../../insyncHttps/insyncHttps';

export const DashboardContext = createContext({
  officesMeta: {},
  getOfficeList: () => {}
});

export const useDashboard = () => {
  const [officesMeta, setOfficesMeta] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showData, setShowData] = useState(false);

  const getOfficeList = () => {
    setError(null);
    setLoading(true);
    insyncHttps.office
      .getOfficeList()
      .then((result) => {
        if (isEmpty(result.data)) {
          setShowData(true);
        } else {
          setShowData(false);
        }
        setLoading(false);
        setOfficesMeta(result.data);
      })
      .catch((err) => {
        console.error(err);
        setError(err.message);
        setLoading(false);
      });
  };

  return { officesMeta, getOfficeList, error, loading, showData };
};

import axios from 'axios';

export async function ingestEncharge(data) {
    try {
        // const header = {
        //     headers: {
        //         'Content-Type': 'application/json',
        //         'Cache-Control': 'no-cache',
        //         'Accept': '*/*',
        //         'X-Encharge-Token': 'ukENT4996eMDaHpJaQdVnDzME',
        //     },
        // };
        // const result = await axios.post(`https://ingest.encharge.io/v1`, JSON.stringify(data), header);
        window.EncTracking.track(data);
        return true
    } catch (error) {
        return error
    }
}
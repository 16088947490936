import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

function MarketingLink() {
  const location = useLocation();
  const history = useHistory();
  const couponLink = location.pathname.slice(1, 7)

  useEffect(() => {
    if (couponLink === 'coupon') {
      const couponId = location.pathname.slice(8)
      history.replace('/dashboard', {
        from: location.pathname,
        couponId
      });
    } else {
      history.replace('/dashboard', {
        from: location.pathname
      });
    }
  }, []);

  return <React.Fragment></React.Fragment>;
}

export default MarketingLink;

import React, { useContext, useState, useEffect } from "react";
import { isEmpty, update } from "ramda";

import { makeStyles, createStyles, } from '@material-ui/core/styles';
import { Divider, Tooltip, CircularProgress } from '@material-ui/core';
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import useColumnLayout from "./useColumnLayout";
import { PatientTrackingContext } from "../PatientTrackingContext";

import CustomButton from '../../../../CustomButton';
import CustomSelect from '../../../../CustomSelect';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        boxCustomSchedule: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            marginBottom: 10
        },
        boxOccurr: {
            // backgroundColor: 'red',
            // height: 50,
            '& p': {
                padding: 0,
                marginTop: -3,
                position: 'absolute',
                fontSize: 12,
            }
        },
        selected: {
            padding: 0,
            margin: 0,
            // marginTop: 15,
            width: '100%',
            height: 40,
            backgroundColor: "#f5f5f5",

        },
        infoContainer: {
            background: 'white',
            borderRadius: 4,
            marginTop: 21,
            // maxWidth: 480,
            marginLeft: 15,
            display: 'flex',
            flexDirection: 'column',
            fontFamily: 'SF Pro Display',
            border: '1px solid #EDEEF0',
            padding: 30,
            width:'45%'
        },
        title: {
            fontSize: 22,
            fontWeight: 'bold',
            margin: 0,
            wordBreak: 'break-all',
            marginBottom: 15
        },
        subtitle: {
            fontSize: 16,
            margin: 0,
            wordBreak: 'break-all',
            marginBottom: 7,
            marginTop: 21
        },
    })
);

function SortYourQueues() {
    const columnLayoutCtx = useColumnLayout();
    const patientTracking = useContext(PatientTrackingContext);

    const classes = useStyles();
    const [disabledUpdate, setDisabledUpdate] = useState(false);
    const [loading, setLoading] = useState(false);
    const [arrTrackerColumns, setArrTrackerColumns] = useState([]);
    const [formSort, setFormSort] = useState([
        {
            level: '1',
            value: '',
            order: ''
        },
        {
            level: '2',
            value: '',
            order: ''
        },
        {
            level: '3',
            value: '',
            order: ''
        },
    ]);

    useEffect(() => {
        if (patientTracking.columnLayout && Object.keys(patientTracking.columnLayout).length > 0) {

            const data = Object.keys(patientTracking.columnLayout).filter((t) => patientTracking.columnLayout[t].enabled).map((e) => ({ ...patientTracking.columnLayout[e], color: '' }));
            const sortedColumnIds = data.sort((idA, idB) => {
                return idA.order - idB.order;
            });
            setArrTrackerColumns(sortedColumnIds)

        } else if (patientTracking.columnLayout && Object.keys(patientTracking.columnLayout).length === 0) {
            setArrTrackerColumns([]);
        }
    }, [patientTracking.columnLayout])

    useEffect(() => {
        if (patientTracking.columnSortData && patientTracking.columnSortData.length > 0) {
            setFormSort(patientTracking.columnSortData)
        }
    }, [patientTracking.columnSortData])

    const onChangeTextRoom = (name) => (event) => {

        setDisabledUpdate(false)
        const resp = formSort.filter((x) => x.value === event.target.value)
        if (resp && resp.length > 0) {
            const indexArrayForm = formSort.findIndex((x) => x.value === event.target.value)
            let newDataArrayForm = { ...resp[0], value: '', order: '' };
            const newAddForm = update(indexArrayForm, newDataArrayForm, formSort)
            // ----------------------------------------------------------
            const resulForm = newAddForm.filter((x) => x.level === name);
            const indexArrayForm3 = newAddForm.findIndex((x) => x.level === name);
            let newDataArrayForm3 = { ...resulForm[0], value: event.target.value };
            const newAddForm3 = update(indexArrayForm3, newDataArrayForm3, newAddForm)
            setFormSort(newAddForm3);
            // ----------------------------------------------------------
            const r = newAddForm3.map(e => e.value)
            const resul = arrTrackerColumns.map((x) => ({
                ...x,
                color: r.includes(x.id) ? '#197fe8' : ''
            }));
            setArrTrackerColumns(resul);
        } else {
            const resulForm = formSort.filter((x) => x.level === name);
            const indexArrayForm = formSort.findIndex((x) => x.level === name);
            let newDataArrayForm = { ...resulForm[0], value: event.target.value };
            const newAddForm = update(indexArrayForm, newDataArrayForm, formSort)
            setFormSort(newAddForm);
            // ----------------------------------------------------------
            const r = newAddForm.map(e => e.value)
            const resul = arrTrackerColumns.map((x) => ({
                ...x,
                color: r.includes(x.id) ? '#197fe8' : ''
            }));
            setArrTrackerColumns(resul);
        }

    };

    const onChangeText = (value, order) => (event) => {
        const resp = formSort.filter((x) => x.level === value)
        const indexArrayForm = formSort.findIndex((x) => x.level === value);
        let newDataArrayForm = { ...resp[0], order };
        const newAddForm = update(indexArrayForm, newDataArrayForm, formSort)
        setFormSort(newAddForm);
    }

    const handleDataSort = (form) => () => {
        setLoading(true)
        if ((!isEmpty(form[2].value) && isEmpty(form[1].value) && isEmpty(form[0].value))
            || (!isEmpty(form[2].value) && !isEmpty(form[1].value) && isEmpty(form[0].value))
            || (!isEmpty(form[2].value) && isEmpty(form[1].value) && !isEmpty(form[0].value))
            || (isEmpty(form[2].value) && !isEmpty(form[1].value) && isEmpty(form[0].value))
        ) {
            setDisabledUpdate(true)
            setLoading(false)
        } else {
            setDisabledUpdate(false)
            columnLayoutCtx.addColumnDataSort(formSort)
            setLoading(false)
        }
    }

    return (
        <div className={classes.infoContainer}>
            <p className={classes.title}>
                Sort Your Queues
            </p>
            <Divider style={{ backgroundColor: '#EDEEF0' }} />
            {formSort.map((t, index) => {
                const options = arrTrackerColumns.map((roo) => (
                    {
                        label: roo.name,
                        value: roo.id,
                    }
                ));

                return (
                    <div style={{ marginBottom: 15 }} key={index}>
                        <p className={classes.subtitle}>
                            Sort Level {t.level}
                        </p>
                        <div style={{ display: 'flex' }}>
                            <CustomSelect
                                value={t.value}
                                className={classes.selected}
                                onChange={onChangeTextRoom(t.level)}
                                options={options}
                                empty={true}
                            />
                            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10 }}>
                                <Tooltip title='Ascending' placement="top">
                                    <ExpandLessRoundedIcon
                                        style={{ cursor: 'pointer', color: t.order === 'ASC' ? '#197fe8' : ''  }}
                                        onClick={onChangeText(t.level, 'ASC')}
                                    />
                                </Tooltip>
                                <Tooltip title='Descending' placement="bottom">
                                    <KeyboardArrowDownRoundedIcon
                                        style={{ cursor: 'pointer', color: t.order === 'DESC' ? '#197fe8' : '' }}
                                        onClick={onChangeText(t.level, 'DESC')}
                                    />
                                </Tooltip>
                            </div>

                        </div>
                    </div>
                )
            }
            )}
            
            {disabledUpdate ?
                <div style={{ color: '#FF4848', textAlign: 'right', fontWeight: 500, margin: '4px 14px 14px 0', fontSize: '0.75rem' }}>
                    must complete the levels in order
                </div>
                : null
            }
            {loading ?
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress size={30} style={{ color: '#9DCD2A' }} />
                </div>
                :
                <CustomButton
                    onClick={handleDataSort(formSort)}
                    label='update'
                />
            }
        </div>
    );
}

export default SortYourQueues;

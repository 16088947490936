import { isEmpty } from "ramda";
import { createContext, useState } from "react";

import insyncHttps from "../../../../insyncHttps/insyncHttps";

export const PlanDetailsContext = createContext({
    planList: {},
    loadingPlans: false,
    getListPlans: () => {},
    errorPlans: null,
    plan: { planItem: {}, loadingPlan: false, getPlanById: () => { },  errorPlan: null },

});

export const usePlansList = () => {
    const [planList, setPlanList] = useState([]);
    const [loadingPlans, setLoadingPlans] = useState(false);
    const [errorPlans, setErrorPlans] = useState(false);

    const getListPlans = () => {
        setErrorPlans(null);
        setLoadingPlans(true);
        insyncHttps.billing
            .getPlanList()
            .then(result => {
                if(result && result.data && result.data.length > 0){
                    setLoadingPlans(false);
                    setPlanList(result.data);
                }
            })
            .catch(err => {
                setLoadingPlans(false);
                setErrorPlans(err.message);
                setPlanList([]);
            });
    };

    return { planList, getListPlans, errorPlans, loadingPlans };

};
import React, { useState } from 'react';
import moment from "moment";
import { makeStyles, Tabs, Tab, Box, Grid } from '@material-ui/core';

import Dashboard from './Report/Dashboard';
import Report from './Report/Report';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}

const useStyles = makeStyles({
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    margin: 0,
    wordBreak: 'break-all',
    fontFamily: 'SF Pro Display',
  },
  customTabs: {
    '& .MuiTab-root': {
      textTransform: 'none',
    },
    '& .MuiTabs-indicator': {
      height: 0,
      backgroundColor: 'transparent',
    }
  },
  customTab: {
    minWidth: 'fit-content',
    marginLeft: 20,
    marginRight: 20,
    borderRadius: 25,
    minHeight: 26,
    paddingTop: 0,
    paddingBottom: 0,
    fontFamily: 'SF Pro Display',
  }
});

function Access() {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [startDateFilt, setStartDateFilt] = useState(moment("0", "h").subtract(1, "d").unix() * 1000)
  const [endtDateFilt, setEndDateFilt] = useState(moment("0", "h").unix() * 1000)

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Grid
      container
      style={{ paddingBottom: 30, marginTop: 20, }}
      justifyContent='center'
    >
      <Grid
        item
        xs={11} lg={8} xl={7}
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          className={classes.customTabs}
        >
          <Tab
            value={0}
            label="Dashboard"
            className={classes.customTab}
            style={{
              background: value === 0 ? '#197FE8' : '#F8F9FB',
              color: value === 0 ? 'white' : '#979797'
            }}
          />
          <Tab
            value={1}
            label="Report"
            className={classes.customTab}
            style={{
              background: value === 1 ? '#197FE8' : '#F8F9FB',
              color: value === 1 ? 'white' : '#979797'
            }}
          />
        </Tabs>
      </Grid>
      <Grid
        item
        xs={11} lg={8}
      >
        <TabPanel value={value} index={0}>
          <Dashboard
            startDateFilt={startDateFilt}
            setStartDateFilt={setStartDateFilt}
            endtDateFilt={endtDateFilt}
            setEndDateFilt={setEndDateFilt}
          />
        </TabPanel>
      </Grid>
      <Grid
        item
        xs={11} lg={8}
      >
        <TabPanel value={value} index={1}>
          <Report startDateFilt={startDateFilt}
            setStartDateFilt={setStartDateFilt}
            endtDateFilt={endtDateFilt}
            setEndDateFilt={setEndDateFilt}
          />
        </TabPanel>
      </Grid>
    </Grid>
  );
}

export default Access;

import React, { useState, forwardRef, useEffect } from 'react';
import clsx from 'clsx';
import {
  AppBar,
  Toolbar,
  Popover,
  Avatar,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  MenuItem,
  Divider,
  makeStyles,
  Button
} from '@material-ui/core';
import { NavLink as RouterLink, useHistory } from 'react-router-dom';

import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import ListAltRoundedIcon from '@material-ui/icons/ListAltRounded';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import LocalActivityRoundedIcon from '@material-ui/icons/LocalActivityRounded';

import { useDashboard } from '../../components/pages/Dashboard/DashboardContext';
import { ingestEncharge } from '../../utils/encharge';
import CustomModalVideo from '../CustomModalVideo';

// Context

import { useAuth } from '../../contexts/v2/auth';

const useStyles = makeStyles((theme) => ({
  customTabs: {
    maxWidth: 800,
    '& .MuiTab-root': {
      fontFamily: 'SF Pro Display',
      textTransform: 'none',
      fontSize: 16,
      minWidth: 90,
      padding: 6
    },
    '& .MuiTabs-indicator': {
      backgroundColor: 'transparent'
    }
  },
  navlinks: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center'
  },
  logo: {
    flexGrow: '1',
    cursor: 'pointer'
  },
  link: {
    textDecoration: 'none',
    color: '#979797',
    padding: 6,
    fontSize: 16,
    minWidth: 125,
    fontFamily: 'SF Pro Display',
    textTransform: 'none',
    textAlign: 'center',
    maxWidth: 264,
    boxSizing: 'border-box',
    flexShrink: '0',
    fontWeight: 500,
    lineHeight: 1.75,
    whiteSpace: 'normal',
    letterSpacing: '0.02857em',
    '&:hover': {
      color: '#197FE8'
    },
    '&.active': {
      color: '#197FE8'
    }
  },
  linkBtn: {
    color: '#197FE8',
    border: 'none',
    fontSize: 16,
    fontFamily: 'SF Pro Display',
    fontWeight: 500,
    textTransform: 'none',
    lineHeight: 1.75,
    whiteSpace: 'normal',
    letterSpacing: '0.02857em',

    '&:hover': {
      color: '#197FE8',
      border: 'none',
      background: 'none'
    }
  },

  mainBox: {
    padding: 0,

    '& .MuiListItemText-root >  span, .MuiListItemText-secondary': {
      textDecoration: 'none',
      color: '#979797',
      padding: 6,
      fontSize: 16,
      minWidth: 125,
      fontFamily: 'SF Pro Display',
      textTransform: 'none',
      textAlign: 'center',

      maxWidth: 264,
      boxSizing: 'border-box',
      flexShrink: '0',
      fontWeight: 500,
      lineHeight: 1.75,
      whiteSpace: 'normal',
      letterSpacing: '0.02857em'
    },
    '&.active': {
      '& .MuiListItemText-root > span': {
        color: '#197FE8'
      }
    },
    '&:hover': {
      color: '#197FE8',
      border: 'none',
      background: 'none'
    }
  }
}));

export default function (props) {
  const { tabs, officeId } = props;
  const beacon = window.Beacon;
  const classes = useStyles();
  const history = useHistory();
  const { user, isSuperAdmin, signout } = useAuth();
  const { officesMeta, getOfficeList } = useDashboard();

  const [userMenu, setUserMenu] = useState(null);
  const [openVideo, setOpenVideo] = useState(false);

  useEffect(() => {
    getOfficeList();
  }, []);

  const userMenuClick = (event) => {
    setUserMenu(event.currentTarget);
  };

  const userMenuClose = () => {
    setUserMenu(null);
  };

  const CustomRouterLink = forwardRef((props, ref) => (
    <div ref={ref} /*style={{ flexGrow: 1 }}*/>
      <RouterLink {...props} />
    </div>
  ));

  const handleDownloadApp = async () => {
    // ---------------------------------------------------
    /**
     * @author Randall Medina
     * @description execute the function to add attribute Encharge
     */
    const payloadRegister = {
      name: 'Registered widget',
      user: {
        email: user.email,
        // "userId": props.officeId,
        tags: '_widget'
      }
    };
    const [first] = Object.keys(officesMeta).map((id) => id);
    if (first === officeId) {
      await ingestEncharge(payloadRegister);
    }
    // ---------------------------------------------------
  };

  return (
    <React.Fragment>
      <AppBar
        position="relative"
        style={{ width: '100%', margin: 0, boxShadow: 'none', zIndex: 1000 }}
      >
        <Toolbar
          variant="dense"
          disableGutters
          style={{
            minHeight: 104,
            background: 'white',
            display: 'flex',
            justifyContent: user ? 'space-between' : 'center'
          }}
        >
          <a href={user ? '/dashboard' : '/login'} style={{ marginLeft: '5%' }}>
            <img src='/assets/logo.svg' width='187' height='58' alt='logo' />
          </a>
          {user && tabs && (
            <div
              style={{ width: '100%', display: 'flex', alignItems: 'center' }}
            >
              <div className={classes.navlinks}>
                <List
                  disablePadding
                  key={'Menu'}
                  style={{ display: 'flex', marginLeft: 15 }}
                >
                  <ListItem
                    button
                    component={CustomRouterLink}
                    to={`/office/${officeId}`}
                    className={clsx('list-item', classes.mainBox)}
                    key={'Application settings'}
                    exact
                  >
                    <ListItemText primary={`Application settings`} />
                  </ListItem>
                  <ListItem
                    button
                    component={CustomRouterLink}
                    to={`/office/${officeId}/access`}
                    className={clsx('list-item', classes.mainBox)}
                    key={'Access'}
                    exact
                  >
                    <ListItemText primary={`Access`} />
                  </ListItem>
                  <ListItem
                    button
                    component={CustomRouterLink}
                    to={`/office/${officeId}/report`}
                    className={clsx('list-item', classes.mainBox)}
                    key={'Reports'}
                    exact
                  >
                    <ListItemText primary={`Reports`} />
                  </ListItem>
                  <ListItem
                    button
                    component={CustomRouterLink}
                    to={`/office/${officeId}/billing`}
                    className={clsx('list-item', classes.mainBox)}
                    key={'My account'}
                    exact
                  >
                    <ListItemText primary={`My account`} />
                  </ListItem>
                </List>
                <div style={{ display: 'flex' }}>
                  <Button
                    // href={process.env.REACT_APP_WACTH_DEMO}
                    // target="_blank"
                    role="button"
                    variant="outlined"
                    className={classes.linkBtn}
                    onClick={() => setOpenVideo(true)}
                  >
                    <PlayCircleFilledIcon style={{ marginRight: 5 }} />
                    Watch the demo
                  </Button>
                  <Button
                    href={process.env.REACT_APP_SETUP_URL}
                    target="_blank"
                    role="button"
                    variant="outlined"
                    className={classes.linkBtn}
                    onClick={handleDownloadApp}
                  >
                    <CloudDownloadIcon style={{ marginRight: 5 }} />
                    Download the App
                  </Button>
                </div>
              </div>
              <div
                style={{
                  height: 33,
                  width: 1,
                  background: '#EDEEF0',
                  marginRight: 15
                }}
              />
            </div>
          )}

          {user && (
            <div
              style={{
                marginRight: '5%',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              {/* --------------------------------------------- */}
              <Avatar
                style={{ background: '#197FE8', textTransform: 'capitalize' }}
              >
                {user.email.slice(0, 1)}
              </Avatar>
              <ExpandMoreRoundedIcon
                style={{ color: '#197FE8', cursor: 'pointer' }}
                onClick={userMenuClick}
              />
              <Popover
                open={Boolean(userMenu)}
                anchorEl={userMenu}
                onClose={userMenuClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
              >
                <MenuItem
                  style={{ color: '#979797', fontFamily: 'SF Pro Display' }}
                  onClick={() => {
                    history.push('/dashboard');
                    setUserMenu(null);
                  }}
                >
                  <ListItemIcon style={{ minWidth: 30 }}>
                    <ListAltRoundedIcon
                      fontSize="small"
                      style={{ color: '#979797' }}
                    />
                  </ListItemIcon>
                  Offices
                </MenuItem>
                {isSuperAdmin && (
                  <MenuItem
                    style={{ color: '#979797', fontFamily: 'SF Pro Display' }}
                    onClick={() => {
                      history.push('/coupons');
                      setUserMenu(null);
                    }}
                  >
                    <ListItemIcon style={{ minWidth: 30 }}>
                      <LocalActivityRoundedIcon
                        fontSize="small"
                        style={{ color: '#979797' }}
                      />
                    </ListItemIcon>
                    Coupons
                  </MenuItem>
                )}
                <Divider style={{ backgroundColor: '#EDEEF0' }} />
                <MenuItem
                  style={{ color: '#979797', fontFamily: 'SF Pro Display' }}
                  onClick={() => {
                    signout();
                    history.push('/login');
                    setUserMenu(null);
                    beacon('logout');
                  }}
                >
                  <ListItemIcon style={{ minWidth: 30 }}>
                    <ExitToAppRoundedIcon
                      fontSize="small"
                      style={{ color: '#979797' }}
                    />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Popover>
            </div>
          )}
        </Toolbar>
      </AppBar>
      {openVideo && (
        <CustomModalVideo
          open={openVideo}
          toggleModal={() => setOpenVideo(false)}
          url={`${process.env.REACT_APP_WACTH_DEMO}`}
        />
      )}
    </React.Fragment>
  );
}

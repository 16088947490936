import React, { useState, useContext, useEffect } from "react";
import {
    Card,
    CardContent,
    CardActions,
    CircularProgress,
    Modal,
    makeStyles,
    Divider,
    Switch
} from "@material-ui/core";
import { appContext } from "../../../../contexts/app";
import { OfficeContext } from "../OfficeContext";

import insyncHttps from "../../../../insyncHttps/insyncHttps";

import CustomTextField from "../../../CustomTextField";
import CustomCheckBox from "../../../CustomCheckBox";
import CustomButton from "../../../CustomButton";
import CustomSelect from "../../../CustomSelect";

const useStyles = makeStyles({
    title: {
        fontSize: 22,
        fontWeight: 'bold',
        margin: 0,
        wordBreak: 'break-all'
    },
    subTitle: {
        fontSize: 16,
        fontWeight: 'normal',
        margin: 0,
        color: '#979797'
    },
    textTitleField: {
        fontSize: 16,
        marginBottom: 10,
        marginTop: 20,
        textAlign: 'left',
        fontFamily: 'SF Pro Display',
        fontWeight: 'bold',
    },
    textField: {
        fontSize: 16,
        marginBottom: 10,
        marginTop: 20,
        textAlign: 'left',
    },
    cardContainer: {
        margin: "auto",
        paddingBottom: 38,
        paddingTop: 28,
        paddingLeft: 20,
        paddingRight: 20,
        minWidth: 400,
        maxWidth: 400,
        overflowY: 'auto',
        fontFamily: 'SF Pro Display',
        '&::-webkit-scrollbar': {
            width: 4,
            height: 0,
        },
        '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#DFE2E2',
            borderRadius: 4,
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#888',
            borderRadius: 4,
        },
        '& .MuiCardContent-root': {
            padding: 0
        }
    }
});

export default React.forwardRef((props, ref) => {

    const { plansChargebee, subscriptions } = props;
    const classes = useStyles();
    const handleClose = props.handleClose;

    const { error } = useContext(appContext);
    const { officeId } = useContext(OfficeContext);

    const [loading, setLoading] = useState(false);
    const [errorField, setErrorField] = useState(false);
    const [plansOptions, setPlansOptions] = useState([]);

    useEffect(() => {
        function fetch() {
            if (plansChargebee.plans.length > 0) {
                const data = plansChargebee.plans.map((t) => (
                    {
                        label: `${t.plan.name} - ${(t.plan.price / 100).toLocaleString("en-US", { style: "currency", currency: "USD" })}`,
                        value: t.plan.id,
                    }
                ));
                setPlansOptions(data)
            } else {
                setPlansOptions([])
            }
        }
        fetch();
    }, [plansChargebee])



    const [form, setForm] = useState({
        // order: '',
        // id: '',
        // showPlan: false,
        // personalized: true,

        chargebeePlanId: '',
        name: '',
        price: '',
        users: '',
        rooms: '',
        stages: '',
        connections: '',
        patients: '',
        unlimited_users: false,
        unlimited_rooms: false,
        unlimited_stages: false,
        unlimited_connections: false,
        unlimited_patients: true,
    });

    const handleChange = (event) => {
        if (event.target.name === 'unlimited_users') {
            setForm({ ...form, unlimited_users: !form.unlimited_users, users: '' });
        } else if (event.target.name === 'unlimited_rooms') {
            setForm({ ...form, unlimited_rooms: !form.unlimited_rooms, rooms: '' });
        } else if (event.target.name === 'unlimited_stages') {
            setForm({ ...form, unlimited_stages: !form.unlimited_stages, stages: '' });
        } else {
            // unlimited_connections
            setForm({ ...form, unlimited_connections: !form.unlimited_connections, connections: '' });
        }
    };

    const onChangeText = (event) => {
        if (event.target.name === 'price') {
            // const re = /^[0-9\b]+$/;
            const rePrice = /^[0-9]+([.])?([0-9]+)?$/;
            setForm({ ...form, [event.target.name]: rePrice.test(event.target.value) ? event.target.value : '' });
        } else {
            const numb = event.target.value.split('').filter((e) => isNaN(Number(e)))
            if (numb.length === 0) {
                setForm({ ...form, [event.target.name]: event.target.value });
            }
        }
    };

    const createNewCustomPlan= async () => {
        setLoading(true);
        if (form.chargebeePlanId === '' || form.name === '' || form.price === '' ||
            (form.users === '' && !form.unlimited_users) || (form.rooms === '' && !form.unlimited_rooms) ||
            (form.stages === '' && !form.unlimited_stages) || (form.connections === '' && !form.unlimited_connections)
        ) {
            setErrorField(true)
            setLoading(false);
        } else {
            const subscriptionId = subscriptions.subs && subscriptions.subs.length > 0 ? subscriptions.subs[0].id: '';

            insyncHttps.billing
                .createCustomPlan({ ...form, officeId, subscriptionId })
                .then(result => {
                    setLoading(false);
                    handleClose();
                })
                .catch(err => {
                    error.setMessage(err.message);
                    setLoading(false);
                });
        }
    };

    return (
        <Card className={classes.cardContainer}>
            <CardContent>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginLeft: 25,
                        marginRight: 25
                    }}
                >
                    <p style={{ marginBottom: 23 }} className={classes.title}>
                        Create Custom Plan
                    </p>
                    <p
                        style={{ marginBottom: 23, cursor: 'pointer' }}
                        onClick={handleClose}
                        className={classes.subTitle}
                    >
                        Cancel
                    </p>
                </div>
                <Divider style={{ backgroundColor: '#EDEEF0' }} />
                <div style={{ marginBottom: 20, paddingLeft: 25, paddingRight: 25 }}>
                    <p className={classes.textField}>
                        Chargebee Plan
                    </p>
                    <CustomSelect
                        value={form.chargebeePlanId}
                        name='selectedPlanId'
                        options={plansOptions}
                        onChange={e => {
                            setForm({ ...form, chargebeePlanId: e.target.value });
                        }}
                        noOptionsMessage={() => '- Sin resultados -'}
                        error={form.chargebeePlanId === '' ? errorField : false}
                    />
                    <p className={classes.textField}>
                        Name
                    </p>
                    <CustomTextField
                        name="name"
                        value={form.name}
                        placeholder=""
                        error={form.name === '' ? errorField : false}
                        onChange={e => {
                            setForm({ ...form, name: e.target.value });
                        }}
                    />
                    <p className={classes.textField}>
                        Price
                    </p>
                    <CustomTextField
                        name="price"
                        value={form.price}
                        placeholder=""
                        error={form.price === '' ? errorField : false}
                        onChange={onChangeText}
                        iconstart="$"
                    />
                    <div style={{ width: '100%' }}>
                        <p className={classes.textTitleField}>
                            Paging / Messaging
                        </p>
                        <div style={{ width: '100%' }}>
                            <p className={classes.textField}>
                                Users
                            </p>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <CustomTextField
                                    name="users"
                                    style={{ width: '25%' }}
                                    value={form.users}
                                    placeholder=""
                                    error={(form.users === '' && !form.unlimited_users) ? errorField : false}
                                    onChange={onChangeText}
                                    disabled={form.unlimited_users}
                                />
                                <div style={{ marginLeft: 15 }}>
                                    <CustomCheckBox
                                        onClick={handleChange}
                                        checked={form.unlimited_users}
                                        name='unlimited_users'
                                    />
                                    <span
                                        style={{ color: form.unlimited_users ? '#2F80ED' : '#979797' }}
                                    >
                                        Unlimited
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div style={{ width: '100%' }}>
                            <p className={classes.textField}>
                                Rooms
                            </p>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <CustomTextField
                                    name="rooms"
                                    style={{ width: '25%' }}
                                    value={form.rooms}
                                    placeholder=""
                                    error={(form.rooms === '' && !form.unlimited_rooms) ? errorField : false}
                                    onChange={onChangeText}
                                    disabled={form.unlimited_rooms}
                                />
                                <div style={{ marginLeft: 15 }}>
                                    <CustomCheckBox
                                        onClick={handleChange}
                                        checked={form.unlimited_rooms}
                                        name='unlimited_rooms'
                                    />
                                    <span
                                        style={{ color: form.unlimited_rooms ? '#2F80ED' : '#979797' }}
                                    >
                                        Unlimited
                                    </span>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div style={{ width: '100%' }}>
                        <p className={classes.textTitleField}>
                            Patient Tracking
                        </p>
                        <div style={{}}>
                            <p className={classes.textField}>
                                Tracking Stage
                            </p>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <CustomTextField
                                    name="stages"
                                    style={{ width: '25%' }}
                                    value={form.stages}
                                    placeholder=""
                                    error={(form.stages === '' && !form.unlimited_stages) ? errorField : false}
                                    onChange={onChangeText}
                                    disabled={form.unlimited_stages}
                                />
                                <div style={{ marginLeft: 15 }}>
                                    <CustomCheckBox
                                        onClick={handleChange}
                                        checked={form.unlimited_stages}
                                        name='unlimited_stages'
                                    />
                                    <span
                                        style={{ color: form.unlimited_stages ? '#2F80ED' : '#979797' }}
                                    >
                                        Unlimited
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ width: '100%' }}>
                        <p className={classes.textTitleField}>
                            Concurrent Connections
                        </p>
                        <div style={{}}>
                            <p className={classes.textField}>
                                Connections
                            </p>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <CustomTextField
                                    name="connections"
                                    style={{ width: '25%' }}
                                    value={form.connections}
                                    placeholder=""
                                    error={(form.connections === '' && !form.unlimited_connections) ? errorField : false}
                                    onChange={onChangeText}
                                    disabled={form.unlimited_connections}
                                />
                                <div style={{ marginLeft: 15 }}>
                                    <CustomCheckBox
                                        onClick={handleChange}
                                        checked={form.unlimited_connections}
                                        name='unlimited_connections'
                                    />
                                    <span
                                        style={{ color: form.unlimited_connections ? '#2F80ED' : '#979797' }}
                                    >
                                        Unlimited
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div style={{ width: '100%', display: 'flex', justifyContent: 'space-around' }}>
                        <div style={{ marginTop: 15 }}>
                            <CustomCheckBox
                                onClick={handleChange}
                                checked={form.personalized}
                                name='personalized'
                            />
                            <span
                                style={{ color: form.personalized === true ? '#2F80ED' : '#979797' }}
                            >
                                Personalized plan
                            </span>
                        </div>
                        <div style={{ marginTop: 15 }}>
                            <CustomCheckBox
                                onClick={handleChange}
                                checked={form.showPlan}
                                name='showPlan'
                            />
                            <span
                                style={{ color: form.showPlan === true ? '#2F80ED' : '#979797' }}
                            >
                                Show plan
                            </span>
                        </div>
                    </div> */}
                </div>
            </CardContent>
            <Divider style={{ marginBottom: 32 }} />
            <CardActions style={{ paddingLeft: 25, paddingRight: 25 }}>
                {loading ?
                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%', marginTop: 15 }}>
                        <CircularProgress size={30} style={{ color: '#9DCD2A' }} />
                    </div>
                    :
                    <CustomButton
                        label='Create plan'
                        width='full'
                        onClick={createNewCustomPlan}
                    />
                }
            </CardActions>
        </Card>
    );
});
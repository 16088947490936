import React, { useState, useContext, useEffect } from "react";
import {
    CardContent,
    useMediaQuery,
    IconButton,
    CircularProgress,
    makeStyles,
    Modal,
    Popover,
    ListItem,
    ListItemText,
} from "@material-ui/core";
import CreateRoundedIcon from '@material-ui/icons/CreateRounded';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import { BillingContext } from '../BillingContext'
import { appContext } from '../../../../../contexts/app';
import { OfficeContext } from "../../OfficeContext";
import ErrorStateDisplay from "../../../../ErrorStateDisplay/ErrorStateDisplay";
import insyncHttps from "../../../../../insyncHttps/insyncHttps";

import ChangeEmail from './ChangeEmail';
import UpdateOffice from './UpdateOffice';
import { isEmpty } from "ramda";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
    infoContainer: {
        borderRadius: 4,
        paddingTop: 34,
        paddingBottom: 34,
        paddingLeft: 17,
        paddingRight: 22,
        background: 'white',
        border: '1px solid #EDEEF0',
    },
    title: {
        fontSize: 24,
        fontWeight: 600,
        margin: 0,
        wordBreak: 'break-all',
        fontFamily: 'SF Pro Display'
    },
    subTitle: {
        fontSize: 16,
        fontWeight: 'normal',
        margin: 0,
        color: '#979797',
        fontFamily: 'SF Pro Display',
    },
    subTitleCode: {
        fontSize: 19,
        fontWeight: 'normal',
        margin: 0,
        color: '#979797',
        fontFamily: 'SF Pro Display',
    },
    subTitlePlan: {
        fontSize: 16,
        fontWeight: 'normal',
        margin: 0,
        color: '#979797',
        fontFamily: 'SF Pro Display',
    },
    popoverCust: {
        '& .MuiPopover-paper': {
            fontFamily: 'SF Pro Display',
            paddingTop: 10,
            paddingBottom: 10
        },
        '& .MuiTypography-root': {
            fontFamily: 'SF Pro Display',
        },
    }
});

export default function (props) {
    const classes = useStyles();
    const history = useHistory();
    const mdDown = useMediaQuery('(max-width:960px)');
    const { customer, officesMeta } = useContext(BillingContext);
    const { error } = useContext(appContext);

    const { officeId, setOfficeId } = useContext(OfficeContext);
    const [changeEmailOpen, setChangeEmailOpen] = useState(false);
    const [showOffices, setShowOffices] = useState(null);
    const [changeNameOffice, setChangeNameOffice] = useState(false);
    const [changeName, setChangeName] = useState({});
    const [loading, setLoading] = useState(false);
    const [errorField, setErrorField] = useState(false);

    useEffect(() => {
        getOfficeById(officeId)
    }, [officeId])

    const getOfficeById = (officeId) => {
        setLoading(true);
        insyncHttps.office
            .getOfficeById(officeId)
            .then(result => {
                setLoading(false);
                setChangeName(result.data);
                setChangeNameOffice(false);
            })
            .catch(err => {
                console.error(err);
                error.setMessage(err.message);
                setLoading(false);
            });
    };


    const handleOfficeListOpen = event => {
        setShowOffices(event.currentTarget);
    };

    const handleOfficeListClose = () => {
        setShowOffices(null);
    };

    //-------------------------------------------------------
    const subminEmail = (nameOfficeUp) => () => {
        setLoading(true);
        if (nameOfficeUp === '') {
            setErrorField(true)
            setLoading(false);
        } else {
            insyncHttps.office
                .updateOffice({ officeName: nameOfficeUp, officeId })
                .then(result => {
                    setLoading(false);
                    getOfficeById(officeId)

                })
                .catch(err => {
                    setLoading(false);
                    error.setMessage(err.message);
                });
        }
    }
    //-------------------------------------------------------
    const changeAccessCode = () => {
        setLoading(true);
        insyncHttps.office
            .changeAccessCode({ officeId })
            .then(result => {
                setLoading(false);
                getOfficeById(officeId)

            })
            .catch(err => {
                setLoading(false);
                error.setMessage(err.message);
            });
    }
    //-------------------------------------------------------

    let content;

    if (customer.error) {
        content = (
            <CardContent>
                <ErrorStateDisplay
                    title={"Error Getting Customer"}
                    message={customer.error}
                    retry={customer.getCustomer}
                />
            </CardContent>
        );
    } else {
        content = (
            <React.Fragment>
                {customer.loading ? (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress size={30} style={{ color: '#9DCD2A' }} />
                    </div>
                ) : (
                    customer.customer && customer.customer.customer && (
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <div style={{ marginLeft: 28 }}>
                                <p className={classes.title}>
                                    {customer.customer.customer.email}
                                </p>
                                <p className={classes.subTitle}>
                                    Billing Email
                                </p>
                            </div>
                            <IconButton
                                onClick={() => {
                                    setChangeEmailOpen(true);
                                }}
                                style={{ height: 40, width: 40, marginRight: 4 }}
                            >
                                <CreateRoundedIcon style={{ color: '#9DA2B4' }} />
                            </IconButton>
                        </div>
                    )
                )}
                <Modal
                    style={{ display: "flex" }}
                    open={changeEmailOpen}
                    disableAutoFocus={true}
                >
                    <ChangeEmail
                        emailUser={customer && customer.customer && customer.customer.customer.email}
                        handleClose={() => {
                            setChangeEmailOpen(false);
                        }}
                    />
                </Modal>
            </React.Fragment>
        );
    }

    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginTop: 30,
                flexDirection: mdDown ? 'column' : 'row'
            }}
        >
            <div
                style={{
                    width: mdDown ? '95%' : '32%',
                    display: 'flex',
                    justifyContent: 'space-between'
                }}
                className={classes.infoContainer}
            >
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton
                        style={{ height: 40, width: 40 }}
                        onClick={() => {
                            setChangeNameOffice(true);
                        }}
                    >
                        <CreateRoundedIcon style={{ color: '#9DA2B4' }} />
                    </IconButton>
                    <div>
                        <p className={classes.title}>
                            {changeName.name}
                        </p>
                        {!isEmpty(changeName) && changeName.accessCode && changeName.accessCode !== '' && (
                            <p className={classes.subTitleCode}>
                                Access code: <span style={{ color: '#000', fontWeight: "bold" }}>{changeName.accessCode}</span>
                            </p>
                        )}
                        {/* {!isEmpty(changeName) && changeName.currentPlan && changeName.currentPlan !== '' && (
                            <p className={classes.subTitlePlan}>
                                Current Plan: <span style={{ color: '#000', fontWeight: "bold" }}>{changeName.currentPlan}</span>
                            </p>
                        )} */}
                        {!isEmpty(changeName) && changeName.currentPlanChargebee && changeName.currentPlanChargebee !== '' && (
                            <p className={classes.subTitlePlan}>
                                Current Plan: <span style={{ color: '#000', fontWeight: "bold" }}>{changeName.currentPlanChargebee}</span>
                            </p>
                        )}
                        <p className={classes.subTitle}>
                            {props.subheader}
                        </p>
                    </div>
                </div>
                <Modal
                    style={{ display: "flex" }}
                    open={changeNameOffice}
                    disableAutoFocus={true}
                >
                    <UpdateOffice
                        nameOffice={changeName.name}
                        accessCodeOffice={changeName.accessCode}
                        errorField={errorField}
                        loading={loading}
                        subminEmail={subminEmail}
                        handleClose={() => {
                            setLoading(false);
                            setChangeNameOffice(false);
                        }}
                        changeAccessCode={changeAccessCode}
                    />
                </Modal>
                {Object.keys(officesMeta).length !== 0 ?
                    <IconButton
                        onClick={handleOfficeListOpen}
                        style={{ height: 40, width: 40 }}
                    >
                        <ExpandMoreRoundedIcon style={{ color: '#3A3E4B' }} />
                    </IconButton>
                    : null
                }
                <Popover
                    open={Boolean(showOffices)}
                    anchorEl={showOffices}
                    onClose={handleOfficeListClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    className={classes.popoverCust}
                >
                    {Object.keys(officesMeta).map(officesId => {
                        return (
                            <div
                                style={{
                                    minWidth: 159,
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                }}
                                key={officesId}
                            >
                                <ListItem
                                    button
                                    divider
                                    key={officesId}
                                    onClick={() => {
                                        setOfficeId(officesId)
                                        history.push(`/office/${officesId}`, {
                                            officeId: officesId,
                                            officeName:
                                                officesMeta[officesId].name,
                                        });
                                        setShowOffices(null)
                                    }}
                                    style={{
                                        textAlign: 'center',
                                        color: officesId !== officeId ? 'black' : '#197FE8'
                                    }}
                                >
                                    <ListItemText
                                        primary={officesMeta[officesId].name}
                                    />
                                </ListItem>
                            </div>
                        )
                    })}
                </Popover>
            </div>
            <div style={{ width: mdDown ? '95%' : '58%' }} className={classes.infoContainer}>
                {content}
            </div>
        </div>
    );
}

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CustomButton from "../CustomButton";

const useStyles = makeStyles((theme) => ({
    urlContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderRadius: 4,
        marginTop: 20,
        width: '100%',
        height: 60,
    },

}))


const CustomBannerPlan = (props) => {
    const {  boxText, boxBackground, daysOff, textDaysOff, colorDaysOff, btnText, btnTextColor, btnOnClick } = props;
    const classes = useStyles();

    return (
        <div className={classes.urlContainer} style={{ background: boxBackground }}>
            <div style={{ width: '70%', display: 'flex', marginLeft: 13, alignItems: 'center' }}>
                <p
                    style={{
                        color: '#fff',
                        fontSize: 18,
                        fontFamily: 'SF Pro Display',
                        marginRight: 20
                    }}
                >
                    {boxText}
                </p>
                {daysOff && (
                    <p style={{ color: '#fff', backgroundColor: colorDaysOff, padding: '8px 16px', borderRadius: 3 }}>{textDaysOff}</p>
                )}
            </div>
            <div style={{ width: '30%', textAlign: 'end', marginRight: 13 }}>
                <CustomButton
                    label={btnText}
                    backgroundred='#FFF'
                    style={{ color: btnTextColor }}
                    href={btnOnClick}
                />
            </div>
        </div>

    );
}

export default CustomBannerPlan;

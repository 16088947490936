import firebase from "../../firebase";

export default function( {  
    email, officeName, name, lastName, phoneNumber, address, officeId, firstOffice, 
    employees, rooms, trialEnds, trialDays
    // computers, city, stateZip
}) {
    return firebase.functions().httpsCallable("allFunctions")({
        funcRole: "user",
        funcName: "loadOfficeData",
        data: { 
            email, officeName, name, lastName, phoneNumber, address,
            officeId, firstOffice,
            employees, rooms, trialEnds, trialDays
            // computers, city, stateZip,
        }
    });
}
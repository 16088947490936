
import React, { useState, useContext, useEffect } from 'react';
import { VictoryBar, VictoryChart } from "victory";

import { makeStyles, Divider } from '@material-ui/core';

import VisitReportContext from "./VisitReportContext";

const useStyles = makeStyles({
  infoContainer: {
    borderRadius: 4,
    paddingTop: 21,
    paddingBottom: 25,
    paddingLeft: 30,
    paddingRight: 26,
    background: 'white',
    border: '1px solid #EDEEF0',
    marginTop: 15,
    fontFamily: 'SF Pro Display',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
    margin: 0,
    marginBottom: 10
  },
  barCustom: {
    '& svg': {
      height: '300px !important'
    }
  }
});

function ReportThirdContainer() {
  const classes = useStyles();
  const reportCtx = useContext(VisitReportContext);
  const [slices, setSlices] = useState([{ totalTime: 1 }]);
  const [dataStages, setDataStages] = useState([]);
  const combinedStageTimes = {};
  useEffect(() => {
    countVisits();
  }, [reportCtx.visits, reportCtx.stages]);

  useEffect(() => {
    if (dataStages.length > 0) {
      const mySlices = createSlices();
      setSlices(mySlices.length > 0 ? mySlices : [{ totalTime: 0 }]);
    }
  }, [dataStages]);


  const countVisits = () => {
    let newData = []
    reportCtx.stages.map((stage) => {
      let newStage = []
      reportCtx.visits.map((visit) => {
        if (visit.stageTimes[stage.id]) {
          newStage = newStage.concat(visit)
        }
      })
      newData = newData.concat({ ...newStage, idStage: stage.id })
    })
    setDataStages(newData)
  }

  function getStageById(id) {
    return reportCtx.stages.find(stage => {
      return stage.id === id;
    });
  }

  function getLengthVisits(id) {
    let stageData = dataStages.filter(e => e.idStage === id)
    const { idStage, ...newStageData } = stageData[0]
    return Object.keys(newStageData).length
  }

  function createSlices() {
    //get cumulative total of time spent in each stage
    reportCtx.visits
      .filter(visit => {
        return visit.completedTime && visit.completedTime > 0;
      })
      .forEach(visit => {
        Object.keys(visit.stageTimes).forEach(stageId => {
          if (!combinedStageTimes[stageId]) {
            combinedStageTimes[stageId] = {
              id: stageId,
              totalTime: visit.stageTimes[stageId],
            };
          } else {
            combinedStageTimes[stageId].totalTime +=
              visit.stageTimes[stageId];
          }
        });
      });

    const sliceData = Object.keys(combinedStageTimes).map(
      combinedStageTimesId => {
        const element = combinedStageTimes[combinedStageTimesId];
        const stage = getStageById(element.id);
        element.stageVisits = getLengthVisits(element.id)
        element.name = stage && stage.name ? stage.name : element.id;
        element.backgroundColor =
          stage && stage.backgroundColor
            ? stage.backgroundColor
            : "grey";
        element.textColor =
          stage && stage.textColor ? stage.textColor : "black";
        element.value = element.totalTime;
        return element;
      }
    );

    return sliceData;
  }

  return (
    <div className={classes.infoContainer}>
      <p className={classes.title}>
        Average time in stage per visit
      </p>
      <Divider style={{ backgroundColor: '#EDEEF0' }} />
      <div className={classes.barCustom}>
        {reportCtx && reportCtx.visits && reportCtx.visits.length > 0 ?
          <VictoryChart domainPadding={{ x: [20, 20], y: [20, 20] }}>
            <VictoryBar
              horizontal={true}
              animate={{ duration: 1000 }}
              barWidth={20}
              events={[
                {
                  target: "data", eventHandlers: {
                    onClick: (evt, props) => {
                      const { datum } = props;
                      alert(datum.name);
                    }
                  }
                }
              ]}
              style={{
                data: {
                  fill: data => {
                    return data.datum.backgroundColor;
                  }
                },
                labels: {
                  fontSize: 15, fill: data => {
                    return data.datum.backgroundColor;
                  }
                },
              }}
              labels={({ datum }) => {
                return `${Math.floor((datum.totalTime / datum.stageVisits) / 60000)} Minutes`;
              }}
              data={slices && slices.length > 0 && slices.map((slice) => {
                if (slice && slice.totalTime && slice.stageVisits) {
                  return {
                    ...slice,
                    y: Math.floor((slice.totalTime / slice.stageVisits) / 60000),
                    x: slice && slice.name
                  };
                } else {
                  return {
                    ...slice,
                    y: 0,
                    x: 0
                  };

                }
              })}
            />
          </VictoryChart>
          :
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <p>There is not enough data to make the graphs</p>
          </div>
        }

      </div>
    </div>
  );
}

export default ReportThirdContainer;

import { createContext, useState } from "react";
import insyncHttps from "../../../insyncHttps/insyncHttps";

export const CouponsUserAdminContext = createContext({
  couponList: {},
  loading: false,
  getCouponList: () => { },
  error: null,
  getCouponsChargebee: () => { },
  coupons: {},
});

export const useCouponList = () => {
  const [couponList, setCouponList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getCouponList = () => {
    setError(null);
    setLoading(true);
    insyncHttps.adminUser
      .getCouponsList()
      .then(result => {
        setLoading(false);
        setCouponList(result.data);
      })
      .catch(err => {
        setError(err.message);
        setLoading(false);
        setCouponList([]);
      });
  };

  return { couponList, loading, getCouponList, error };
};

export const useCouponsChargebee = () => {
  const [coupons, setCoupons] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const getCouponsChargebee = () => {
    setError(null);
    setLoading(true);
    insyncHttps.adminUser
      .getCouponsChargebee()
      .then(result => {
        setLoading(false);
        setCoupons(result.data);
      })
      .catch(err => {
        setLoading(false);
        setError(err.message);
        setCoupons([]);
      });
  };

  return { coupons, loading, getCouponsChargebee, error };
};
import firebase from '../../../firebase';

/**
 * @typedef {Object} CreateOfficeData
 * @property {string} name - office name
 * @property {string} phoneNumber - office phone number
 * @property {string} firstName - office firstName
 * @property {string} lastName - office lastName
 * @property {string} firstOffice - office id first office
 */

/**
 * @typedef {Object} CreateOfficeResponse
 * @property {Object} office - new office information
 * @property {string} office.id - office id
 * @property {Object} user - new user for internal code
 * @property {string} accessCode - access code
 */

/**
 * Create new Office V2.
 *
 * @param {CreateOfficeData} data new office data
 * @returns {Promise.<CreateOfficeResponse>} office, user and access code information.
 */
export const createOffice = async (data) => {
  return await firebase.functions().httpsCallable('V2-office-createOffice')(
    data
  );
};

import firebase from "../../firebase";
import { isNil, isEmpty } from "ramda";

export default function ({ officeId, email, roleName, accessCode }) {
    let data = {}
    if (!isNil(accessCode) && !isEmpty(accessCode)) {
        data = { officeId, email, roleName, accessCode }
    } else {
        data = { officeId, email, roleName }
    }
    return firebase.functions().httpsCallable("allFunctions")({
        funcRole: "officeAdmin",
        funcName: "addOfficeRoleToUser",
        data
    });
}

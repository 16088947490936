
import React, { useContext } from 'react';
import moment from "moment";
import ReactToPrint from "react-to-print";
import {
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  makeStyles,
  TableContainer,
  Paper,
} from "@material-ui/core";

import VisitReportContext from "./VisitReportContext";
import CustomButton from '../../../CustomButton';

const useStyles = makeStyles({
  infoContainer: {
    borderRadius: 4,
    paddingBottom: 25,
    marginTop: 15,
    fontFamily: 'SF Pro Display',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center'
  },
  containerTable: {
    borderRadius: 4,
    marginTop: 15,
    border: '1px solid #EDEEF0',
    background: 'white',
    width: '100%',
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
    margin: 0,
    marginBottom: 10
  },
  barCustom: {
    '& svg': {
      height: '360px !important'
    }
  }
});

function ReportFourthContainer() {
  const classes = useStyles();
  const reportCtx = useContext(VisitReportContext);
  const reportTime = moment(reportCtx.reportTime).format("hh:mm YYYY/MM/DD ");
  const endDate = moment(reportCtx.reportEndDate).format("YYYY/MM/DD");
  const startDate = moment(reportCtx.reportStartDate).format("YYYY/MM/DD");
  const { printRef2 } = reportCtx;

  return (
    <div className={classes.infoContainer}>
      <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
        <ReactToPrint
          trigger={() => {
            return (
              <CustomButton label='print' />
            );
          }}
          content={() => {
            return printRef2.current;
          }}
        />
        <div style={{ marginLeft: 10 }}>
          <div>{`Generated at ${reportTime}`}</div>
          <div>{`${startDate} - ${endDate}`}</div>
        </div>
      </div>
      <div className={classes.containerTable}>
        <TableContainer component={Paper} style={{ maxHeight: 700 }}>

          <Table stickyHeader={true} style={{ minWidth: 620 }} ref={printRef2}>
            <TableHead>
              <TableRow>
                <TableCell align='center'>Patient Name</TableCell>
                <TableCell align='center'>Arrival Time</TableCell>
                <TableCell align='center'>Appointment Time</TableCell>
                <TableCell align='center'>Checkout Time</TableCell>
                <TableCell align='center'>Total Visit Time</TableCell>
                {reportCtx.stages.map((stage) => {
                  return (
                    <TableCell key={stage.id} align='center'>
                      {stage.name}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {reportCtx.visits
                .sort((a, b) => {
                  return a.arrivalTime - b.arrivalTime;
                })
                .map((visit) => {
                  return (
                    <TableRow key={visit.id}>
                      <TableCell align='center'>
                        {visit.name}
                      </TableCell>
                      <TableCell align='center'>
                        {moment(visit.arrivalTime).format("LT")}
                      </TableCell>
                      <TableCell align='center'>
                        {moment(visit.appointmentTime).format("LT")}
                      </TableCell>
                      <TableCell align='center'>
                        {visit.completedTime
                          ? moment(visit.completedTime).format(
                            "LT"
                          )
                          : "-"}
                      </TableCell>
                      <TableCell align='center'>
                        {visit.completedTime
                          ? Math.floor(
                            (parseInt(visit.completedTime) -
                              parseInt(visit.arrivalTime)) /
                            60000
                          )
                          : 0}
                      </TableCell>
                      {reportCtx.stages.map((stage) => {
                        const timeInStage = Math.floor(
                          visit.stageTimes[stage.id] / 60000
                        );
                        return (
                          <TableCell key={visit.id + stage.id} align='center'>
                            {timeInStage || 0}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default ReportFourthContainer;

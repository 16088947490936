import React, { useContext, useState, useEffect } from 'react';

import { makeStyles, IconButton, CircularProgress, Tooltip, Tabs, Tab, Box, } from '@material-ui/core';
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';

import { OfficeContext } from "../OfficeContext";
import { usePatientTracking, PatientTrackingContext } from "./PatientTrackingContext";
import insyncHttps from "../../../../insyncHttps/insyncHttps";
import ErrorStateDisplay from "../../../ErrorStateDisplay/ErrorStateDisplay";

import Stages from './Stages';
import VisitSettings from './VisitSettings';
import SortYourQueues from './SortYourQueues';
import ColumnLayout from './ColumnLayout';
import AdjustAppointment from './AdjustAppointment';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box style={{ display: 'flex', justifyContent: 'center' }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const useStyles = makeStyles({
    infoContainer: {
        background: 'white',
        borderRadius: 4,
        marginTop: 21,
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'SF Pro Display',
        border: '1px solid #EDEEF0',
        width: '100%',
        maxWidth: 'none'
    },
    infoCustom: {
        paddingLeft: 33,
        paddingTop: 33,
        paddingRight: 25,
        paddingBottom: 35,
    },
    title: {
        fontSize: 22,
        fontWeight: 'bold',
        margin: 0,
        wordBreak: 'break-all',
    },
    subTitle: {
        fontSize: 16,
        fontWeight: 'normal',
        margin: 0,
    },
    urlContainer: {
        background: '#F0F7FF',
        paddingLeft: 20,
        paddingTop: 7,
        paddingRight: 25,
        paddingBottom: 7,
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #CDE4FF',
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4
    },
    customTabs: {
        '& .MuiTab-root': {
            textTransform: 'none',
        },
        '& .MuiTabs-indicator': {
            height: 0,
            backgroundColor: 'transparent',
        }
    },
    customTab: {
        minWidth: 'fit-content',
        marginLeft: 20,
        marginRight: 20,
        borderRadius: 25,
        minHeight: 26,
        paddingTop: 0,
        paddingBottom: 0,
        fontFamily: 'SF Pro Display',
    }
});

function PatientTracking() {
    const classes = useStyles();
    const { officeId } = useContext(OfficeContext);
    const patientTracking = usePatientTracking(officeId);

    const [openCopied, setOpenCopied] = useState(false)
    const [urlSelector, setUrlSelector] = useState('');
    const [loading, setLoading] = useState(false);
    const [value, setValue] = useState(0);

    useEffect(() => {
        patientTracking.getPatientTracking();
    }, []);

    if (patientTracking.error) {
        return (
            <ErrorStateDisplay
                title="Error Getting PatientTracking"
                message={patientTracking.error}
                retry={patientTracking.getPatientTracking}
            />
        );
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const copyData = () => {
        const cp = `${process.env.REACT_APP_CURBSIDE_APP_URL}/office/${officeId}`
        navigator.clipboard.writeText(cp)
        setUrlSelector(cp)
        setOpenCopied(true)
    }

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenCopied(false);
    };

    return (
        <PatientTrackingContext.Provider value={patientTracking}>
            <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    className={classes.customTabs}
                >
                    <Tab
                        value={0}
                        label="Stages"
                        className={classes.customTab}
                        style={{
                            background: value === 0 ? '#9DCD2A' : '#F8F9FB',
                            color: value === 0 ? 'white' : '#979797'
                        }}
                    />
                    <Tab
                        value={1}
                        label="Columns"
                        className={classes.customTab}
                        style={{
                            background: value === 1 ? '#9DCD2A' : '#F8F9FB',
                            color: value === 1 ? 'white' : '#979797'
                        }}
                    />
                    <Tab
                        value={2}
                        label="Advanced"
                        className={classes.customTab}
                        style={{
                            background: value === 2 ? '#9DCD2A' : '#F8F9FB',
                            color: value === 2 ? 'white' : '#979797'
                        }}
                    />
                </Tabs>
                <TabPanel value={value} index={0} style={{ width: '80%' }}>
                    <div style={{ width: '80%' }}>
                        <Stages />
                    </div>
                </TabPanel>
                <TabPanel value={value} index={1} style={{ width: '80%' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                        <ColumnLayout />
                        <SortYourQueues />
                    </div>
                </TabPanel>
                <TabPanel value={value} index={2} style={{ width: '80%' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <div className={classes.infoContainer} >
                            <div className={classes.infoCustom}>
                                <p className={classes.title}>
                                    Url curbside check-in
                                </p>
                                <p className={classes.subTitle}>
                                    With this link the visitors of this office will be able to register
                                </p>
                            </div>
                            <div className={classes.urlContainer}>
                                {loading ? (
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <CircularProgress size={30} style={{ color: '#9DCD2A' }} />
                                    </div>

                                ) : (
                                    <React.Fragment>
                                        <Tooltip
                                            title={
                                                <React.Fragment>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                        <CheckRoundedIcon style={{ color: '#9DCD2A' }} />
                                                        <p style={{ fontWeight: 'bold', fontSize: 18, margin: 0 }}>Copied </p>
                                                    </div>
                                                    <p style={{ fontSize: 14, wordBreak: 'break-all' }}>
                                                        {urlSelector}
                                                    </p>
                                                </React.Fragment>
                                            }
                                            enterDelay={500}
                                            leaveDelay={200}
                                            open={openCopied}
                                            onClose={handleClose}
                                            arrow
                                        >
                                            <div style={{ display: 'flex' }}>
                                                <IconButton onClick={copyData}>
                                                    <FileCopyRoundedIcon style={{ color: '#197FE8' }} />
                                                </IconButton >
                                                <p
                                                    onClick={copyData}
                                                    style={{ color: '#197FE8' }}
                                                >
                                                    Url curbside check-in
                                                </p>
                                            </div>
                                        </Tooltip>
                                    </React.Fragment>
                                )}
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <VisitSettings />
                            <AdjustAppointment />
                        </div>
                    </div>
                </TabPanel>
            </div>
        </PatientTrackingContext.Provider>
    );
}

export default PatientTracking;

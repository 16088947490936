import React, { useState, useContext, forwardRef } from "react";
import {
    Card,
    CardContent,
    CardActions,
    Divider,
    CircularProgress,
    makeStyles,
} from "@material-ui/core";
import { BillingContext } from '../Billing/BillingContext'
import { OfficeContext } from "../OfficeContext";
import { appContext } from "../../../../contexts/app";
import insyncHttps from "../../../../insyncHttps/insyncHttps";

import CustomTextField from "../../../CustomTextField";
import CustomButton from "../../../CustomButton";
import CustomCheckBox from "../../../CustomCheckBox";

const useStyles = makeStyles({
    cardContainer: {
        margin: "auto",
        paddingBottom: 38,
        paddingTop: 28,
        paddingLeft: 20,
        paddingRight: 20,
        minWidth: 400,
        maxWidth: 500,
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: 4,
            height: 0,
        },
        '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#DFE2E2',
            borderRadius: 4,
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#888',
            borderRadius: 4,
        },
        '& .MuiCardContent-root': {
            padding: 0
        }
    },
    title: {
        fontSize: 22,
        fontWeight: 'bold',
        margin: 0,
        wordBreak: 'break-all',
        fontFamily: 'SF Pro Display'
    },
    subTitle: {
        fontSize: 16,
        fontWeight: 'normal',
        margin: 0,
        color: '#979797',
        fontFamily: 'SF Pro Display'
    },
});

const ChangeEmail = forwardRef((props, ref) => {
    const { form, loading, handleClose, handleChange, handleSubmit } = props;
    const classes = useStyles();

    return (
        <Card ref={ref} className={classes.cardContainer}>
            <CardContent>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginLeft: 25,
                        marginRight: 25
                    }}
                >
                    <p style={{ marginBottom: 20 }} className={classes.title}>
                        Change device status
                    </p>
                    <p
                        style={{ marginBottom: 20, cursor: 'pointer' }}
                        onClick={handleClose}
                        className={classes.subTitle}
                    >
                        Cancel
                    </p>
                </div>
                <Divider style={{ backgroundColor: '#EDEEF0' }} />
                <div style={{ marginBottom: 46, paddingLeft: 25, paddingRight: 25, fontFamily: 'SF Pro Display' }}>
                    <p style={{ fontSize: 16, marginBottom: 10, textAlign: 'left', marginTop: 30 }}>
                        Device: {form.hostname}
                    </p>
                    <p style={{ fontSize: 16, marginBottom: 10, textAlign: 'left', marginTop: 30 }}>
                        User: {form.user}
                    </p>

                    <div style={{ marginTop: 25 }}>
                        <p>Action</p>
                        <CustomCheckBox
                            onClick={handleChange}
                            checked={form.session}
                            name='session'
                        />
                        <span
                            style={{ color: form.officeAdmin === true ? '#2F80ED' : '#979797' }}
                        >
                            Session
                        </span>
                    </div>
                    {/* <div style={{ marginTop: 15 }}>
                        <CustomCheckBox
                            onClick={handleChange}
                            checked={form.state}
                            name='state'
                        />
                        <span
                            style={{ color: form.officeUser === true ? '#2F80ED' : '#979797' }}
                        >
                            State
                        </span>
                    </div> */}
                </div>
                <Divider style={{ backgroundColor: '#EDEEF0' }} />
            </CardContent>
            <CardActions style={{ marginTop: 32, paddingLeft: 25, paddingRight: 25 }}>
                {loading ?
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress size={30} style={{ color: '#9DCD2A' }} />
                    </div>
                    :
                    <CustomButton
                        width='full'
                        label='submit'
                        onClick={handleSubmit}
                    />
                }
            </CardActions>
        </Card>
    );
});
export default ChangeEmail
import { createContext, useState } from "react";
import insyncHttps from "../../../../insyncHttps/insyncHttps";

export const BillingContext = createContext({
  subscriptions: { subs: [], loading: false, getSubs: () => { } },
  paymentSources: { sources: [], loading: false, getSources: () => { } },
  plans: { plans: [], loading: false, getPlans: () => { } },
  customer: { customer: null, loading: false, getCustomer: () => { } },
  officesMeta: {},
  getOfficeList: () => { },
});

export const usePaymentSources = officeId => {
  const [sources, setSources] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getSources = () => {
    setError(null);
    setLoading(true);
    insyncHttps.billing
      .getPaymentSources({ officeId })
      .then(result => {
        setLoading(false);
        setSources(result.data);
      })
      .catch(err => {
        setError(err.message);
        setLoading(false);
        setSources([]);
      });
  };

  return { sources, loading, getSources, error };
};

export const useSubscriptions = officeId => {
  const [subs, setSubs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getSubs = () => {
    setError(null);
    setLoading(true);
    insyncHttps.billing
      .getSubscriptions({ officeId })
      .then(result => {
        setLoading(false);
        setSubs(result.data);
      })
      .catch(err => {
        setError(err.message);
        setLoading(false);
        setSubs([]);
      });
  };

  return { subs, loading, getSubs, error };
};

export const usePlansChargebee = () => {
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const getPlans = () => {
    setError(null);
    setLoading(true);
    insyncHttps.billing
      .getPlans()
      .then(result => {
        setLoading(false);
        setPlans(result.data);
      })
      .catch(err => {
        setLoading(false);
        setError(err.message);
        setPlans([]);
      });
  };

  return { plans, loading, getPlans, error };
};

export const useCustomer = officeId => {
  const [customer, setCustomer] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const getCustomer = () => {
    setError(null);
    setLoading(true);
    insyncHttps.billing
      .getCustomer({ officeId })
      .then(result => {
        setLoading(false);
        setCustomer(result.data);
      })
      .catch(err => {
        setError(err.message);
        setLoading(false);
        setCustomer(null);
      });
  };

  return { customer, loading, getCustomer, error };
};

export const useChangeOffice = () => {
  const [officesMeta, setOfficesMeta] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getOfficeList = () => {
    setError(null);
    setLoading(true);
    insyncHttps.office
      .getOfficeList()
      .then(result => {
        setLoading(false);
        setOfficesMeta(result.data);
      })
      .catch(err => {
        console.error(err);
        setError(err.message);
        setLoading(false);
      });
  };

  return { officesMeta, getOfficeList, error, loading };
};

export const useCouponChargebee = couponId => {
  const [itemCoupon, setItemCoupon] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const getCouponById = () => {
    setError(null);
    setLoading(true);
    insyncHttps.billing
      .getCouponById({couponId})
      .then(result => {
        setLoading(false);
        setItemCoupon(result.data);
      })
      .catch(err => {
        console.error(err);
        setError(err.message);
        setLoading(false);
      });
  };

  return { itemCoupon, getCouponById, error, loading };
};
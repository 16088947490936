import React, { useState, useContext, useEffect } from "react";
import moment from "moment";
import MomentUtils from '@date-io/moment';
import {
    CardContent,
    Card,
    makeStyles,
    Divider,
    CircularProgress,
} from "@material-ui/core";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import { BillingContext } from "../BillingContext";
import { appContext } from "../../../../../contexts/app";
import { OfficeContext } from "../../OfficeContext";
import insyncHttps from "../../../../../insyncHttps/insyncHttps";
import { ingestEncharge } from '../../../../../utils/encharge';
import { useDashboard } from "../../../Dashboard/DashboardContext";

import CustomTextField from "../../../../CustomTextField";
import CustomButton from "../../../../CustomButton";
import { useAuth } from "../../../../../contexts/v2/auth";

const useStyles = makeStyles({
    title: {
        fontSize: 22,
        fontWeight: 'bold',
        margin: 0,
        wordBreak: 'break-all',
    },
    subTitle: {
        fontSize: 16,
        fontWeight: 'normal',
        margin: 0,
        color: '#979797'
    },
    textField: {
        fontSize: 16,
        marginBottom: 10,
        marginTop: 20,
        textAlign: 'left',
    },
    cardContainer: {
        margin: "auto",
        fontFamily: 'SF Pro Display',
        paddingBottom: 38,
        paddingTop: 28,
        paddingLeft: 20,
        paddingRight: 20,
        minWidth: 400,
        maxWidth: 500,
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: 4,
            height: 0,
        },
        '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#DFE2E2',
            borderRadius: 4,
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#888',
            borderRadius: 4,
        },
        '& .MuiCardContent-root': {
            padding: 0
        }
    },
    customPopover: {
        '& .MuiPickersToolbar-toolbar': {
            background: 'linear-gradient(180deg, #B5E346 0%, #9FCF2C 100%)',
        },
        '& .MuiPickersBasePicker-pickerView': {
            '& .MuiPickersMonth-monthSelected': {
                color: '#B5E346',
            },
            '& .MuiPickersYear-yearSelected': {
                color: '#B5E346',
            },
            '& .MuiButton-textPrimary': {
                color: '#B5E346',
            }
        },
        '& .MuiDialogActions-root': {
            '& .MuiButton-textPrimary': {
                color: '#B5E346',
            }
        },
    },
    datePicker: {
        marginRight: 10,
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#ECEEF2',
        },
        '& input': {
            padding: 16,
            boxShadow: 'inset 2px 4px 6px rgba(201, 203, 217, 0.15)',

        }
    }
});

export default function (props) {
    const classes = useStyles();
    const handleClose = props.handleClose;

    const { user } = useAuth();
    const { officeId } = useContext(OfficeContext);
    const { paymentSources } = useContext(BillingContext);
    const { error } = useContext(appContext);
    const { officesMeta, getOfficeList } = useDashboard();

    const [selectedDate, handleDateChange] = useState(new Date());
    const [loading, setLoading] = useState(false);
    const [errorField, setErrorField] = useState(false);
    const [form, setForm] = useState({
        fullName: '',
        cardNumber: '',
        cvv: '',
        expiry_month: '',
    });

    useEffect(() => {
        getOfficeList()
    }, []);

    const onChangeText = (prop) => (event) => {
        setForm({ ...form, [prop]: event.target.value })
    };

    const year = moment(selectedDate).format('YYYY')
    const month = moment(selectedDate).format('M')

    const changeNumber = (prop) => (event) => {
        const re = /^[0-9\b]+$/;
        if (event.target.value.length === 0 || re.test(event.target.value)) {
            setForm({ ...form, [prop]: event.target.value })
        }

    };

    const subminDataCard = () => {
        setLoading(true);
        if (form.cardNumber === '' || form.fullName === '' || form.cvv === '') {
            setErrorField(true)
            setLoading(false);
        } else {
            insyncHttps.billing
                .createPaymentSource({
                    officeId,
                    fullName: form.fullName,
                    cardNumber: form.cardNumber,
                    cvv: form.cvv,
                    expiry_month: month,
                    expiry_year: year,
                })
                .then(async result => {
                    // ---------------------------------------------------
                    const payloadRegister = {
                        "name": "Registered payment method",
                        "user": {
                            "email": user.email,
                            // "userId": officeId,
                            "tags": "payment_method_added"
                        }
                    }
                    const [first] = Object.keys(officesMeta).map(id => id);
                    if(first === officeId){
                        await ingestEncharge(payloadRegister)
                    }
                    // ---------------------------------------------------
                    paymentSources.getSources();
                    setLoading(false);
                    handleClose();
                })
                .catch(err => {
                    error.setMessage(err.message);
                    setLoading(false);
                    console.error(error);
                });
        }
    }

    return (
        <Card className={classes.cardContainer}>
            <CardContent>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginLeft: 25,
                        marginRight: 25
                    }}
                >
                    <p style={{ marginBottom: 23 }} className={classes.title}>
                        Payment sources
                    </p>
                    <p
                        style={{ marginBottom: 23, cursor: 'pointer' }}
                        onClick={handleClose}
                        className={classes.subTitle}
                    >
                        Cancel
                    </p>
                </div>
                <Divider style={{ backgroundColor: '#EDEEF0' }} />
                <div style={{ marginBottom: 46, paddingLeft: 25, paddingRight: 25 }}>
                    <p className={classes.textField} style={{ marginTop: 31 }}>
                        Name
                    </p>
                    <CustomTextField
                        value={form.fullName}
                        placeholder="Your name"
                        onChange={onChangeText('fullName')}
                        error={form.fullName === '' ? errorField : null}
                    />
                    <p className={classes.textField}>
                        Card number
                    </p>
                    <CustomTextField
                        value={form.cardNumber}
                        placeholder="Card number"
                        onChange={onChangeText('cardNumber')}
                        error={form.cardNumber === '' ? errorField : null}
                    />
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 20 }}>
                        <div>
                            <p className={classes.textField} >
                                MM/YY
                            </p>
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <DatePicker
                                    views={["year", "month"]}
                                    minDate={new Date()}
                                    value={selectedDate}
                                    inputVariant="outlined"
                                    focused={false}
                                    DialogProps={{ className: classes.customPopover }}
                                    className={classes.datePicker}
                                    onChange={handleDateChange}
                                />
                            </MuiPickersUtilsProvider>

                        </div>
                        <div>
                            <p className={classes.textField}>
                                CVV
                            </p>
                            <CustomTextField
                                value={form.cvv}
                                placeholder="---"
                                onChange={changeNumber('cvv')}
                                error={form.cvv === '' ? errorField : null}
                                maxLength={4}
                            />
                        </div>
                    </div>
                </div>
                <Divider style={{ marginBottom: 32 }} />
                {loading ?
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress size={30} style={{ color: '#9DCD2A' }} />
                    </div>
                    :
                    <div style={{ paddingLeft: 25, paddingRight: 25 }}>
                        <CustomButton
                            label='ADD Card'
                            width='full'
                            onClick={subminDataCard}
                        />
                    </div>
                }
            </CardContent>
        </Card>
    );
}

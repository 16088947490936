import React, { useState, useContext, useEffect } from 'react';

import { Link as RouterLink, useHistory, useLocation } from 'react-router-dom';

import {
  Grid,
  makeStyles,
  Link,
  FormControlLabel,
  Checkbox,
  FormControl
} from '@material-ui/core';
import CustomTextField from '../../CustomTextField';
import CustomButton from '../../CustomButton';

import CustomNavBar from '../../CustomNavBarLogin';
import TagManager from 'react-gtm-module';

// Context

import { appContext, useApp } from '../../../contexts/app';
import { useAuth } from '../../../contexts/v2/auth';

const tagManagerArgs = {
  dataLayer: {
    event: 'sign_up_page',
    page: 'signup'
  },
  dataLayerName: 'PageDataLayer'
};

const useStyles = makeStyles({
  heading: {
    textAlign: 'left',
    fontSize: 35,
    marginBottom: 30,
    fontWeight: 500,
    color: '#1D1D1D'
},
typography: {
    textAlign: "left",
    fontSize: 17,
    marginBottom: 15,
    fontWeight: 300,
    color: '#656565'
},
termsText: {
    color: '#000000',
    marginLeft: 6,
    marginTop: 15,
    fontSize: 17,
    alignItems: 'center'
},
ulist: {
    listStyle: 'none',
    fontSize: 20,
    fontWeight: 400,
    paddingBottom: 15,
    paddingLeft: 0,
    color: '#1D1D1D',
    '& li':{
        paddingBottom: 17,
    },
    '& img':{
        width: 28,
        marginRight: 8,
        verticalAlign: 'middle',
    }
},
lightText: {
    color: '#000000',
    listStyle: 'none',
    fontSize: 16,
    fontWeight: 300,
    paddingLeft: 0,
    '& li':{
        paddingBottom: 15,
    },
    '& img':{
        width: 25,
        marginRight: 5,
        verticalAlign: 'middle',
    }
},
container: {
    maxWidth: 1500,
    paddingLeft: 15,
    paddingRight: 15,
    margin: 'auto'
},
row: {
    display: 'flex',
    flexWrap: 'wrap'
}
});

function Register() {
  TagManager.initialize(tagManagerArgs);

  const classes = useStyles();
  const { error } = useContext(appContext);
  const app = useApp();
  const auth = useAuth();

  const [form, setForm] = useState({
    email: '',
    password: ''
  });
  const [errorField, setErrorField] = useState(false);
  const [emailValidate, setEmailValidate] = useState('');
  const [checked, setChecked] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const { from } = location.state || { from: { pathname: '/' } };

  useEffect(() => {
    // Capture errors resulting from the authentication process and
    // send them as an application message
    // TODO: this has to be improved.
    const err = auth.error;

    if (!err) {
      return;
    }

    if (err.code) {
      error.setMessage(err.code);
    } else if (err.email) {
      error.setMessage(err.email);
    } else if (err.credential) {
      error.setMessage(err.credential);
    } else {
      error.setMessage(err.message);
    }
  }, [auth.error]);

  const gotoNextPage = () => {
    if (from.pathname === app.marketingExtendPlanLink) {
      history.replace('dashboard', {
        from: from.pathname
      });
    } else {
      history.replace('dashboard');
    }
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const onChangeEmail = (event) => {
    const caracteres =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const t = event.currentTarget.value.split(' ');
    let text = '';
    t.map((r) => {
      if (r !== '') {
        text += r;
      }
    });
    setForm({ ...form, email: text.toLowerCase() });
    if (text !== '') {
      if (caracteres.test(text)) {
        setEmailValidate(false);
      } else {
        setEmailValidate(true);
      }
    } else {
      setEmailValidate(true);
    }
  };

  const submitForm = async () => {
    setErrorField(false);

    if (
      form.email === '' ||
      form.password === '' ||
      (form.password.length >= 1 && form.password.length < 6) ||
      checked === false
    ) {
      setErrorField(true);
    } else {
      const authResponse = await auth.signup(form.email, form.password);

      if (authResponse) {
        const tagManagerArgs2 = {
          dataLayer: {
            event: 'sign_up'
          },
          dataLayerName: 'PageDataLayer'
        };
        TagManager.initialize(tagManagerArgs2);

        gotoNextPage();
      }
    }
  };

  const signUpWithGoogle = async () => {
    const authResponse = await auth.signinWithGoogle();

    if (authResponse) {
      gotoNextPage();
    }
  };

  return (
    <div className={classes.container}>
      <CustomNavBar />
      <Grid container style={{ marginTop: 180 }} justifyContent="center">
      <Grid
            item
            xs={11} sm={11} md={6} lg={6}
            style={{ marginTop: 15, fontFamily: 'SF Pro Display' }}
        >         
            <div>
                <p className={classes.heading}>
                    Let's get started on improving <br></br> your staff tracking and messaging
                </p> 
                <ul className={classes.ulist}>
                    <li><img src='/assets/checkmark.svg'></img> No credit card required</li>
                    <li><img src='/assets/checkmark.svg'></img> Full access for 14 days</li>
                    <li><img src='/assets/checkmark.svg'></img> Free 24/7 support</li>
                    <li><img src='/assets/checkmark.svg'></img> Cancel anytime</li>
                    <li><img src='/assets/checkmark.svg'></img> Zero risks</li>
                </ul>
                <ul className={classes.lightText}>
                    <li><img src='/assets/security.png'></img> 100% DATA SECURITY & PRIVACY</li>
                    <li><img src='/assets/win.png'></img> Available on all Microsoft Windows devices</li>
                </ul>
            </div>
            </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={4}
          style={{ marginTop: 50, fontFamily: 'SF Pro Display' }}
        >
          <p className={classes.typography}> Email </p>
          <CustomTextField
            value={form.email}
            placeholder="your@email.com"
            error={form.email !== '' ? emailValidate : errorField}
            onChange={onChangeEmail}
            helperText={
              form.email && emailValidate ? 'Please enter a valid email' : ''
            }
          />
          <p className={classes.typography}> Password </p>
          <CustomTextField
            value={form.password}
            placeholder="6+characters"
            type="password"
            error={
              form.password === ''
                ? errorField
                : form.password.length >= 1 && form.password.length < 6
                ? true
                : false
            }
            helperText={
              form.password.length >= 1 && form.password.length < 6
                ? 'Min 6 caracters'
                : ''
            }
            onChange={(e) => {
              setForm({ ...form, password: e.target.value });
            }}
          />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <FormControl component="div">
              <FormControlLabel
                control={
                  <Checkbox
                    style={{
                      margin: 0,
                      padding: 0,
                      color:
                        errorField && !checked
                          ? '#EC5829'
                          : checked
                          ? '#197FE8'
                          : '#ECEEF2'
                    }}
                  />
                }
                checked={checked}
                onClick={handleChange}
                style={{ margin: 0 }}
              />
              {/* {errorField && !checked && (
                                <FormHelperText error={true} style={{ fontSize: '0.75rem', color: '#f44336'}} >
                                    Required
                                </FormHelperText>
                            )} */}
            </FormControl>
            <div
              className={classes.termsText}
              style={{
                fontSize: '15px',
                lineHeight: '24px',
                color: errorField && !checked ? '#f44336' : '#000000'
              }}
            >
              Creating an account means you’re okay with our
              <br />
              <Link
                target="_blank"
                href="https://insyncapp.io/terms-and-conditions"
                style={{
                  color: errorField && !checked ? '#f44336' : '#00BCF2'
                }}
              >
                Terms of Service,
              </Link>
              &nbsp;
              <Link
                target="_blank"
                href="https://insyncapp.io/privacy-policy"
                style={{
                  color: errorField && !checked ? '#f44336' : '#00BCF2'
                }}
              >
                Privacy Policy.
              </Link>
            </div>
          </div>
          <div style={{ marginTop: 30 }}>
            <CustomButton
              className={classes.btnCustom}
              label="Create free account"
              width="full"
              id="btn-sign-up"
              onClick={submitForm}
            />
          </div>
          <div style={{ marginTop: 30 }}>
            <CustomButton 
              label="Sign up with google"
              width="full"
              onClick={signUpWithGoogle}
              startIcon={<img width="35px" src="/google-logo.png" />}
              backgroundred={'#0000'}
              style={{ background: '#F8F8F8', color: '#fff' }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'center'
            }}
          >
            <p
              className={classes.typography}
              style={{ marginRight: 6 }}
            >
              Already a member?
            </p>
            <RouterLink
              to="/login"
              className={classes.typography}
              style={{ color: '#000000', textDecoration: 'none', fontWeight: 600 }}
            >
              Sign In
            </RouterLink>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default Register;

import { useContext, useState, useRef } from "react";
import { OfficeContext } from "../OfficeContext";
import firebase from "../../../../firebase";

export default function (props) {
  const { officeId } = useContext(OfficeContext);

  const [fetching, setFetching] = useState(false);
  const [visits, setVisits] = useState([]);
  const [stages, setStages] = useState([]);
  const [reportTime, setReportTime] = useState(0);
  const [reportStartDate, setReportStartDate] = useState(0);
  const [reportEndDate, setReportEndDate] = useState(0);
  const printRef = useRef(null);
  const printRef2 = useRef(null);

  function getReport(startDate, endDate) {
    setFetching(true);
    firebase
      .functions()
      .httpsCallable("allFunctions")({
        funcRole: "reporting",
        funcName: "getVisitReport1",
        data: {
          officeId: officeId,
          startDate,
          endDate
        }
      })
      .then(result => {
        const reportData = result.data;
        const stages = Object.keys(reportData.stages)
          .filter(stageId => {
            return Object.keys(reportData.stages).includes(stageId);
          })
          .sort((a, b) => {
            if (reportData.stages[a].name > reportData.stages[b].name) {
              return 1;
            }
            if (reportData.stages[a].name < reportData.stages[b].name) {
              return -1;
            }
            return 0;
          })
          .sort((a, b) => {
            const stageA = { ...reportData.stages[a] };
            const stageB = { ...reportData.stages[b] };
            stageA.listPosition = stageA.listPosition ? stageA.listPosition : 0;
            stageB.listPosition = stageB.listPosition ? stageB.listPosition : 0;

            return stageA.listPosition - stageB.listPosition
          })
          .map(id => {
            return reportData.stages[id];
          });
        setStages(stages);
        setVisits(reportData.visits);
        setReportTime(reportData.reportTime);
        setReportStartDate(reportData.startDate);
        setReportEndDate(reportData.endDate);
      })
      .catch(err => {
        console.error(err);
      })
      .finally(() => {
        setFetching(false);
      });
  }
  return {
    getReport,
    fetching,
    stages,
    visits,
    reportTime,
    reportStartDate,
    reportEndDate,
    printRef,
    printRef2
  };
}

import { createContext, useState } from "react";

import insyncHttps from "../../../../insyncHttps/insyncHttps";

export const MessagingContext = createContext({
    users: {},
    loading: false,
    getMessaging: () => {},
    error: null,
    getPaging: () => {},
});

export const useMessaging = officeId => {
    const [users, setUsers] = useState({});
    const [messages, setMessages] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const getMessaging = () => {
        setError(null);
        setLoading(true);
        insyncHttps.messaging
            .getMessaging({ officeId })
            .then(result => {
                setLoading(false);
                setUsers(result.data.users || {});
                setMessages(result.data.messages || {});
            })
            .catch(error => {
                setError(error.message);
                console.error(error);
                setLoading(false);
                setUsers({});
                setMessages({});
            });
    };

    return { users, loading, messages, getMessaging, error };
};
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardContent, CardActions, Divider, CircularProgress } from '@material-ui/core';

import CustomButton from "../CustomButton";

const useStyles = makeStyles((theme) => ({
    cardContainer: {
        margin: "auto",
        paddingBottom: 38,
        paddingTop: 28,
        paddingLeft: 20,
        paddingRight: 20,
        minWidth: 400,
        maxWidth: 500,
        overflowY: 'auto',
        fontFamily: 'SF Pro Display',
        '&::-webkit-scrollbar': {
            width: 4,
            height: 0,
        },
        '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#DFE2E2',
            borderRadius: 4,
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#888',
            borderRadius: 4,
        },
        '& .MuiCardContent-root': {
            padding: 0
        }
    },
    title: {
        fontSize: 22,
        fontWeight: 'bold',
        margin: 0,
        wordBreak: 'break-all'
    },
}))

const CustomDialogActions = React.forwardRef((props, ref) => {

    const { title, description, titleAccept, titleCancel, handleAction, handleClose, loading } = props;
    const classes = useStyles();

    return (
        <Card className={classes.cardContainer}>
            <CardContent>
                <p style={{ marginBottom: 20 }} className={classes.title}>
                    {title}
                </p>
                <Divider style={{ marginBottom: 20 }} />
                <div style={{ marginBottom: 20, paddingLeft: 15, paddingRight: 15 }}>
                    {description}
                </div>
                <Divider style={{ marginBottom: 15 }} />
            </CardContent>
            <CardActions>
                {loading ?
                    <div style={{ display: 'flex', margin: '0 auto' }}>
                        <CircularProgress size={30} style={{ color: '#9DCD2A' }} />
                    </div>
                    :
                    <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                        <div style={{ width: '48%' }}>
                            <CustomButton
                                label={titleAccept}
                                width='full'
                                onClick={handleAction}
                            />
                        </div>
                        <div style={{ width: '48%' }}>
                            <CustomButton
                                label={titleCancel}
                                width='full'
                                backgroundred='#F86F46'
                                onClick={() => {
                                    handleClose();
                                }}
                            />
                        </div>
                    </div>
                }
            </CardActions>
        </Card>
    );
});

export default CustomDialogActions;

import React from 'react';
import { makeStyles } from '@material-ui/core';

import useVisitReport from './useVisitReport';
import VisitReportContext from "./VisitReportContext";
import Controls from './Controls';
import ReportFourthContainer from './ReportFourthContainer';

const useStyles = makeStyles({
  infoContainer: {
    borderRadius: 4,
    paddingTop: 30,
    paddingBottom: 30,
    paddingLeft: 30,
    paddingRight: 26,
    background: 'white',
    border: '1px solid #EDEEF0',
    marginTop: 20,
    fontFamily: 'SF Pro Display',
    display: 'flex',
    justifyContent: 'center'
  },
  title: {
    fontSize: 24,
    fontWeight: 'bold',
    margin: 0,
    wordBreak: 'break-all'
  },
});

function Report(props) {
  const { startDateFilt, setStartDateFilt, endtDateFilt, setEndDateFilt } = props;
  const classes = useStyles();
  const reportCtx = useVisitReport();

  return (
    <VisitReportContext.Provider value={reportCtx}>
      <div className={classes.infoContainer}>
        <div style={{ maxWidth: 800, flex: 1 }}>
          <p className={classes.title}>
            Date Range
          </p>
          <Controls
            startDateFilt={startDateFilt}
            setStartDateFilt={setStartDateFilt}
            endtDateFilt={endtDateFilt}
            setEndDateFilt={setEndDateFilt}
          />
        </div>
      </div>
      {reportCtx.reportTime > 0 && (
        <div>
          <ReportFourthContainer />
        </div>
      )}

    </VisitReportContext.Provider>
  );
}

export default Report;

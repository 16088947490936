// Load libraries

import React from 'react';

import { Modal, Card, CardContent, CardHeader } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { theme } from './theme/theme';

// Context

import { appContext, useProviderApp } from './contexts/app';
import { ProvideAuth } from './contexts/v2/auth';

import TagManager from 'react-gtm-module';

import Routes from './routes';

const tagManagerArgs = {
  gtmId: 'GTM-PKLTS7C',
  dataLayerName: 'PageDataLayer'
};

TagManager.initialize(tagManagerArgs);

function App() {
  const appState = useProviderApp();

  return (
    <ThemeProvider theme={theme}>
      <appContext.Provider value={appState}>
        <ProvideAuth>
          <Routes />
        </ProvideAuth>
        <Modal
          style={{
            alignItems: 'center',
            justifyContent: 'center',
            display: 'flex'
          }}
          open={
            appState.error.message && appState.error.message.length > 0
              ? true
              : false
          }
          onClose={() => {
            appState.error.setMessage(null);
          }}
        >
          <Card style={{ margin: 'auto' }}>
            <CardHeader title="Error" />
            <CardContent>{appState.error.message}</CardContent>
          </Card>
        </Modal>
      </appContext.Provider>
    </ThemeProvider>
  );
}

export default App

import React, { useContext, useEffect, useState, useRef } from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import axios from 'axios';
import moment from 'moment';
import { isEmpty } from 'ramda';
import TagManager from 'react-gtm-module';

import {
  Grid,
  CircularProgress,
  Typography,
  makeStyles,
  Dialog
} from '@material-ui/core';
import Input from 'react-phone-number-input/input';
import Autocomplete, { usePlacesWidget } from 'react-google-autocomplete';

import ErrorStateDisplay from '../../ErrorStateDisplay/ErrorStateDisplay';
import { DashboardContext, useDashboard } from './DashboardContext';
import insyncHttps from '../../../insyncHttps/insyncHttps';
import { ingestEncharge } from '../../../utils/encharge';

import OfficeList from './OfficeList';
import CustomTextField from '../../CustomTextField';
import CustomSelect from '../../CustomSelect';
import CustomButton from '../../CustomButton';
import CustomNavBar from '../../CustomNavBar';
import ModalDownload from './ModalDownload';

import * as OfficeV2 from '../../../functions/v2/office';

// Context

import { useAuth } from '../../../contexts/v2/auth';
import { useApp } from '../../../contexts/app';

const useStyles = makeStyles({
  title: {
    fontSize: 22,
    fontWeight: 'bold',
    margin: 0,
    wordBreak: 'break-all'
  },
  subTitle: {
    fontSize: 16,
    fontWeight: 'normal',
    margin: 0,
    color: '#979797'
  },
  textField: {
    fontSize: 16,
    marginBottom: 6,
    marginTop: 15,
    textAlign: 'left',
    color: '#656565'
  },
  cardContainer: {
    margin: 'auto',
    fontFamily: 'SF Pro Display',
    paddingBottom: 38,
    paddingTop: 28,
    paddingLeft: 20,
    paddingRight: 20,
    minWidth: 450,
    maxWidth: 500,
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: 4,
      height: 0
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1'
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#DFE2E2',
      borderRadius: 4
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#888',
      borderRadius: 4
    },
    '& .MuiCardContent-root': {
      padding: 0
    }
  },
  selected: {
    border: '1px solid #ECEEF2',
    height: 48,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 12
  },
  customPopover: {
    '& .MuiPopover-paper': {
      '&::-webkit-scrollbar': {
        width: 6,
        height: 0
      },
      '&::-webkit-scrollbar-track': {
        background: '#f1f1f1'
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#c3c3c3',
        borderRadius: 2
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#888',
        borderRadius: 2
      }
    }
  },
  boxInputPhone: {
    fontFamily: 'SF Pro Display',
    color: 'currentColor',
    border: '1px solid #d7d7d7',
    fontSize: 16,
    opacity: '0.5',
    boxShadow: 'inset 2px 4px 6px rgba(201, 203, 217, 0.15)',
    backgroundColor: 'white',
    borderRadius: 4,
    padding: 16,
    width: '93%',
    '&:focus-visible': {
      outline: 'none'
    }
  },
  customAutocomplete: {
    fontFamily: 'SF Pro Display',
    color: 'currentColor',
    border: '1px solid #d7d7d7',
    fontSize: 16,
    opacity: '0.5',
    boxShadow: 'inset 2px 4px 6px rgba(201, 203, 217, 0.15)',
    backgroundColor: 'white',
    borderRadius: 4,
    padding: 16,
    width: '93%',

    '&:focus-visible': {
      outline: 'none'
    },
  },
  heading: {
      textAlign: 'left',
      fontSize: 35,
      marginBottom: 10,
      fontWeight: 500,
      color: '#1D1D1D'
  },
  typography: {
      fontFamily: 'SF Pro Display',
      textAlign: "left",
      fontSize: 20,
      marginBottom: 10,
      fontWeight: 300,
      color: '#656565'
  },
  reviewThumb: {
      margin: '70px 0px 70px 0px'
  },
  reviewText: {
      fontSize: 20,
      fontWeight: 300,
      color: '#000000',
      marginTop: 70,
      paddingLeft: 35,
      '& img':{
          display: 'block',
          paddingBottom: 15,
      },
  },
  lightText: {
      color: '#000000',
      listStyle: 'none',
      fontSize: 16,
      fontWeight: 300,
      paddingLeft: 0,
      '& li':{
          paddingBottom: 15,
      },
      '& img':{
          width: 25,
          marginRight: 5,
          verticalAlign: 'middle',
      }
  }
});

function Dashboard() {
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();
  // -----------------------------------------
  const { user, isSuperAdmin, reload } = useAuth();
  const app = useApp();
  const inputRef = useRef(null);
  const { officesMeta, getOfficeList, error, loading, showData } =
    useDashboard();

  const [stateDownload, setStateDownload] = useState(false);
  const [loadingSend, setLoadingSend] = useState(false);
  const [addressGoogle, setAddressGoogle] = useState('');
  const [dataAccess, setDataAccess] = useState({});
  const [dataEncharge, setDataEncharge] = useState({});
  // -----------------------------------------
  const [errorField, setErrorField] = useState(false);
  const [formGroup, setFormGroup] = useState({
    officeName: '',
    name: '',
    lastName: '',
    phoneNumber: '',
    employees: '',
    rooms: ''
  });

  const ListNotEmpty = Object.keys(officesMeta).length !== 0;

  const employeesOptions = [
    {
      label: '1-3',
      value: 20
    },
    {
      label: '4-7',
      value: 21
    },
    {
      label: '8-12',
      value: 22
    },
    {
      label: '13-20',
      value: 23
    },
    {
      label: '21-30',
      value: 24
    },
    {
      label: '31-50',
      value: 25
    },
    {
      label: '51+',
      value: 26
    }
  ];

  const roomsOptions = [
    {
      label: '1-5',
      value: 27
    },
    {
      label: '6-10',
      value: 28
    },
    {
      label: '11-20',
      value: 29
    },
    {
      label: '20-30',
      value: 30
    },
    {
      label: '31+',
      value: 31
    }
  ];

  // Check if when selecting the list of offices you
  // have to go to the marketing link
  const gotoExtendPlan =
    location.state && location.state.from === app.extendPlanLink;

  const couponId = location.state && location.state.couponId;

  useEffect(() => {
    getOfficeList();
  }, []);

  useEffect(() => {
    async function fetch() {
      if (showData && !isEmpty(user)) {
        // ---------------------------------------------------
        /**
         * @author Randall Medina
         * @description execute the function to create a user in Encharge
         */
        const payloadRegister = {
          name: 'Registered account created',
          user: {
            email: user && user.email,
            tags: 'acc_created '
          }
        };
        await ingestEncharge(payloadRegister);
        // ---------------------------------------------------
      }
    }
    fetch();
  }, [showData, user]);

  const changeEmployees = (e) => {
    setFormGroup({ ...formGroup, employees: e.target.value });
  };
  const changeRooms = (e) => {
    setFormGroup({ ...formGroup, rooms: e.target.value });
  };

  const submitNewOffice = async () => {
    const tagManagerArgs = {
      dataLayer: {
        event: 'office_created'
      },
      dataLayerName: 'PageDataLayer'
    };
    TagManager.initialize(tagManagerArgs);

    setLoadingSend(true);

    if (
      formGroup.officeName === '' ||
      formGroup.name === '' ||
      formGroup.lastName === '' ||
      formGroup.phoneNumber === '' ||
      addressGoogle === '' ||
      formGroup.employees === '' ||
      formGroup.rooms === ''
    ) {
      setErrorField(true);
      setLoadingSend(false);
    } else {
      try {
        const manyEmployees = employeesOptions.find(
          (t) => t.value === formGroup.employees
        );
        const manyRooms = roomsOptions.find((t) => t.value === formGroup.rooms);
        const createOfficeResponse = await OfficeV2.createOffice({
          name: formGroup.officeName,
          phoneNumber: formGroup.phoneNumber,
          firstName: formGroup.name,
          lastName: formGroup.lastName,
          firstOffice: ''
        });
        const response = createOfficeResponse.data;

        // ---------------------------------------------------
        setDataAccess({
          accessCode: response.accessCode,
          officeId: response.office.id
        });
        // ---------------------------------------------------
        const trial = moment(new Date()).add(14, 'days').format('YYYY/MM/DD');
        // ---------------------------------------------------
        /**
         * @author Randall Medina
         * @description execute the function to create the test plan script
         */
        const resultLoadOffice = await insyncHttps.office.loadOfficeData({
          email: user.email,
          officeName: formGroup.officeName,
          name: formGroup.name,
          lastName: formGroup.lastName,
          phoneNumber: formGroup.phoneNumber,
          address: addressGoogle,
          trialEnds: trial,
          trialDays: 14,

          employees: manyEmployees.label,
          rooms: manyRooms.label,

          officeId: response.office.id,
          firstOffice: ''
        });
        // ----------------------------------------------------------------
        // ----------------------------------------------------------------
        const keys = process.env.REACT_APP_PIPEDRIVE_KEY;
        const header = {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            Accept: 'application/json'
            // Authorization: `Basic ${token}`,
            // 'X-API-Key': `${keys}`,
          }
        };
        // ----------------------------------------------------------------
        /**
         * @author Randall Medina
         * @description execute the function to create organization Pipedrive
         */
        const dataCompany = {
          name: formGroup.officeName,
          address: addressGoogle
        };
        const resultCompany = await axios.post(
          `https://api.pipedrive.com/v1/organizations?api_token=${keys}`,
          dataCompany,
          header
        );
        // ----------------------------------------------------------------
        /**
         * @author Randall Medina
         * @description execute the function to create person Pipedrive
         */
        const dataPerson = {
          name: `${formGroup.name} ${formGroup.lastName}`,
          first_name: formGroup.name, // first time
          last_name: formGroup.lastName, // first time
          phone: formGroup.phoneNumber,
          email: user.email,
          org_id: resultCompany.data.data.id // organization
        };
        const resultPerson = await axios.post(
          `https://api.pipedrive.com/v1/persons?api_token=${keys}`,
          dataPerson,
          header
        );
        // ----------------------------------------------------------------
        /**
         * @author Randall Medina
         * @description execute the function to create deal Pipedrive
         */
        const dataDeal = {
          title: formGroup.officeName,
          value: '0',
          person_id: resultPerson.data.data.id,
          org_id: resultCompany.data.data.id, // pipedrive_office_id
          status: 'open',
          '60108ed00f29ad9becabca264cb7ed29b29a49fb': response.office.id, // insync_account_id
          e5dc866fcebc7338d22c35318913d5419402c21a: 17, // subscription_plan  -- Trial in Pipedrive --
          '0fb823a20107667a44b7d38fc5f456617cf13a23': formGroup.employees, // number_of_users
          '78b810a01d621031d7fd680479bc21c5418d44c6': moment(
            new Date(resultLoadOffice.data.accountCreated)
          ).format('YYYY/MM/DD'), // YYYY-MM-DD. account_created
          efa2633ea344f9cf6789361dde4174d534d09515: moment(new Date()).format(
            'YYYY/MM/DD'
          ), // YYYY-MM-DD. subscription_started
          '003ed7769aa12031d88c37a107be39647cc2d268': trial, // YYYY-MM-DD. trial_ends
          cb154500c92bc3ecebaf13cc26566b0f41453419: formGroup.rooms // number_of_rooms
        };
        await axios.post(
          `https://api.pipedrive.com/v1/deals?api_token=${keys}`,
          dataDeal,
          header
        );
        // ----------------------------------------------------------------
        const payloadSendEncharge = {
          email: user.email,
          userId: response.office.id,
          officeName: formGroup.officeName,
          firstName: formGroup.name,
          lastName: formGroup.lastName,
          phone: formGroup.phoneNumber,
          officeAddress: addressGoogle,
          numberEmployees: manyEmployees.label,
          numberRooms: manyRooms.label,
          tags: 'trial_started'
        };
        setDataEncharge(payloadSendEncharge);
        // ----------------------------------------------------------------
        setAddressGoogle('');
        setFormGroup({
          officeName: '',
          name: '',
          lastName: '',
          phoneNumber: '',
          employees: '',
          rooms: ''
        });
        // ----------------------------------------------------------------

        await reload();

        getOfficeList();
        setLoadingSend(false);
        setErrorField(false);
        setStateDownload(true);
      } catch (error) {
        console.log('error creating first office: ', error);
        setLoadingSend(false);
      }
    }
  };

  return (
    <DashboardContext.Provider
      value={{ officesMeta, getOfficeList, error, loading }}
    >
      <CustomNavBar />
      <Grid container style={{ paddingTop: 30 }} justifyContent="center">
        <Grid
          item
          xs={11}
          sm={11}
          md={11}
          lg={5}
          xl={5}
          style={{ marginTop: 50, fontFamily: 'SF Pro Display' }}
        >
          <div style={{ padding: '10px' }}>
            <p className={classes.heading}>
              Create an effective communication <br></br> system for your staff
              and patients.
            </p>
            <div style={{ display: 'flex' }}>
              <div className={classes.reviewThumb}>
                <img src="/assets/doctor-thumb.svg"></img>
              </div>
              <div className={classes.reviewText}>
                <img src="/assets/quote.svg"></img>
                <img src="/assets/rating.svg"></img>
                <p style={{ margin: '10px 0px 10px 0px', lineHeight: '24px' }}>
                  InSync is like having an extra employee <br></br> who handles
                  all staff communication.
                </p>
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: '700',
                    lineHeight: '24px'
                  }}
                >
                  Stephanie Adler <br></br>{' '}
                  <span style={{ color: '#5190CC' }}>
                    Dental Office Manager
                  </span>
                </p>
              </div>
            </div>
            <ul className={classes.lightText}>
              <li>
                <img src="/assets/security.png"></img> 100% DATA SECURITY &
                PRIVACY
              </li>
              <li>
                <img src="/assets/win.png"></img> Available on all Microsoft
                Windows devices
              </li>
            </ul>
          </div>
          </Grid>
                <Grid
                    item
                    xs={11} sm={11} md={5} lg={4} xl={3}
                    style={{ marginTop: 80 }}>
          {isSuperAdmin && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center'
              }}
            >
              <p
                style={{
                  fontSize: 15,
                  fontWeight: 700,
                  margin: 0,
                  fontFamily: 'SF Pro Display'
                }}
              >
                SUPERADMIN
              </p>
            </div>
          )}
          {loading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                width: '100%'
              }}
            >
              <CircularProgress size={30} style={{ color: '#9DCD2A' }} />
            </div>
          ) : error ? (
            <ErrorStateDisplay
              title="Error Getting Offices"
              message={error}
              retry={getOfficeList}
            />
          ) : ListNotEmpty ? (
            <OfficeList gotoExtendPlan={gotoExtendPlan} couponId={couponId} />
          ) : (
            <div>
              <div style={{ marginBottom: 20 }}>
                <p className={classes.textField}>Office Name</p>
                <CustomTextField
                  value={formGroup.officeName}
                  placeholder="Add a name for your office"
                  error={formGroup.officeName === '' ? errorField : false}
                  onChange={(e) => {
                    setFormGroup({
                      ...formGroup,
                      officeName: e.target.value
                    });
                  }}
                />
                <p className={classes.textField}>First Name</p>
                <CustomTextField
                  value={formGroup.name}
                  placeholder="First Name"
                  error={formGroup.name === '' ? errorField : false}
                  onChange={(e) => {
                    setFormGroup({
                      ...formGroup,
                      name: e.target.value
                    });
                  }}
                />
                <p className={classes.textField}>Last Name</p>
                <CustomTextField
                  value={formGroup.lastName}
                  error={formGroup.lastName === '' ? errorField : false}
                  placeholder="Last Name"
                  onChange={(e) => {
                    setFormGroup({
                      ...formGroup,
                      lastName: e.target.value
                    });
                  }}
                />
                <p className={classes.textField}>Phone</p>
                <Input
                  country="US"
                  placeholder="(000) 000-0000"
                  value={formGroup.phoneNumber}
                  onChange={(e) => {
                    setFormGroup({ ...formGroup, phoneNumber: e });
                  }}
                  maxLength={14}
                  className={classes.boxInputPhone}
                  style={
                    formGroup.phoneNumber === '' && errorField
                      ? {
                          borderWidth: '1px',
                          border: '1px solid #EC5829',
                          background: '#FDEBE3',
                          color: '#EC5829'
                        }
                      : {}
                  }
                />
                <p className={classes.textField}>Address</p>
                <Autocomplete
                  ref={inputRef}
                  apiKey={process.env.REACT_APP_AUTOCOMPLETE_GOOGLE}
                  placeholder={'Address'}
                  className={classes.customAutocomplete}
                  style={
                    addressGoogle === '' && errorField
                      ? {
                          borderWidth: '1px',
                          border: '1px solid #EC5829',
                          background: '#FDEBE3',
                          color: '#EC5829'
                        }
                      : {}
                  }
                  onChange={(e) =>
                    e.target.value === ''
                      ? setAddressGoogle('')
                      : e.target.value
                  }
                  onPlaceSelected={(place, a, c) => {
                    setAddressGoogle(place.formatted_address);
                  }}
                  options={{
                    types: ['geocode', 'establishment'],
                    // types: ["(regions)"],
                    // types: ["address"],
                    componentRestrictions: { country: ['us', 'ca'] }
                  }}
                />
                <p
                  style={{ fontSize: 16, marginBottom: 10, textAlign: 'left', color: '#656565' }}
                >
                  How many employees are in your office?
                </p>
                <CustomSelect
                  value={formGroup.employees}
                  name="employees"
                  options={employeesOptions}
                  onChange={changeEmployees}
                  noOptionsMessage={() => '- No results found -'}
                  error={formGroup.employees === '' ? errorField : false}
                />
                <p
                  style={{ fontSize: 16, marginBottom: 10, textAlign: 'left', color: '#656565' }}
                >
                  How many rooms / areas are in your offices?
                </p>
                <CustomSelect
                  value={formGroup.rooms}
                  name="rooms"
                  options={roomsOptions}
                  onChange={changeRooms}
                  noOptionsMessage={() => '- No results found -'}
                  error={formGroup.rooms === '' ? errorField : false}
                />
                <div style={{ marginTop: 17 }}>
                  {loadingSend ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '100%'
                      }}
                    >
                      <CircularProgress
                        size={30}
                        style={{ color: '#9DCD2A' }}
                      />
                    </div>
                  ) : (
                    <CustomButton
                      label="Create Your first Office"
                      onClick={submitNewOffice}
                      width="full"
                    />
                  )}
                </div>
              </div>
            </div>
          )}
        </Grid>
      </Grid>
      {/* --------------------------------- */}
      {stateDownload && (
        <Dialog
          open={stateDownload}
          // onClose={() => {
          //     setStateDownload(false);
          // }}
        >
          <ModalDownload
            data={dataEncharge}
            showDownload={false}
            code={dataAccess.accessCode}
            officeId={dataAccess.officeId}
            download={process.env.REACT_APP_SETUP_URL}
            handleClose={async () => {
              await reload();
              setStateDownload(false);
              history.push(`/office/${dataAccess.officeId}`, {
                showVideo: true
              });
            }}
          />
        </Dialog>
      )}
      {/* --------------------------------- */}
    </DashboardContext.Provider>
  );
}

export default Dashboard;

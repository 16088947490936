import * as billing from "./billing/billing";
import * as insyncUser from "./insyncUser/insyncUser";
import * as messaging from "./messaging/messaging";
import * as paging from "./paging/paging";
import * as office from "./office/office";
import * as patientTracking from "./patientTracking/patientTracking";
import * as adminUser from "./adminUser/adminUser";

export default {
    billing,
    insyncUser,
    messaging,
    paging,
    office,
    patientTracking,
    adminUser
};

import React, { useEffect, useState } from 'react';
import { Switch, Route, useLocation, useParams } from 'react-router-dom';

// Context

import { OfficeContext, useOffice } from './OfficeContext';
import { usePlanId, PlanContext } from './PlanContext';
import { useApp } from '../../../contexts/app';

import Billing from './Billing';
import CustomNavBar from '../../CustomNavBar';
import CustomModalVideo from '../../CustomModalVideo';
import AplicationSettings from './AplicationSettings';
import Access from './Access';
import Reports from './Reports';
import PlanDetails from './PlanDetails';
import OfficeExtendPlan from './OfficeExtendPlan';
import PlansDetailsCoupon from './PlansDetailsCoupon';

function OfficeDetail(props) {
  const location = useLocation();
  const params = useParams();
  const locationData =
    location.state && location.state.showVideo ? true : false;
  const paramsOfficeId =
    (location.state && location.state.officeId) || params.id;

  const [value, setValue] = useState(0);
  const planId = usePlanId(paramsOfficeId);
  const [openVideo, setOpenVideo] = useState(false);
  const app = useApp();

  useEffect(() => {
    planId.getPlanById();
  }, []);

  useEffect(() => {
    if (locationData) {
      setTimeout(() => {
        setOpenVideo(true);
      }, 2000);
    }
  }, [locationData]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <OfficeContext.Provider value={useOffice(paramsOfficeId)}>
      <CustomNavBar
        tabs={true}
        handleChange={handleChange}
        value={value}
        officeId={paramsOfficeId}
      />
      {openVideo && (
        <CustomModalVideo
          open={openVideo}
          toggleModal={() => setOpenVideo(false)}
          url={`${process.env.REACT_APP_WACTH_DEMO}`}
        />
      )}
      <PlanContext.Provider value={planId}>
        <Switch>
          {/* <Route path="/office/:id" exact component={PlanDetails} /> */}
          <Route path="/office/:id" exact component={AplicationSettings} />
          <Route path="/office/:id/plans" exact component={PlanDetails} />
          <Route path="/office/:id/access" exact component={Access} />
          <Route path="/office/:id/report" exact component={Reports} />
          <Route path="/office/:id/billing" exact component={Billing} />
          <Route path={app.extendPlanToProccess} exact component={OfficeExtendPlan} />
          <Route path={app.couponLinkToProccess} exact component={PlansDetailsCoupon} />
        </Switch>
      </PlanContext.Provider>
    </OfficeContext.Provider>
  );
}

export default OfficeDetail;

import React, { useEffect, useContext, useState } from "react";
import { isEmpty } from "ramda";
import moment from "moment";

import { makeStyles, IconButton, Divider, Dialog, CircularProgress } from '@material-ui/core';
import AddRoundedIcon from '@material-ui/icons/AddRounded';

import { MessagingContext, useMessaging } from "./MessagingContext";
import { OfficeContext } from "../OfficeContext";
import { PlanContext } from "../PlanContext";

import reorderUsers from "../../../../insyncHttps/messaging/reorderUsers";
import { mainRouterHistory } from "../../../../routerHistories";

import CustomButton from '../../../CustomButton';
import CustomBannerPlan from '../../../CustomBannerPlan';

import HeaderInformation from './HeaderInformation';
import BodyInformation from './BodyInformation';
import AddUser from "./AddUser";

const useStyles = makeStyles({
    infoContainer: {
        borderRadius: 4,
        paddingTop: 30,
        paddingBottom: 30,
        paddingLeft: 30,
        paddingRight: 26,
        background: 'white',
        border: '1px solid #EDEEF0',
        marginTop: 30,
        fontFamily: 'SF Pro Display'
    },
    title: {
        fontSize: 24,
        fontWeight: 'bold',
        margin: 0,
        wordBreak: 'break-all'
    },
    subTitle: {
        fontSize: 16,
        fontWeight: 'normal',
        margin: 0,
        color: '#979797',
        marginRight: 10,
        marginLeft: 10,
    },
});

function Messaging() {
    const classes = useStyles();
    const { officeId } = useContext(OfficeContext);
    const messaging = useMessaging(officeId);
    // ------------------------------------------
    const { getPlanById, planItem, loadingPlan } = useContext(PlanContext);
    const currentDate = moment(new Date()).format('YYYY/MM/DD');
    // ------------------------------------------

    const [addUserOpen, setAddUserOpen] = useState(false);
    const [orderedUserIds, setOrderedUserIds] = useState([]);
    const [orderModified, setOrderModified] = useState(false);
    const [saveOrderLoading, setSaveOrderLoading] = useState(false);


    function moveUserUp(userId) {
        const currentIndex = orderedUserIds.indexOf(userId);
        if (currentIndex > 0) {
            orderedUserIds.splice(currentIndex, 1);
            orderedUserIds.splice(currentIndex - 1, 0, userId);
            setOrderModified(true);
            setOrderedUserIds([...orderedUserIds]);
        }
    }

    function moveUserDown(userId) {
        const currentIndex = orderedUserIds.indexOf(userId);
        if (currentIndex < orderedUserIds.length - 1) {
            orderedUserIds.splice(currentIndex, 1);
            orderedUserIds.splice(currentIndex + 1, 0, userId);
            setOrderedUserIds([...orderedUserIds]);
            setOrderModified(true);
        }
    }

    useEffect(() => {
        messaging.getMessaging();
        getPlanById();
    }, []);

    useEffect(() => {
        setOrderModified(false);
        setOrderedUserIds(getSortedPersonIds(messaging.users));
    }, [messaging.users]);


    const changeOrder = () => {
        setSaveOrderLoading(true);
        reorderUsers({
            officeId,
            usersId: orderedUserIds
        })
            .then(result => {
                setSaveOrderLoading(false);
                messaging.getMessaging();
            })
            .catch(err => {
                setSaveOrderLoading(false);
                messaging.getMessaging();
            });
    }

    return (
        <MessagingContext.Provider value={messaging}>
            {loadingPlan ?
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress size={30} style={{ color: '#9DCD2A' }} />
                </div>
                :
                <div>
                    {!isEmpty(planItem) && (
                        <div>
                            {planItem.showBannerUsers && (
                                <CustomBannerPlan
                                    boxBackground={'#F86F46'}
                                    boxText={'You have reached the maximum amount of users in your plan. To add more click "upgrade plan"'}
                                    daysOff={false} // Boolean
                                    textDaysOff={''}
                                    colorDaysOff={''}
                                    btnText={'UPGRADE PLAN'}
                                    btnOnClick={`/office/${officeId}/plans`}
                                    btnTextColor={'#F86F46'}
                                />
                            )}
                            {planItem.showBannerTrial && (
                                <CustomBannerPlan
                                    boxBackground={'#197FE8'}
                                    boxText={`${planItem.planName}`}
                                    daysOff={true} // Boolean
                                    textDaysOff={`${moment(planItem.trialEnds).diff(currentDate, 'days')} days left`}
                                    colorDaysOff={'#358FEB'}
                                    btnText={'CHOOSE A PLAN'}
                                    btnOnClick={`/office/${officeId}/plans`}
                                    btnTextColor={'#197FE8'}
                                />
                            )}
                        </div>
                    )}
                </div>
            }
            <div className={classes.infoContainer}>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: 16
                }}>
                    <p className={classes.title}> Users </p>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {saveOrderLoading ?
                            <CircularProgress size={30} style={{ color: '#9DCD2A' }} />
                            :
                            orderModified && (
                                <div style={{ minWidth: 130 }}>
                                    <CustomButton
                                        width='full'
                                        label='save'
                                        onClick={changeOrder}
                                    />
                                </div>
                            )
                        }
                        <p className={classes.subTitle}>
                            Create new user
                        </p>
                        <IconButton
                            style={{ background: 'rgba(157, 162, 180, 0.08)', padding: 5 }}
                            onClick={() => {
                                setAddUserOpen(true);
                            }}
                        >
                            <AddRoundedIcon style={{ color: '#9DA2B4' }} fontSize='medium' />
                        </IconButton>
                    </div>
                    <Dialog
                        open={addUserOpen}
                    >
                        <AddUser
                            handleClose={() => {
                                setAddUserOpen(false);
                                getPlanById();
                            }}
                        />
                    </Dialog>
                </div>
                <Divider style={{ background: '#EDEEF0' }} />
                <HeaderInformation />
                <BodyInformation
                    orderedUserIds={orderedUserIds}
                    moveUserDown={moveUserDown}
                    moveUserUp={moveUserUp}
                    orderModified={orderModified}
                />
            </div>
        </MessagingContext.Provider>
    );
}

export default Messaging;


function getSortedPersonIds(users) {
    return Object.keys(users)
        .filter(userId => {
            return Object.keys(users).includes(userId);
        })
        .sort((a, b) => {
            if (users[a].name > users[b].name) {
                return 1;
            }
            if (users[a].name < users[b].name) {
                return -1;
            }
            return 0;
        })
        .sort((a, b) => {
            const personA = { ...users[a] };
            const personB = { ...users[b] };
            personA.listPosition = personA.listPosition
                ? personA.listPosition
                : 0;
            personB.listPosition = personB.listPosition
                ? personB.listPosition
                : 0;

            return personA.listPosition - personB.listPosition;
        });
}
import React, { useState, forwardRef, useContext } from "react";
import {
  Card,
  CardContent,
  CardActions,
  Divider,
  CircularProgress,
  makeStyles,
} from "@material-ui/core";
import CustomButton from "../../CustomButton";
import CustomSelect from "../../CustomSelect";
import CustomTextField from "../../CustomTextField";
import insyncHttps from "../../../insyncHttps/insyncHttps";
import { appContext } from "../../../contexts/app";


const useStyles = makeStyles({
  cardContainer: {
    margin: "auto",
    paddingBottom: 38,
    paddingTop: 28,
    paddingLeft: 20,
    paddingRight: 20,
    minWidth: 400,
    maxWidth: 500,
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: 4,
      height: 0,
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#DFE2E2',
      borderRadius: 4,
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#888',
      borderRadius: 4,
    },
    '& .MuiCardContent-root': {
      padding: 0
    }
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
    margin: 0,
    wordBreak: 'break-all',
    fontFamily: 'SF Pro Display'
  },
  subTitle: {
    fontSize: 16,
    fontWeight: 'normal',
    margin: 0,
    color: '#979797',
    fontFamily: 'SF Pro Display'
  },
});

const AddCoupon = forwardRef((props, ref) => {
  const { handleClose, useCupons, coupons } = props;
  const classes = useStyles();

  const { error } = useContext(appContext);

  const [loading, setLoading] = useState(false);
  const [errorField, setErrorField] = useState(false);
  const [form, setForm] = useState({
    name: '',
    description: '',
    percentageDiscount: '',
    chargebeeCouponId: '',
  });

  const onChangeText = (prop) => (event) => {
    setForm({ ...form, [prop]: event.target.value })
  };

  const changeNumber = (prop) => (event) => {
    const re = /^[0-9\b]+$/;
    if (event.target.value.length === 0 || re.test(event.target.value)) {
      setForm({ ...form, [prop]: event.target.value })
    }
  };

  const subminNewCoupon = () => {
    setLoading(true)
    if (form.name === '' || form.description === '' || form.chargebeeCouponId === '' || form.percentageDiscount === '') {
      setErrorField(true)
    } else {
      insyncHttps.adminUser.createCoupon({
        ...form,
      })
        .then(result => {
          useCupons.getCouponList()
          setLoading(false)
          handleClose()
        })
        .catch(err => {
          setLoading(false)
          error.setMessage(err.message);
        })
    }
  }

  const couponsOptions = coupons && coupons.length > 0 ? coupons.map(e =>
    ({ label: e.coupon.name, value: e.coupon.id })
  ) : { label: '', value: '', };

  return (
    <Card ref={ref} className={classes.cardContainer}>
      <CardContent>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginLeft: 25,
            marginRight: 25,
            fontFamily: 'SF Pro Display'
          }}
        >
          <p style={{ marginBottom: 10 }} className={classes.title}>
            Add Coupon
          </p>
          <p
            style={{ marginBottom: 10, cursor: 'pointer' }}
            onClick={handleClose}
            className={classes.subTitle}
          >
            Cancel
          </p>
        </div>
        <Divider style={{ backgroundColor: '#EDEEF0' }} />
        <div style={{ marginBottom: 46, paddingLeft: 25, paddingRight: 25, }}>
          <p style={{ fontSize: 16, marginBottom: 10, textAlign: 'left', marginTop: 15 }}>
            Coupon Title
          </p>
          <CustomTextField
            value={form.name}
            onChange={onChangeText('name')}
            maxLength={30}
            error={form.name === '' ? errorField : false}
          />
          <p style={{ fontSize: 16, marginBottom: 10, textAlign: 'left', marginTop: 15 }}>
            Coupon description
          </p>
          <CustomTextField
            value={form.description}
            onChange={onChangeText('description')}
            multiline={true}
            maxLength={50}
            rows={3}
            error={form.description === '' ? errorField : false}
          />
          <p style={{ fontSize: 16, marginBottom: 10, textAlign: 'left', marginTop: 15 }}>
            Percentage discount
          </p>
          <CustomTextField
            value={form.percentageDiscount}
            onChange={changeNumber('percentageDiscount')}
            iconstart='%'
            maxLength={2}
            error={form.percentageDiscount === '' ? errorField : false}
          />
          <p style={{ fontSize: 16, marginBottom: 10, textAlign: 'left', marginTop: 15 }}>
            Chargebee coupon
          </p>
          <CustomSelect
            options={couponsOptions}
            value={form.chargebeeCouponId}
            name='chargebeeCouponId'
            onChange={onChangeText('chargebeeCouponId')}
            error={form.chargebeeCouponId === '' ? errorField : false}
          />
        </div>
        <Divider style={{ backgroundColor: '#EDEEF0' }} />
      </CardContent>
      <CardActions style={{ marginTop: 20, paddingLeft: 25, paddingRight: 25 }}>
        {loading ?
          <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <CircularProgress size={30} style={{ color: '#9DCD2A' }} />
          </div>
          :
          <CustomButton
            disabled={loading}
            width='full'
            label='Save'
            onClick={subminNewCoupon}
          />
        }
      </CardActions>
    </Card>
  );
});
export default AddCoupon
import React, { useState, useContext, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import {
    Card,
    CardContent,
    TextField,
    CardHeader,
    Select,
    Button,
    MenuItem,
    Grid,
    FormControl,
    InputLabel,
    Link,
    Typography
} from "@material-ui/core";

// import insyncHttps from "../../../../insyncHttps/insyncHttps";
import { ingestEncharge } from '../../../utils/encharge';
import { DashboardContext } from "../Dashboard/DashboardContext";
import { isEmpty } from "ramda";
import { useAuth } from "../../../contexts/v2/auth";

const useStyles = makeStyles((theme) => ({
    textTitle: {
        '& .MuiCardHeader-content .MuiTypography-root': {
            fontWeight: 'bold',
            fontSize: 30,
            fontStyle: 'normal',
            fontFamily: 'SF Pro Display',
        }
    },
    textInfo: {
        fontSize: 18,
        fontStyle: 'normal',
        color: '#646464',
        fontFamily: 'SF Pro Display',
    },
    textInfoTwo: {
        fontSize: 18,
        fontStyle: 'normal',
        color: '#000',
        fontFamily: 'SF Pro Display',
        fontWeight: 900
    }
}))


export default function (props) {

    const handleClose = props.handleClose;
    const classes = useStyles();
    const auth = useAuth();
    const { officesMeta } = useContext(DashboardContext);

    const handleWidgetClose = async () => {
        // ---------------------------------------------------
        /**
         * @author Randall Medina
         * @description execute the function to add the Encharge attributes to the first office
         */
        if(!isEmpty(props.data)){
            const payloadRegister = {
                name: 'Registered office',
                user: {
                    ... props.data,
                    tags: 'trial_started'
                }
            };
            const [first] = Object.keys(officesMeta).map(id => id);
            if (Object.keys(officesMeta).length === 0) {
                await ingestEncharge(payloadRegister)
            }
        }
        // ---------------------------------------------------
        handleClose();
    }

    const handleDownloadApp = async () => {
        // ---------------------------------------------------
        /**
         * @author Randall Medina
         * @description execute the function to add attribute Encharge
         */
        const payloadRegister = {
            "name": "Registered widget",
            "user": {
                "email": auth.user.email,
                // "userId": props.officeId,
                "tags": "_widget"
            }
        }
        const [first] = Object.keys(officesMeta).map(id => id);
        if (Object.keys(officesMeta).length === 0) {
            await ingestEncharge(payloadRegister)
        }
        // ---------------------------------------------------
    }

    return (
        <Card style={{ border: '1px solid #000', margin: 6 }}>
            <CardHeader style={{}} className={classes.textTitle} title="Congratulation you have successfully created your office on Insync" />
            <CardContent>
                <Grid container align="center" spacing={2}>
                    <Grid item xs={12} style={{ textAlign: 'left' }}>

                        <Typography className={classes.textInfoTwo}>You can now install the App on all your office computers.</Typography>
                        <br />
                        <Typography className={classes.textInfoTwo}>This will allow your staff members to use Insync directly by launching it.</Typography>
                        <Typography className={classes.textInfoTwo}>There is no logging in and out required.</Typography>

                        <Typography className={classes.textInfo} style={{ marginBottom: 15, marginTop: 15 }}>
                            Please click on the button below to download the App.
                        </Typography>

                        {/* <Link onClick={handleDownloadApp} target="_blank" style={{ textDecoration: 'revert', color: '#197fe8', fontWeight: 500 }} href={`${props.download}`}>https://insyncapp.io/install</Link> */}

                        <Button
                            href={props.download}
                            target="_blank"
                            role="button"
                            variant="outlined"
                            style={{
                                fontSize: 15, fontFamily: 'SF Pro Display', color: '#fff', borderColor: '#fff',
                                textTransform: 'none',
                                background: 'linear-gradient(rgb(181, 227, 70) 0%, rgb(159, 207, 44) 100%)',
                            }}
                            onClick={handleDownloadApp}
                        >
                            Download App
                        </Button>

                        {/* <Typography className={classes.textInfo} style={{ marginBottom: 15, marginTop: 15 }}>
                            You can also copy and paste it easily on any browser.
                        </Typography> */}

                        <Typography className={classes.textInfo} style={{ marginBottom: 15, marginTop: 15 }}>
                            Use the code <span style={{ fontWeight: 700 }}>{props.code}</span>  to connect directly to your office
                        </Typography>
                        <Typography className={classes.textInfo} style={{ marginBottom: 15, marginTop: 15 }}>
                            Once this is done you can click the "next" button to setup your users.
                        </Typography>

                        <Typography className={classes.textInfo} style={{ marginBottom: 15, marginTop: 15 }}>
                            If you cannot install the App right now, you can always access the download page later in the portal.
                        </Typography>

                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            // href={props.download}
                            // target="_blank"
                            // role="button"
                            variant="outlined"
                            style={{
                                fontSize: 15, fontFamily: 'SF Pro Display', color: '#fff', borderColor: '#fff',
                                padding: '10px 80px',
                                background: 'linear-gradient(rgb(181, 227, 70) 0%, rgb(159, 207, 44) 100%)',
                            }}
                            onClick={handleWidgetClose}
                        >
                            NEXT
                        </Button>
                        {props.showDownload && (
                            <Button
                                variant="outlined"
                                style={{
                                    background: 'linear-gradient(rgb(181, 227, 70) 0%, rgb(159, 207, 44) 100%)',
                                    fontSize: 15, fontFamily: 'SF Pro Display', color: '#fff', borderColor: '#fff',
                                    marginLeft: 20, padding: '10px 20px'
                                }}
                                onClick={() => {
                                    handleClose('close');
                                }}
                            >
                                CLOSE
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

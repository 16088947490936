import { createContext, useState, useContext } from 'react';

export const appContext = createContext();

export const useProviderApp = () => {
  const [message, setMessage] = useState('');

  return {
    error: { message, setMessage },
    extendPlanLink: '/extend-plan',
    extendPlanToProccess: '/office/:officeId/extend-plan-to-process',
    couponLink: '/coupon/:couponId',
    couponLinkToProccess: '/office/:officeId/coupon/:couponId',
  };
};

export const useApp = () => {
  return useContext(appContext);
};
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { isEmpty } from "ramda";
import {
  makeStyles, IconButton, Divider, Dialog, CircularProgress, Grid, Avatar,
  Button, useMediaQuery, Card, Modal, CardContent, CardActions
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';

import { useAuth } from '../../../../contexts/v2/auth';
import insyncHttps from '../../../../insyncHttps/insyncHttps';

import { appContext } from '../../../../contexts/app';
import { PlanContext } from '../../OfficeDetail/PlanContext';
import ErrorStateDisplay from '../../../ErrorStateDisplay/ErrorStateDisplay';
import { PlanDetailsContext, usePlansList } from '../../OfficeDetail/PlanDetails/PlanDetailsContext';
import { useCouponChargebee, usePaymentSources, usePlansChargebee, useSubscriptions } from '../../OfficeDetail/Billing/BillingContext';

import CustomButton from '../../../CustomButton';
import DialogCustomPlan from './DialogCustomPlan';
import CustomDialogActions from '../../../CustomDialogActions';

const useStyles = makeStyles({
  bannerProm: {
    width: '70%',
    padding: 15,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#F86F46',
    borderRadius: 8,
    marginBottom: 20,
    textAlign: 'center',
    flexDirection: 'column'
  },
  textBanner: {
    color: 'white',
    margin: 0,
    fontSize: 32
  },
  containerCardPlans: {
    display: 'flex',
    flexFlow: 'row wrap',
    marginRight: 'auto',
    marginLeft: 'auto',
    position: 'relative'
  },
  populated: {
    margin: 10,
    padding: 20,
    borderRadius: 16,
    background: 'white'
  },
  textsCardsPlans: {
    color: '#3A3E4B',
    fontStyle: 'normal',
    fontSize: 32,
    fontWeight: 900,
    margin: 0
  },
  customBubble: {
    fontSize: 16,
    fontWeight: 700,
    color: '#fff',
    minHeight: 24,
    minWidth: 24,
    height: 'fit-content',
    width: 'fit-content',
    marginRight: 4,
    fontFamily: "SF Pro Display",
    '& .interntal': {
      display: 'flex',
      alignItems: 'flex-start',
      padding: '4px 7px',
      borderRadius: 28,
    }
  },
  buttonCard: {
    width: '100%',
    textTransform: 'inherit',
    borderRadius: 8,
    fontSize: 16,
    fontFamily: "SF Pro Display",
    border: '2px solid #F86F46',
    color: '#F86F46',
  }
});

function PlansDetailsCoupon() {
  const params = useParams();
  const history = useHistory();

  const { officeId, couponId } = params;

  const classes = useStyles();
  // --------------------------------------------
  const auth = useAuth();
  const { error } = useContext(appContext);
  const usePlans = usePlansList();
  const plansChargebee = usePlansChargebee();
  const paymentSources = usePaymentSources(officeId);
  const { getCouponById, itemCoupon, loading } = useCouponChargebee(couponId);
  const subscriptions = useSubscriptions(officeId);
  // --------------------------------------------
  const { getPlanById, planItem, errorPlan, loadingPlan } = useContext(PlanContext);
  // --------------------------------------------
  const lgDown = useMediaQuery('(max-width:1280px)');
  const mdDown = useMediaQuery('(max-width:960px)');
  const smDown = useMediaQuery('(max-width:600px)');
  // --------------------------------------------
  const [dialogPlan, setDialogPlan] = useState(false);
  const [dataInitPlan, setDataInitPlan] = useState([]);
  const [dialogPayment, setDialogPayment] = useState(false);
  const [loadingSub, setLoadingSub] = useState(false);
  // --------------------------------------------

  useEffect(() => {
    usePlans.getListPlans();
    getPlanById();
    plansChargebee.getPlans();
    paymentSources.getSources();
    subscriptions.getSubs();
    getCouponById();
  }, []);

  useEffect(() => {
    function fetch() {
      if (usePlans.planList.length > 0 && !isEmpty(planItem) && planItem.planId !== '') {
        const data = usePlans.planList.map(t => ({
          ...t,
          selected: ((t.id === planItem.planId) || (planItem.customPlan && t.name === 'Enterprise') || (planItem.showBannerTrial && t.name === 'Enterprise'))
            ? true : false,
          isPayment: (planItem.showBannerTrial && t.name === 'Enterprise') ? false : true
        }));
        setDataInitPlan(data)
      } else if (usePlans.planList.length > 0 && !isEmpty(planItem) && planItem.planId === '') {
        const data = usePlans.planList.map(t => ({ ...t, isPayment: (planItem.showBannerTrial && t.name === 'Enterprise') ? false : true }));
        setDataInitPlan(data)
      } else {
        setDataInitPlan([])
      }

    }
    fetch();
  }, [usePlans.planList, planItem]);

  const subminPlan = (plan) => () => {
    const subscriptionId = subscriptions.subs && subscriptions.subs.length > 0 ? subscriptions.subs[0].id : '';
    if (plan.isInitial && plan.price === '0') {
      setLoadingSub(true);
      insyncHttps.billing
        .updateSubscription({
          chargebeePlanId: plan.chargebeePlanId,
          planQuantity: 1,
          planId: plan.id,
          officeId,
          subscriptionId,
          renew: false,
          coupon: true,
          chargebeeCouponId: couponId
        })
        .then(result => {
          setLoadingSub(false);
          history.push(`/office/${officeId}/billing`);
        })
        .catch(err => {
          error.setMessage(err.message);
          setLoadingSub(false);
        });
    } else {
      if (paymentSources.sources.length > 0) {
        setLoadingSub(true);
        insyncHttps.billing
          .updateSubscription({
            chargebeePlanId: plan.chargebeePlanId,
            planQuantity: 1,
            planId: plan.id,
            officeId,
            subscriptionId,
            renew: false,
            coupon: true,
            chargebeeCouponId: couponId
          })
          .then(result => {
            setLoadingSub(false);
            history.push(`/office/${officeId}/billing`);
          })
          .catch(err => {
            error.setMessage(err.message);
            setLoadingSub(false);
          });
      } else {
        setDialogPayment(true);
      }
    }
  }

  const onCustomPlan = () => {
    if (paymentSources.sources.length > 0) {
      setDialogPlan(true);
    } else {
      setDialogPayment(true);
    }
  }

  const caculatePriceDiscount = (p, d) => {
    const deduction = (p * d) / 100;
    const finalValue = Math.round(p - deduction)
    return finalValue;
  };

  return (
    <Grid
      container
      style={{ padding: 30, display: 'flex', fontFamily: "SF Pro Display-Bold" }}
      alignItems='center'
      direction='column'
      justifyContent='center'
    >
      <Grid
        item
        xs={11} lg={10} xl={9}
        style={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}
      >
        {!usePlans.loadingPlans && dataInitPlan.length !== 0 && (
          <div className={classes.bannerProm} style={{ width: smDown ? '100%' : '75%' }}>
            <p className={classes.textBanner} style={{ fontSize: mdDown ? 32 : 35 }}>
              {itemCoupon.name}
            </p>
            <p className={classes.textBanner} style={{ fontSize: 20, fontFamily: 'SF Pro Display' }}>
              {itemCoupon.description}
            </p>
          </div>
        )}

        <div
          style={{ flexDirection: smDown ? 'column' : 'row' }}
          className={classes.containerCardPlans}
        >
          {dataInitPlan.map((t, index) => (
            <div
              key={index}
              style={{ width: smDown ? '100%' : mdDown ? '50%' : lgDown ? '25%' : '20%' }}
            >
              <div
                className={classes.populated}
                style={{ border: t.selected ? '4px solid #9DCD2A' : '4px solid #EDEEF0' }}
              >
                <div style={{ marginBottom: 20, textAlign: 'let' }}>
                  <h2 className={classes.textsCardsPlans}>
                    {t.name}
                  </h2>
                </div>
                {t.selected && (
                  <div style={{ marginBottom: 30, textAlign: 'let' }}>
                    <h2 style={{ color: '#9DCD2A', fontSize: 16 }} className={classes.textsCardsPlans}>
                      Your current plan
                    </h2>
                  </div>
                )}
                <div style={{ marginBottom: 20, textAlign: 'left' }}>
                  <h2
                    style={{ fontSize: 16 }}
                    className={classes.textsCardsPlans}
                  >
                    Paging / Messaging
                  </h2>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', width: '100%', marginTop: 6 }}>
                  <Avatar className={classes.customBubble}>
                    <div className='interntal' style={{ backgroundColor: t.selected ? '#9DCD2A' : '#9DA2B4' }}>
                      {t.unlimited_users ? <AllInclusiveIcon style={{ width: "0.7em", height: "0.7em" }} /> : t.users}
                    </div>
                  </Avatar>
                  <h2 style={{ fontSize: 16, fontWeight: 400, color: '#848B8D' }} className={classes.textsCardsPlans}>
                    Users
                  </h2>
                </div>

                <div style={{ display: 'flex', alignItems: 'center', width: '100%', marginTop: 6 }}>
                  <Avatar className={classes.customBubble}>
                    <div className='interntal' style={{ backgroundColor: t.selected ? '#9DCD2A' : '#9DA2B4' }}>
                      {t.unlimited_rooms ? <AllInclusiveIcon style={{ width: "0.7em", height: "0.7em" }} /> : t.rooms}
                    </div>
                  </Avatar>
                  <h2 style={{ fontSize: 16, fontWeight: 400, color: '#848B8D' }} className={classes.textsCardsPlans}>
                    Rooms
                  </h2>
                </div>
                <div style={{ marginBottom: 18, marginTop: 18, textAlign: 'left' }}>
                  <h2 style={{ fontSize: 16 }} className={classes.textsCardsPlans}>Patient Tracking</h2>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar className={classes.customBubble}>
                    <div className='interntal' style={{ backgroundColor: t.selected ? '#9DCD2A' : '#9DA2B4' }}>
                      {t.unlimited_stages ? <AllInclusiveIcon style={{ width: "0.7em", height: "0.7em" }} /> : t.stages}
                    </div>
                  </Avatar>
                  <h2 style={{ fontSize: 16, fontWeight: 400, color: '#848B8D' }} className={classes.textsCardsPlans}>
                    Tracking Stage
                  </h2>
                </div>
                <div style={{ marginBottom: 18, marginTop: 18, textAlign: 'left' }}>
                  <h2 style={{ fontSize: 16 }} className={classes.textsCardsPlans}>Concurrent Connections</h2>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Avatar className={classes.customBubble}>
                    <div className='interntal' style={{ backgroundColor: t.selected ? '#9DCD2A' : '#9DA2B4' }}>
                      {t.unlimited_connections ? <AllInclusiveIcon style={{ width: "0.7em", height: "0.7em" }} /> : t.connections}
                    </div>
                  </Avatar>
                  <h2 style={{ fontSize: 16, fontWeight: 400, color: '#848B8D' }} className={classes.textsCardsPlans}>
                    Connections
                  </h2>
                </div>
                <div style={{ marginBottom: 10 }}>
                  <div style={{ paddingTop: 18, paddingBottom: (!t.isPayment) ? 10 : 0 }}>
                    <Divider style={{ color: '#C9CBD947' }} />
                  </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: 15, height: 45, display: 'flex' }}>
                  {t.price !== '' ?
                    <>
                      <div style={{ marginRight: 7, }}>
                        <sup style={{ fontSize: 16, verticalAlign: 'sub', textDecoration: t.price !== '0' ? 'line-through' : 'none' }}>
                          $
                        </sup>
                        <span style={{ fontSize: 45, textDecoration: t.price !== '0' ? 'line-through' : 'none' }}>
                          {t.price}
                        </span>
                      </div>
                      <h2
                        style={{ fontSize: 16, fontWeight: 400, color: '#848B8D', textDecoration: t.price !== '0' ? 'line-through' : 'none' }}
                        className={classes.textsCardsPlans}
                      >
                        License <br /> per month
                      </h2>
                    </>
                    :
                    <div style={{ marginRight: 7 }}>
                      <span style={{ fontSize: 27 }}>Call for Pricing</span>
                    </div>
                  }
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: 15, display: 'flex' }}>
                  {t.price !== '' && t.price !== '0' && (

                    <>
                      <div style={{ marginRight: 7, color: '#F86F46' }}>
                        <sup style={{ fontSize: 16, verticalAlign: 'sub' }}>
                          $
                        </sup>
                        <span style={{ fontSize: 45 }}>
                          {caculatePriceDiscount(t.price, itemCoupon.percentageDiscount)}
                        </span>
                      </div>
                      <h2
                        style={{ fontSize: 16, fontWeight: 400, color: '#F86F46' }}
                        className={classes.textsCardsPlans}
                      >
                        License <br /> per month
                      </h2>
                    </>
                  )}
                </div>

                <div style={{ marginBottom: 10, textAlign: 'center' }}>
                  {!loadingSub && (
                    t.price === '0' ? '' :
                      t.price === '' ?
                        <Button
                          href='https://insyncapp.io'
                          target="_blank"
                          role="button"
                          variant='outlined'
                          className={classes.buttonCard}
                          style={{ border: '2px solid #197FE8', color: '#197FE8' }}
                          // onClick={subminPlan(t)}
                          endIcon={<ArrowForwardIcon />}
                        >
                          Contact US
                        </Button>
                        :
                        <Button
                          variant='outlined'
                          onClick={subminPlan(t)}
                          endIcon={<ArrowForwardIcon />}
                          className={classes.buttonCard}
                        >
                          Choose plan
                        </Button>
                  )}
                </div>
              </div>
            </div>
          ))}

          {loadingSub && (
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
              <CircularProgress size={40} style={{ color: '#9DCD2A', marginRight: 5 }} /> Processing ...
            </div>
          )}
          {loadingPlan || usePlans.loadingPlans || dataInitPlan.length === 0 ?
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
              <CircularProgress size={40} style={{ color: '#9DCD2A' }} />
            </div>
            : null
            }
          {usePlans.errorPlans && (
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
              <ErrorStateDisplay
                title="Error Getting Plans List"
                message={usePlans.errorPlans}
                retry={usePlans.getListPlans}
              />
            </div>
          )}
          {errorPlan && (
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
              <ErrorStateDisplay
                title="Error Getting Plan by Id"
                message={errorPlan}
                retry={getPlanById}
              />
            </div>
          )}

        </div>
      </Grid>
      <Grid
        item
        xs={11} lg={3} xl={3}
        style={{ marginTop: 30 }}
      >
        {!usePlans.loadingPlans && dataInitPlan.length > 0 && auth.isSuperAdmin && (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <CustomButton
              label='Custom Plan'
              onClick={onCustomPlan}
              width='full'
            />
          </div>
        )}
        {dialogPlan && (
          <Dialog
            open={dialogPlan}
          >
            <Grid container justifyContent="center" align="center">
              <Grid item xs={12}>
                <DialogCustomPlan
                  plansChargebee={plansChargebee}
                  // subscriptions={subscriptions}
                  handleClose={() => {
                    setDialogPlan(false);
                    history.push(`/office/${officeId}/billing`);
                  }}
                />
              </Grid>
            </Grid>
          </Dialog>
        )}
        {dialogPayment && (
          <Modal
            style={{ display: "flex" }}
            open={dialogPayment}
            onClose={() => {
              setDialogPayment(false);
            }}
          >
            <CustomDialogActions
              title="Payment Sources"
              description="No payment sources have been created"
              titleAccept="Go to create"
              titleCancel="Cancel"
              handleAction={() => {
                history.push(`/office/${officeId}/billing`);
              }}
              handleClose={() => {
                setDialogPayment(false);
              }}
              loading={false}
            />
          </Modal>
        )}
      </Grid>
    </Grid>
  )
}

export default PlansDetailsCoupon;

// Load libraries

import React  from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';

// Context

import { useAuth } from './contexts/v2/auth';
import { useApp } from './contexts/app';

// Components

import Spinner from './components/Spinner';

// Pages

import Dashboard from './components/pages/Dashboard';
import Office from './components/pages/OfficeDetail';
import Login from './components/pages/Login';
import ForgotPassword from './components/pages/ForgotPassword';
import Register from './components/pages/Register';
import TermsOfService from './components/pages/TermsOfService';
import MarketingLink from './components/pages/MarketingLink';
import CouponsUserAdmin from './components/pages/CouponsUserAdmin';

/**
 * Component that defines the main routes.
 *
 * @returns {Function} - react component.
 */
function Routes() {
  const auth = useAuth();
  const app = useApp();

  // Wait until you have information from the logged in user - while a blank page
  // will be displayed with a spinner in the middle of the page.
  if (auth.initializing) {
    return (
      <div className="app-loader-container">
        <div className="app-spinner">
          <Spinner size="6rem" color="rgb(181, 227, 70)" isLoading />
        </div>
      </div>
    );
  }

  return (
    <Router>
      <Switch>
        <PrivateRoute exact path="/">
          <Redirect to="/dashboard" />
        </PrivateRoute>
        <Route exact path="/login" component={Login} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/terms-of-service" component={TermsOfService} />
        <Route path="/signup" component={Register} />

        {/* Marketing Extend */}
        <PrivateRoute path={app.extendPlanLink}>
          <MarketingLink />
        </PrivateRoute>
        {/* Marketing Coupon */}
        <PrivateRoute path={app.couponLink}>
          <MarketingLink />
        </PrivateRoute>

        <PrivateRoute path="/dashboard">
          <Dashboard />
        </PrivateRoute>
        <PrivateRoute path="/office/:id">
          <Office />
        </PrivateRoute>
        {/* Admin */}
        <PrivateRoute exact path="/coupons">
          <CouponsUserAdmin/>
        </PrivateRoute>
        <Route exact path="*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </Router>
  );
}

/**
 * Route that will verify if the user is authenticated to render the
 * children component, otherwise it will be redirected to the login route.
 *
 * @param {Object} params - component params
 * @param {React.Component} children - children react component
 * @returns {Route} - new route component
 */
function PrivateRoute({ children, ...rest }) {
  const auth = useAuth();

  return (
    <Route
      {...rest}
      render={({ location }) =>
        auth.user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

export default Routes;

import React, { useContext, useState, useEffect, useRef } from 'react';
import axios from 'axios';
import moment from 'moment';
import Input from 'react-phone-number-input/input';
import Autocomplete, { usePlacesWidget } from 'react-google-autocomplete';

import {
  List,
  ListItem,
  Grid,
  CardContent,
  Card,
  Dialog,
  CardActions,
  ListItemIcon,
  CircularProgress,
  Divider,
  makeStyles
} from '@material-ui/core';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import insyncHttps from '../../../insyncHttps/insyncHttps';

import CustomButton from '../../CustomButton';
import CustomTextField from '../../CustomTextField';
import CustomSelect from '../../CustomSelect';
import ModalDownload from './ModalDownload';
import { isEmpty } from 'ramda';

import * as OfficeV2 from '../../../functions/v2/office';
import { useHistory } from 'react-router-dom';

// Context

import { DashboardContext } from './DashboardContext';
import { useApp } from '../../../contexts/app';
import { useAuth } from '../../../contexts/v2/auth';

const useStyles = makeStyles({
  title: {
    fontSize: 22,
    fontWeight: 'bold',
    margin: 0,
    wordBreak: 'break-all'
  },
  subTitle: {
    fontSize: 16,
    fontWeight: 'normal',
    margin: 0,
    color: '#979797'
  },
  textField: {
    fontSize: 16,
    marginBottom: 6,
    marginTop: 15,
    textAlign: 'left'
  },
  cardContainer: {
    margin: 'auto',
    fontFamily: 'SF Pro Display',
    paddingBottom: 38,
    paddingTop: 28,
    paddingLeft: 20,
    paddingRight: 20,
    minWidth: 450,
    maxWidth: 500,
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: 4,
      height: 0
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1'
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#DFE2E2',
      borderRadius: 4
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#888',
      borderRadius: 4
    },
    '& .MuiCardContent-root': {
      padding: 0
    }
  },
  selected: {
    border: '1px solid #ECEEF2',
    height: 48,
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: 12
  },
  customPopover: {
    '& .MuiPopover-paper': {
      '&::-webkit-scrollbar': {
        width: 6,
        height: 0
      },
      '&::-webkit-scrollbar-track': {
        background: '#f1f1f1'
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#c3c3c3',
        borderRadius: 2
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#888',
        borderRadius: 2
      }
    }
  },
  boxInputPhone: {
    fontFamily: 'SF Pro Display',
    color: 'currentColor',
    border: '1px solid #ECEEF2',
    fontSize: 16,
    opacity: '0.5',
    boxShadow: 'inset 2px 4px 6px rgba(201, 203, 217, 0.15)',
    backgroundColor: 'white',
    borderRadius: 4,
    padding: 16,
    width: '93%',
    '&:focus-visible': {
      outline: 'none'
    }
  },
  customAutocomplete: {
    fontFamily: 'SF Pro Display',
    color: 'currentColor',
    border: '1px solid #d7d7d7',
    fontSize: 16,
    opacity: '0.5',
    boxShadow: 'inset 2px 4px 6px rgba(201, 203, 217, 0.15)',
    backgroundColor: 'white',
    borderRadius: 4,
    padding: 16,
    width: '93%',

    '&:focus-visible': {
      outline: 'none'
    }
  }
});

export default function ({ gotoExtendPlan, couponId }) {
  const { officesMeta, getOfficeList, loading, newPlans } =
    useContext(DashboardContext);
  const [createOfficeDialogOpen, setCreateOfficeDialogOpen] = useState(false);
  const [loadingSync, setLoadingSync] = useState(false);
  const history = useHistory();
  const app = useApp();

  const customScriptUser = (officeId) => {
    setLoadingSync(true);
    insyncHttps.office
      .syncUserOffice(officeId)
      .then((result) => {
        // console.log('result', result.data.syncUp)
        customScriptUserAttributes(officeId);
      })
      .catch((err) => {
        setLoadingSync(false);
      });
  };

  const customScriptUserAttributes = (officeId) => {
    setLoadingSync(true);
    insyncHttps.office
      .syncUserAttributes(officeId)
      .then((result) => { })
      .catch((err) => {
        setLoadingSync(false);
      });
  };

  return (
    <Card>
      <CardContent>
        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress size={30} style={{ color: '#9DCD2A' }} />
          </div>
        ) : (
          <List>
            {Object.keys(officesMeta).map((officeId) => {
              return (
                <ListItem
                  button
                  divider
                  key={officeId}
                  onClick={() => {
                    if (gotoExtendPlan) {
                      history.push(
                        app.extendPlanToProccess.replace(':officeId', officeId)
                      );
                    } else if (couponId) {
                      console.log('couponId', couponId);
                      history.push(
                        app.couponLinkToProccess
                          .replace(':officeId', officeId)
                          .replace(':couponId', couponId)
                      );
                    } else {
                      history.push(`/office/${officeId}`, {
                        officeId: officeId,
                        officeName: officesMeta[officeId].name
                      });
                    }
                  }}
                >
                  <ListItemIcon style={{ minWidth: 30 }}>
                    <KeyboardArrowRightRoundedIcon
                      fontSize="small"
                      style={{ color: '#9DCD2A' }}
                    />
                  </ListItemIcon>
                  <p
                    style={{
                      fontSize: 18,
                      margin: 10,
                      fontFamily: 'SF Pro Display'
                    }}
                  >
                    {officesMeta[officeId].name}
                  </p>
                </ListItem>
              );
            })}
          </List>
        )}
      </CardContent>
      <CardContent>
        <CustomButton
          label="create office"
          width="full"
          onClick={() => {
            setCreateOfficeDialogOpen(true);
          }}
        />
      </CardContent>
      {createOfficeDialogOpen && (
        <Dialog open={createOfficeDialogOpen}>
          <Grid container justifyContent="center" align="center">
            <Grid item xs={12}>
              <NewOffice
                getOfficeList={getOfficeList}
                officesMeta={officesMeta}
                handleClose={() => {
                  setCreateOfficeDialogOpen(false);
                }}
              />
            </Grid>
          </Grid>
        </Dialog>
      )}
    </Card>
  );
}

function NewOffice(props) {
  const history = useHistory();
  const classes = useStyles();
  const auth = useAuth();
  const { user, reload } = auth;
  const inputRef = useRef(null);
  const [addressGoogle, setAddressGoogle] = useState('');
  const [formGroup, setFormGroup] = useState({
    officeName: '',
    phoneNumber: '',

    employees: '',
    rooms: ''
  });

  const [errorField, setErrorField] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dataAccess, setDataAccess] = useState({});
  const [stateDownloadTwo, setStateDownloadTwo] = useState(false);

  const getOfficeList = props.getOfficeList;
  const handleClose = props.handleClose;
  const officesMeta = props.officesMeta;

  const employeesOptions = [
    {
      label: '1-3',
      value: 20
    },
    {
      label: '4-7',
      value: 21
    },
    {
      label: '8-12',
      value: 22
    },
    {
      label: '13-20',
      value: 23
    },
    {
      label: '21-30',
      value: 24
    },
    {
      label: '31-50',
      value: 25
    },
    {
      label: '51+',
      value: 26
    }
  ];

  const roomsOptions = [
    {
      label: '1-5',
      value: 27
    },
    {
      label: '6-10',
      value: 28
    },
    {
      label: '11-20',
      value: 29
    },
    {
      label: '20-30',
      value: 30
    },
    {
      label: '31+',
      value: 31
    }
  ];

  const changeEmployees = (e) => {
    setFormGroup({ ...formGroup, employees: e.target.value });
  };

  const changeRooms = (e) => {
    setFormGroup({ ...formGroup, rooms: e.target.value });
  };

  const subminNewOffice = async () => {
    setLoading(true);
    if (
      formGroup.officeName === '' ||
      formGroup.phoneNumber === '' ||
      addressGoogle === '' ||
      formGroup.employees === '' ||
      formGroup.rooms === ''
    ) {
      setLoading(false);
      setErrorField(true);
    } else {
      setLoading(true);

      const valor =
        Object.keys(officesMeta).length > 0
          ? Object.keys(officesMeta).find((t) => officesMeta[t])
          : '';

      try {
        const manyEmployees = employeesOptions.find(
          (t) => t.value === formGroup.employees
        );
        const manyRooms = roomsOptions.find((t) => t.value === formGroup.rooms);
        const createOfficeResponse = await OfficeV2.createOffice({
          name: formGroup.officeName,
          phoneNumber: formGroup.phoneNumber,
          firstName: '',
          lastName: '',
          firstOffice: !isEmpty(valor) ? valor : ''
        });
        const response = createOfficeResponse.data;

        setDataAccess({
          accessCode: response.accessCode,
          officeId: response.office.id
        });

        const trial = moment(new Date()).add(14, 'days').format('YYYY/MM/DD');
        // ---------------------------------------------------
        // ---------------------------------------------------
        /**
         * @author Randall Medina
         * @description execute the function to create the test plan script
         */
        const resultLoadOffice = await insyncHttps.office.loadOfficeData({
          email: user.email,
          officeName: formGroup.officeName,
          // name: formGroup.name,
          // lastName: formGroup.lastName,
          phoneNumber: formGroup.phoneNumber,
          address: addressGoogle,
          trialEnds: trial,
          trialDays: 14,

          employees: manyEmployees.label,
          rooms: manyRooms.label,

          officeId: response.office.id,
          firstOffice: !isEmpty(valor) ? valor : ''
        });
        // ----------------------------------------------------------------
        const keys = process.env.REACT_APP_PIPEDRIVE_KEY;
        const header = {
          headers: {
            'Content-Type': 'application/json;charset=UTF-8',
            'Cache-Control': 'no-cache',
            Accept: 'application/json'
            // Authorization: `Basic ${token}`,
            // 'X-API-Key': `${keys}`,
          }
        };
        // ----------------------------------------------------------------
        /**
         * @author Randall Medina
         * @description execute the function to create organization Pipedrive
         */
        const dataCompany = {
          name: formGroup.officeName,
          address: addressGoogle
        };
        const resultCompany = await axios.post(
          `https://api.pipedrive.com/v1/organizations?api_token=${keys}`,
          dataCompany,
          header
        );
        // ----------------------------------------------------------------
        /**
         * @author Randall Medina
         * @description execute the function to create person Pipedrive
         */
        const dataPerson = {
          name: `${resultLoadOffice.data.nameUser} ${resultLoadOffice.data.lastnameUser}`, // for the second time and get it from the API
          first_name: resultLoadOffice.data.nameUser, // for the second time and get it from the API
          last_name: resultLoadOffice.data.lastnameUser, // for the second time and get it from the API
          phone: formGroup.phoneNumber,
          email: user.email,
          org_id: resultCompany.data.data.id // organization
        };
        const resultPerson = await axios.post(
          `https://api.pipedrive.com/v1/persons?api_token=${keys}`,
          dataPerson,
          header
        );
        // ----------------------------------------------------------------
        /**
         * @author Randall Medina
         * @description execute the function to create deal Pipedrive
         */
        const dataDeal = {
          title: formGroup.officeName,
          value: '0',
          person_id: resultPerson.data.data.id,
          org_id: resultCompany.data.data.id, // pipedrive_office_id
          status: 'open',
          '60108ed00f29ad9becabca264cb7ed29b29a49fb': response.office.id, // insync_account_id
          e5dc866fcebc7338d22c35318913d5419402c21a: 17, // subscription_plan  -- Trial in Pipedrive --
          '0fb823a20107667a44b7d38fc5f456617cf13a23': formGroup.employees, // number_of_users
          '78b810a01d621031d7fd680479bc21c5418d44c6': moment(
            new Date(resultLoadOffice.data.accountCreated)
          ).format('YYYY/MM/DD'), // YYYY-MM-DD. account_created
          efa2633ea344f9cf6789361dde4174d534d09515: moment(new Date()).format(
            'YYYY/MM/DD'
          ), // YYYY-MM-DD. subscription_started
          '003ed7769aa12031d88c37a107be39647cc2d268': trial, // YYYY-MM-DD. trial_ends
          cb154500c92bc3ecebaf13cc26566b0f41453419: formGroup.rooms // number_of_rooms
        };
        await axios.post(
          `https://api.pipedrive.com/v1/deals?api_token=${keys}`,
          dataDeal,
          header
        );
        // ----------------------------------------------------------------
        setLoading(false);
        setErrorField(false);
        // handleClose();
        setStateDownloadTwo(true);
        // getOfficeList();
        await reload();
      } catch (error) {
        console.log('error creating office: ', error);
        setLoading(false);
      }
    }
  };

  return (
    <Card style={{ margin: 'auto', padding: 30, minWidth: 400 }}>
      <CardContent>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center'
          }}
        >
          <p style={{ fontSize: 24, fontWeight: 'bold', marginBottom: 20 }}>
            Create Office
          </p>
          <p
            style={{
              fontSize: 16,
              marginBottom: 20,
              color: '#979797',
              cursor: 'pointer'
            }}
            onClick={handleClose}
          >
            Cancel
          </p>
        </div>
        <Divider style={{ backgroundColor: '#EDEEF0' }} />
        <div style={{ marginBottom: 20 }}>
          <p style={{ fontSize: 16, marginBottom: 10, textAlign: 'left' }}>
            Office name
          </p>
          <CustomTextField
            value={formGroup.officeName}
            placeholder="Add a name for your office"
            onChange={(e) => {
              setFormGroup({
                ...formGroup,
                officeName: e.target.value
              });
            }}
            error={formGroup.officeName === '' ? errorField : false}
          />
          <p className={classes.textField}>Phone</p>
          <Input
            country="US"
            placeholder="(000) 000-0000"
            value={formGroup.phoneNumber}
            onChange={(e) => {
              setFormGroup({ ...formGroup, phoneNumber: e });
            }}
            maxLength={14}
            className={classes.boxInputPhone}
            style={
              formGroup.phoneNumber === '' && errorField
                ? {
                  borderWidth: '1px',
                  border: '1px solid #EC5829',
                  background: '#FDEBE3',
                  color: '#EC5829'
                }
                : {}
            }
          />
          <p className={classes.textField}>Address</p>
          <Autocomplete
            ref={inputRef}
            apiKey={process.env.REACT_APP_AUTOCOMPLETE_GOOGLE}
            placeholder={'Address'}
            className={classes.customAutocomplete}
            style={
              addressGoogle === '' && errorField
                ? {
                  borderWidth: '1px',
                  border: '1px solid #EC5829',
                  background: '#FDEBE3',
                  color: '#EC5829'
                }
                : {}
            }
            onChange={(e) =>
              e.target.value === '' ? setAddressGoogle('') : e.target.value
            }
            onPlaceSelected={(place, a, c) => {
              setAddressGoogle(place.formatted_address);
            }}
            options={{
              types: ['geocode', 'establishment'],
              // types: ["(regions)"],
              // types: ["address"],
              componentRestrictions: { country: ['us', 'ca'] }
            }}
          />
          <p style={{ fontSize: 16, marginBottom: 10, textAlign: 'left' }}>
            How many employees are in your office?
          </p>
          <CustomSelect
            value={formGroup.employees}
            name="employees"
            options={employeesOptions}
            onChange={changeEmployees}
            noOptionsMessage={() => '- No results found -'}
            error={formGroup.employees === '' ? errorField : false}
          />
          <p style={{ fontSize: 16, marginBottom: 10, textAlign: 'left' }}>
            How many rooms / areas are in your offices?
          </p>
          <CustomSelect
            value={formGroup.rooms}
            name="rooms"
            options={roomsOptions}
            onChange={changeRooms}
            noOptionsMessage={() => '- No results found -'}
            error={formGroup.rooms === '' ? errorField : false}
          />
        </div>
        <Divider style={{ backgroundColor: '#EDEEF0' }} />
      </CardContent>
      <CardActions style={{ marginTop: 20 }}>
        {loading ? (
          <div
            style={{ display: 'flex', justifyContent: 'center', width: '100%' }}
          >
            <CircularProgress size={30} style={{ color: '#9DCD2A' }} />
          </div>
        ) : (
          <CustomButton
            label="Create Office"
            onClick={subminNewOffice}
            width="full"
          />
        )}
      </CardActions>
      {/* --------------------------------- */}
      {stateDownloadTwo && (
        <Dialog
          open={stateDownloadTwo}
        // onClose={() => {
        //     setStateDownloadTwo(false);
        // }}
        >
          <ModalDownload
            data={{}}
            showDownload={true}
            code={dataAccess.accessCode}
            officeId={dataAccess.officeId}
            download={process.env.REACT_APP_SETUP_URL}
            handleClose={async (e) => {
              await reload();
              setStateDownloadTwo(false);
              handleClose();
              if (e === 'close') {
                history.push(`/office/${dataAccess.officeId}`);
              } else {
                history.push(`/office/${dataAccess.officeId}`, {
                  showVideo: true
                });
              }
            }}
          />
        </Dialog>
      )}
      {/* --------------------------------- */}
    </Card>
  );
}

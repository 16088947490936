// Load libraries

import React, { useState, useEffect, useContext, createContext } from 'react';

// Setup firebase app

import firebase from '../../firebase';

// Context

const authContext = createContext();

/**
 * Provider component that wraps your app and makes auth object
 * available to any child component that calls useAuth().
 *
 * @param {Object} props
 * @param {React.Component} props.children
 * @returns {React.Component}
 */
export function ProvideAuth({ children }) {
  const auth = useProvideAuth();
  return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

/**
 * @name SigninFunction
 * @function
 * @param {String} email - user email
 * @param {String} password - user password
 */

/**
 * @name SignupFunction
 * @function
 * @param {String} email - user email
 * @param {String} password - user password
 */

/**
 * @name SignoutFunction
 * @function
 */

/**
 * @name SigninWithGoogleFunction
 * @function
 */

/**
 * @name ReloadFunction
 * @function
 */

/**
 * @typedef {Object} AuthContext
 * @property {Object} user - firebase user information
 * @property {boolean} initializing
 * @property {boolean} loading
 * @property {Object} error
 * @property {SigninFunction} signin
 * @property {SignupFunction} signup
 * @property {SigninWithGoogleFunction} signinWithGoogle
 * @property {SignoutFunction} signout
 * @property {boolean} isSuperAdmin
 * @property {ReloadFunction} reload
 */

/**
 * Hook for child components to get the auth object and
 * re-render when it changes.
 *
 * @returns {AuthContext}
 */
export const useAuth = () => {
  return useContext(authContext);
};

/**
 * Provider hook that creates auth object and handles state.
 *
 * @returns {React.Component}
 */
function useProvideAuth() {
  const [user, setUser] = useState(null);
  const [initializing, setInitializing] = useState(true);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [claims, setClaims] = useState(null);
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  const onAuthCompleted = async (user) => {
    verifyAdmin(user);
    setLoading(false);
    setUser(user);
    setError(null);
    setInitializing(false);
  };

  /**
   *  verifies if you are an administrator user or not
   *
   * @name verifyAdmin
   * @function
   * @param {JSON} user - user exist
   */

  const verifyAdmin = async (user) => {
    if (user) {
      const tokenResult = await firebase
        .auth()
        .currentUser.getIdTokenResult(true);
      setClaims(tokenResult.claims);
      if (
        tokenResult.claims &&
        tokenResult.claims.globalRoles &&
        tokenResult.claims.globalRoles.includes('superAdmin')
      ) {
        setIsSuperAdmin(true);
      } else {
        setIsSuperAdmin(false);
      }
    }
  };

  const onError = (err) => {
    setLoading(false);
    setUser(null);
    setError(err);
  };

  /**
   * Firebase Login method
   *
   * @param {string} email
   * @param {string} password
   * @returns {Promise.<firebase.auth.UserCredential>} firebase user info
   */
  const signin = (email, password) => {
    setLoading(true);

    return firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(({ user }) => {
        return user;
      })
      .catch(onError);
  };

  /**
   * Firebase Logout method
   *
   * @returns {Promise.<void>}
   */
  const signout = () => {
    setLoading(true);

    return firebase
      .auth()
      .signOut()
      .then(() => {
        onAuthCompleted(null);
      })
      .catch(onError);
  };

  /**
   * Firebase signup method
   *
   * @param {string} email
   * @param {string} password
   * @returns {Promise.<firebase.auth.UserCredential>} firebase user info
   */
  const signup = (email, password) => {
    setLoading(true);

    return firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then((user) => {
        return user;
      })
      .catch(onError);
  };

  /**
   * Firebase Login method with Google Popup.
   *
   * @returns {Promise.<firebase.auth.UserCredential>} firebase use info
   */
  const signinWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();

    provider.setCustomParameters({ prompt: 'select_account' });

    return firebase
      .auth()
      .signInWithPopup(provider)
      .then((user) => user)
      .catch(onError);
  };

  /**
   * Firebase Logout method
   *
   * @returns {Promise.<firebase.auth.IdTokenResult>}
   */
  function reload() {
    if (!user) {
      console.warn('reload auth with out user.');
      return;
    }

    return user.getIdTokenResult(true);
  }

  // Subscribe to user on mount
  // Because this sets state in the callback it will cause any ...
  // ... component that utilizes this hook to re-render with the ...
  // ... latest auth object.
  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      onAuthCompleted(user);
    });
    const unsubscribe_Token = firebase.auth().onIdTokenChanged((user) => {
      onAuthCompleted(user);
    });
    // Cleanup subscription on unmount
    return () => {
      unsubscribe();
      unsubscribe_Token();
    };
  }, []);

  return {
    user,
    initializing,
    loading,
    error,
    signin,
    signup,
    signinWithGoogle,
    signout,
    isSuperAdmin,
    claims,
    reload
  };
}

import React, { useState, useContext, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import { CircularProgress, Grid, makeStyles } from '@material-ui/core';
import { green } from '@material-ui/core/colors';

// Context

import { appContext, useApp } from '../../../contexts/app';
import { useAuth } from '../../../contexts/v2/auth';

import CustomTextField from '../../CustomTextField';
import CustomButton from '../../CustomButton';
import CustomNavBar from '../../CustomNavBarLogin';

const useStyles = makeStyles((theme) => ({
  typography: {
    textAlign: "left",
    fontSize: 17,
    marginBottom: 10,
    fontWeight: 300,
    color: '#656565'
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  container: {
      maxWidth: 1500,
      paddingLeft: 15,
      paddingRight: 15,
      margin: 'auto'
  }
}));

function Login() {
  const classes = useStyles();
  const { error } = useContext(appContext);
  const app = useApp();
  const auth = useAuth();
  const [errorField, setErrorField] = useState(false);
  const [emailValidate, setEmailValidate] = useState('');
  const [form, setForm] = useState({
    email: '',
    password: ''
  });
  const location = useLocation();
  const history = useHistory();
  const { from } = location.state || { from: { pathname: '/' } };

  useEffect(() => {
    // Capture errors resulting from the authentication process and
    // send them as an application message
    // TODO: this has to be improved.
    const err = auth.error;

    if (!err) {
      return;
    }

    if (err.code) {
      error.setMessage(err.code);
    } else if (err.email) {
      error.setMessage(err.email);
    } else if (err.credential) {
      error.setMessage(err.credential);
    } else {
      error.setMessage(err.message);
    }
  }, [auth.error]);

  const gotoNextPage = () => {
    if (from.pathname === app.marketingExtendPlanLink) {
      history.replace('dashboard', {
        from: from.pathname
      });
    } else {
      history.replace(from);
    }
  };

  const onChangeEmail = (event) => {
    const caracteres =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const t = event.currentTarget.value.split(' ');
    let text = '';
    t.map((r) => {
      if (r !== '') {
        text += r;
      }
    });
    setForm({ ...form, email: text.toLowerCase() });
    if (text !== '') {
      if (caracteres.test(text)) {
        setEmailValidate(false);
      } else {
        setEmailValidate(true);
      }
    } else {
      setEmailValidate(true);
    }
  };

  const submitForm = async () => {
    if (
      form.email === '' ||
      form.password === '' ||
      (form.password.length >= 1 && form.password.length < 6)
    ) {
      setErrorField(true);
    } else {
      setErrorField(false);
      const authResponse = await auth.signin(form.email, form.password);

      if (authResponse) {
        gotoNextPage();
      }
    }
  };

  const signInWithGoogle = async () => {
    const authResponse = await auth.signinWithGoogle();

    if (authResponse) {
      gotoNextPage();
    }
  };

  return (
    <div className={classes.container}>
      <CustomNavBar />
      <Grid container style={{ marginTop: 180 }} justifyContent="center">
        <Grid
          item
          xs={11}
          sm={7}
          md={5}
          lg={4}
          style={{ marginTop: 15, fontFamily: 'SF Pro Display' }}
        >
          <p style={{ fontSize: 24, fontWeight: 'bold', marginBottom: 40 }}>
            Login
          </p>
          <p className={classes.typography}> Email </p>
          <CustomTextField
            value={form.email}
            placeholder="your@email.com"
            error={form.email !== '' ? emailValidate : errorField}
            onChange={onChangeEmail}
            helperText={
              form.email && emailValidate ? 'Please enter a valid email' : ''
            }
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-end'
            }}
          >
            <p className={classes.typography}> Password </p>
            <Link
              to="/forgot-password"
              className={classes.typography}
              style={{
                color: '#EC5829',
                textDecoration: 'none',
                fontWeight: 400
              }}
            >
              Forgot password?
            </Link>
          </div>
          <CustomTextField
            value={form.password}
            placeholder="*******"
            type="password"
            error={
              errorField ||
              (form.password.length >= 1 && form.password.length < 6)
                ? true
                : false
            }
            helperText={
              form.password.length >= 1 && form.password.length < 6
                ? 'Min 6 caracters'
                : ''
            }
            onChange={(e) => {
              setForm({ ...form, password: e.target.value });
            }}
          />
          <div style={{ marginTop: 30 }}>
            <div>
              <CustomButton label="login" width="full" onClick={submitForm} />
              {auth.loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          </div>
          <div style={{ marginTop: 30 }}>
            <CustomButton
              className={classes.btnGoogle}
              label="Sign in with google"
              width="full"
              onClick={signInWithGoogle}
              startIcon={<img width="35px" src="/google-logo.png" />}
              backgroundred={'#0000'}
              style={{
                color: '#969696',
                boxShadow:
                  '0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)'
              }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-end',
              justifyContent: 'center'
            }}
          >
            <p
              className={classes.typography}
              style={{ color: '#656565', marginRight: 6, }}
            >
              Not a member?
            </p>
            <Link
              to="/signup"
              className={classes.typography}
              style={{ color: '#000000', textDecoration: 'none', fontWeight: 600 }}
            >
              Sign up now
            </Link>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default Login;

import { createContext, useState } from "react";
import insyncHttps from "../../../../insyncHttps/insyncHttps";

export const DevicesContext = createContext({
    deviceList: { devices: [], loading: false, getDevices: () => { } },
});

export const useDevices = (officeId) => {
    const [devices, setDevices] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const getDevices = () => {
        setError(null);
        setLoading(true);
        insyncHttps.insyncUser
            .getAllDevices({ officeId })
            .then(result => {
                setLoading(false);
                if (result && Object.keys(result.data).length > 0) {
                    const activeDevices = Object.keys(result.data).filter(t => result.data[t].session).map( e => result.data[e]);
                    setDevices(activeDevices);
                } else {
                    setDevices([]);
                }
            })
            .catch(err => {
                setLoading(false);
                setError(err.message);
                setDevices([]);
            });
    };

    return { devices, loading, getDevices, error };
};
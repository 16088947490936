import React, { useContext, useState, useEffect } from 'react';
import { update, isEmpty } from 'ramda';
import moment from 'moment';
import {
    makeStyles,
    CircularProgress,
    IconButton,
    Modal
} from '@material-ui/core';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import CreateRounded from '@material-ui/icons/CreateRounded';
import DeleteRounded from '@material-ui/icons/DeleteRounded';

import { PatientTrackingContext } from "../PatientTrackingContext";
import { appContext } from '../../../../../contexts/app';
import { OfficeContext } from "../../OfficeContext";
import insyncHttps from "../../../../../insyncHttps/insyncHttps";
import { ingestEncharge } from '../../../../../utils/encharge';
import { useDashboard } from "../../../Dashboard/DashboardContext";
import { PlanContext } from "../../PlanContext";

import CustomButton from '../../../../CustomButton';
import CustomCheckBox from '../../../../CustomCheckBox';
import CustomTextField from '../../../../CustomTextField';
import CustomBannerPlan from '../../../../CustomBannerPlan';
import AddRoom from './AddRoom';
import { IcArrowUp, IcArrowDown } from '../../../../IconSVG';
import { useAuth } from '../../../../../contexts/v2/auth';

const useStyles = makeStyles({
    infoContainer: {
        background: 'white',
        borderRadius: 4,
        marginTop: 21,
        // width: '100%',
        // maxWidth: 480,
        display: 'flex',
        flexDirection: 'column',
        fontFamily: 'SF Pro Display',
        border: '1px solid #EDEEF0',
        padding: 30,
        // marginRight: 10
    },
    title: {
        fontSize: 22,
        fontWeight: 'bold',
        margin: 0,
        wordBreak: 'break-all',
    },
    cardHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 15
    },
    listRooms: {
        borderTop: '1px solid #EDEEF0',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: 56,
    },
    subTitle: {
        fontSize: 16,
        fontWeight: 'normal',
        margin: 0,
        color: '#979797',
        marginLeft: 10,
        marginRight: 10,
    },
    textHead: {
        color: '#979797',
        fontSize: 14,
        width: 105,
        display: 'flex',
        justifyContent: 'center'
    }
});

function Rooms() {
    const classes = useStyles();
    const { user } = useAuth();
    const { officeId } = useContext(OfficeContext);
    const { error } = useContext(appContext);
    const { rooms, loading, getPatientTracking, defaults } = useContext(PatientTrackingContext);
    const { officesMeta, getOfficeList } = useDashboard();
    // ------------------------------------------
    const { getPlanById, planItem, loadingPlan } = useContext(PlanContext);
    const currentDate = moment(new Date()).format('YYYY/MM/DD');
    // ------------------------------------------

    const [addRoomsOpen, setAddRoomsOpen] = useState(false);
    const [deleteRoom, setDeleteRoom] = useState(false);
    const [editRoom, setEditRoom] = useState(false);
    const [nameRoom, setNameRoom] = useState('');
    const [errorField, setErrorField] = useState(false);
    const [orderedRoomIds, setOrderedRoomIds] = useState([]);
    const [orderModified, setOrderModified] = useState(false);
    const [saveOrderLoading, setSaveOrderLoading] = useState(false);
    const [loadingP, setLoadingP] = useState(false);
    const [dataRoomsNew, setDataRoomsNew] = useState([]);

    function moveRoomUp(roomId) {
        const currentIndex = orderedRoomIds.indexOf(roomId);
        if (currentIndex > 0) {
            orderedRoomIds.splice(currentIndex, 1);
            orderedRoomIds.splice(currentIndex - 1, 0, roomId);
            setOrderModified(true);
            setOrderedRoomIds([...orderedRoomIds]);
        }
    }

    function moveRoomDown(roomId) {
        const currentIndex = orderedRoomIds.indexOf(roomId);
        if (currentIndex < orderedRoomIds.length - 1) {
            orderedRoomIds.splice(currentIndex, 1);
            orderedRoomIds.splice(currentIndex + 1, 0, roomId);
            setOrderedRoomIds([...orderedRoomIds]);
            setOrderModified(true);
        }
    }

    useEffect(() => {
        getOfficeList()
        getPlanById();
    }, []);

    useEffect(() => {
        setOrderModified(false);
        if (rooms) {
            setOrderedRoomIds(getSortedRoomIds(rooms));
        }
    }, [rooms]);

    useEffect(() => {

        if (orderedRoomIds && orderedRoomIds.length > 0) {
            const resul = orderedRoomIds.map(t => ({
                ...rooms[t],
                paging: (rooms[t] && rooms[t].hasOwnProperty('type') && rooms[t].type === 'ambos') || rooms[t].type === 'paging' ? true : false,
                tracking: (rooms[t] && rooms[t].hasOwnProperty('type') && rooms[t].type === 'ambos') || rooms[t].type === 'tracking' ? true : false

            }))
            setDataRoomsNew(resul)
        } else {
            setDataRoomsNew([])
        }
    }, [orderedRoomIds])

    const changeOrder = () => {
        setSaveOrderLoading(true);
        insyncHttps.patientTracking
            .reorderRooms({
                officeId,
                roomIds: orderedRoomIds
            })
            .then(result => {
                setSaveOrderLoading(false);
                getPatientTracking();
            })
            .catch(err => {
                setSaveOrderLoading(false);
                getPatientTracking();
            });
    }

    const subminNewNameRoom = (item) => () => {
        setLoadingP(true);
        const roomId = item.roomId
        if (nameRoom === '' || nameRoom === undefined) {
            setErrorField(true)
            setLoadingP(false)
        } else {
            insyncHttps.patientTracking
                .updateRoom({ officeId, roomId, name: nameRoom, type: item.type })
                .then(async result => {
                    // ---------------------------------------------------
                    const payloadRegister = {
                        "name": "Modified room",
                        "user": {
                            "email": user.email,
                            // "userId": officeId,
                            "tags": "renamed_room"
                        }
                    }
                    const [first] = Object.keys(officesMeta).map(id => id);
                    if (first === officeId) {
                        await ingestEncharge(payloadRegister)
                    }
                    // ---------------------------------------------------
                    setLoadingP(false);
                    setEditRoom(false);
                    setNameRoom('')
                    getPatientTracking();
                })
                .catch(err => {
                    console.error(err);
                    setLoadingP(false);
                    error.setMessage(err.message);
                });
        }
    }

    const subminDeleteRoom = (item) => () => {
        const roomId = item.roomId
        setLoadingP(true);
        insyncHttps.patientTracking
            .removeRoomFromOffice({ officeId, roomId })
            .then(result => {
                if (result && result.data && result.data.status && result.data.status === 201) {
                    setLoadingP(false);
                    setDeleteRoom(null);
                    error.setMessage(result.data.message);
                } else {
                    setLoadingP(false);
                    getPatientTracking();
                    getPlanById();
                }
            })
            .catch(err => {
                setLoadingP(false);
                error.setMessage(err.message);
            });

    }

    const changeDefault = (roomId) => () => {
        setLoadingP(true);
        insyncHttps.patientTracking
            .setDefaultRoom({
                officeId,
                roomId
            })
            .then(result => {
                setLoadingP(false);
                getPatientTracking();
            })
            .catch(err => {
                setLoadingP(false);
                error.setMessage(err.message);
            });
    }

    const handleChange = (prop, item) => (event) => {
        const indexArray = dataRoomsNew.findIndex((t) => t.id === item.id);
        let newDataArray = { ...item, [prop]: event.target.checked };
        const newAdd = update(indexArray, newDataArray, dataRoomsNew)
        setDataRoomsNew(newAdd)
        const resultType = (prop === 'paging' && event.target.checked && !item.tracking) || (prop === 'tracking' && !event.target.checked && item.paging) ? 'paging' :
            (prop === 'tracking' && event.target.checked && !item.paging) || (prop === 'paging' && !event.target.checked && item.tracking) ? 'tracking' :
                (prop === 'tracking' && event.target.checked && item.paging) || (prop === 'paging' && event.target.checked && item.tracking) ? 'ambos' :
                    ''
        insyncHttps.patientTracking
            .updateRoom({
                ...item,
                officeId,
                roomId: item.id,
                name: nameRoom !== '' ? nameRoom : item.name,
                type: resultType
            })
            .then(result => {
                getPatientTracking();
            })
            .catch(err => { error.setMessage(err.message) });
    };

    return (
        <div>
            {loadingPlan ?
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress size={30} style={{ color: '#9DCD2A' }} />
                </div>
                :
                <div>
                    {!isEmpty(planItem) && (
                        <div>
                            {planItem.showBannerRooms && (
                                <CustomBannerPlan
                                    boxBackground={'#F86F46'}
                                    boxText={'You have reached the maximum amount of rooms in your plan. To add more click "upgrade plan"'}
                                    daysOff={false} // Boolean
                                    textDaysOff={''}
                                    colorDaysOff={''}
                                    btnText={'UPGRADE PLAN'}
                                    btnOnClick={`/office/${officeId}/plans`}
                                    btnTextColor={'#F86F46'}
                                />
                            )}
                            {planItem.showBannerTrial && (
                                <CustomBannerPlan
                                    boxBackground={'#197FE8'}
                                    boxText={`${planItem.planName}`}
                                    daysOff={true} // Boolean
                                    textDaysOff={`${moment(planItem.trialEnds).diff(currentDate, 'days')} days left`}
                                    colorDaysOff={'#358FEB'}
                                    btnText={'CHOOSE A PLAN'}
                                    btnOnClick={`/office/${officeId}/plans`}
                                    btnTextColor={'#197FE8'}
                                />
                            )}
                        </div>
                    )}
                </div>
            }
            <div className={classes.infoContainer}>
                <div className={classes.cardHeader}>
                    <p className={classes.title}> Rooms </p>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <div className={classes.textHead}> Paging </div>
                            <div className={classes.textHead}>Tracking</div>
                            <div className={classes.textHead}>Tracking Default</div>
                            <div style={{ width: 105, display: 'flex', justifyContent: 'center' }} >
                                {saveOrderLoading ?
                                    <CircularProgress size={30} style={{ color: '#9DCD2A' }} />
                                    :
                                    orderModified && (
                                        <div style={{ width: 80 }}>
                                            <CustomButton
                                                width='full'
                                                label='save'
                                                onClick={changeOrder}
                                                style={{ minWidth: 80, width: 90 }}
                                            />
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <div style={{ width: 80, display: 'flex', justifyContent: 'center', }}>
                            <IconButton
                                style={{ background: 'rgba(157, 162, 180, 0.08)', padding: 5, height: 40, width: 40 }}
                                onClick={() => {
                                    setAddRoomsOpen(true);
                                }}
                            >
                                <AddRoundedIcon
                                    style={{ color: '#9DA2B4' }}
                                    fontSize='medium'
                                />
                            </IconButton>
                        </div>
                    </div>
                    <Modal
                        style={{ display: "flex" }}
                        open={addRoomsOpen}
                        disableAutoFocus={true}
                    >
                        <AddRoom
                            handleClose={() => {
                                setAddRoomsOpen(false);
                                getPlanById();
                            }}
                        />
                    </Modal>
                </div>
                {loading ? (
                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress size={30} style={{ color: '#9DCD2A' }} />
                    </div>
                ) : (
                    dataRoomsNew.map(room => {
                        const roomId = room.id;
                        const isDefault = defaults && defaults.roomId ? defaults.roomId === roomId : false;

                        if (!roomId) {
                            return null;
                        }
                        return (
                            <div key={roomId}>
                                {(deleteRoom && deleteRoom.roomId === roomId) || (editRoom && editRoom.roomId === roomId) ?
                                    <div
                                        className={classes.listRooms}
                                        style={{ background: deleteRoom ? 'rgba(240, 100, 34, 0.12)' : 'white' }}
                                    >
                                        <div style={{ width: deleteRoom ? '60%' : '80%', display: 'flex', alignItems: 'center' }}>
                                            {deleteRoom ?
                                                <div>
                                                    <p style={{ margin: 0, color: "#EC5829", marginLeft: 10 }}>
                                                        {room.name}
                                                    </p>
                                                </div>
                                                :
                                                <CustomTextField
                                                    value={nameRoom}
                                                    placeholder='Room name'
                                                    onChange={e => {
                                                        setNameRoom(e.target.value);
                                                    }}
                                                    error={nameRoom === '' ? errorField : false}
                                                />
                                            }
                                        </div>
                                        {loadingP ?
                                            <CircularProgress size={30} style={{ color: '#9DCD2A' }} />
                                            :
                                            <div style={{ marginLeft: 10, display: 'flex', alignItems: 'center' }}>
                                                <div style={{ width: 127 }}>
                                                    <CustomButton
                                                        width='full'
                                                        label={deleteRoom ? 'Delete' : 'Save'}
                                                        backgroundred={deleteRoom ? '#F86F46' : ''}
                                                        onClick={
                                                            deleteRoom ?
                                                                subminDeleteRoom({ ...room, roomId })
                                                                :
                                                                subminNewNameRoom({ ...room, roomId })
                                                        }
                                                    />
                                                </div>
                                                <span
                                                    className={classes.subTitle}
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        setEditRoom(null)
                                                        setDeleteRoom(null)
                                                    }}
                                                >
                                                    Cancel
                                                </span>
                                            </div>
                                        }
                                    </div>
                                    :
                                    <div className={classes.listRooms}>
                                        <p style={room.hasOwnProperty('available') && !room.available ? { pointerEvents: 'none', opacity: 0.4 } : {}}>{room.name}</p>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div style={room.hasOwnProperty('available') && !room.available ? { pointerEvents: 'none', opacity: 0.4, display: 'flex', alignItems: 'center' } : { display: 'flex', alignItems: 'center' }}>
                                                <div style={{ width: 100, display: 'flex', justifyContent: 'center' }}>
                                                    <CustomCheckBox
                                                        onClick={handleChange('paging', room)}
                                                        checked={room.paging}
                                                        name='paging'
                                                    />
                                                </div>
                                                <div style={{ width: 2, height: 33, backgroundColor: '#EDEEF0' }} />
                                                <div style={{ width: 100, display: 'flex', justifyContent: 'center', opacity: isDefault ? '0.6' : '' }}>
                                                    <CustomCheckBox
                                                        onClick={handleChange('tracking', room)}
                                                        checked={room.tracking}
                                                        name='Tracking'
                                                        disabled={isDefault}
                                                    />
                                                </div>
                                                <div style={{ width: 2, height: 33, backgroundColor: '#EDEEF0' }} />
                                                <div style={{ width: 100, display: 'flex', justifyContent: 'center' }}>
                                                    <CustomCheckBox
                                                        checked={isDefault}
                                                        onChange={changeDefault(roomId)}
                                                    />
                                                </div>
                                                <div style={{ width: 2, height: 33, backgroundColor: '#EDEEF0' }} />
                                                <div style={{ width: 105, display: 'flex', justifyContent: 'center' }}>
                                                    <IconButton
                                                        style={{ height: 40, width: 40 }}
                                                        onClick={() => {
                                                            moveRoomUp(roomId);
                                                        }}
                                                    >
                                                        <IcArrowUp width='7' height='15' fill='#9DA2B4' />
                                                    </IconButton>
                                                    <IconButton
                                                        style={{ height: 40, width: 40 }}
                                                        onClick={() => {
                                                            moveRoomDown(roomId);
                                                        }}
                                                    >
                                                        <IcArrowDown width='7' height='15' fill='#9DA2B4' />
                                                    </IconButton>
                                                </div>
                                                <div style={{ width: 2, height: 33, backgroundColor: '#EDEEF0' }} />
                                                <IconButton
                                                    style={{ height: 40, width: 40 }}
                                                    onClick={() => {
                                                        setEditRoom({ ...room, roomId });
                                                        setNameRoom(room.name);
                                                        setDeleteRoom(null);
                                                        setLoadingP(false)
                                                    }}
                                                >
                                                    <CreateRounded style={{ color: '#9DA2B4' }} />
                                                </IconButton>
                                            </div>
                                            {!isDefault ? <IconButton
                                                style={{ height: 40, width: 40 }}
                                                onClick={() => {
                                                    setDeleteRoom({ ...room, roomId });
                                                    setEditRoom(null);
                                                    setLoadingP(false)
                                                }}
                                            >
                                                <DeleteRounded style={{ color: '#D8DAE1' }} />
                                            </IconButton>
                                                : <IconButton style={{ height: 40, width: 40 }}></IconButton>}
                                        </div>
                                    </div>
                                }
                            </div>
                        );
                    })
                )}
            </div>
        </div>
    );
}

export default Rooms;

function getSortedRoomIds(rooms) {
    return Object.keys(rooms)
        .filter(roomId => {
            return Object.keys(rooms).includes(roomId);
        })
        .sort((a, b) => {
            if (rooms[a].name > rooms[b].name) {
                return 1;
            }
            if (rooms[a].name < rooms[b].name) {
                return -1;
            }
            return 0;
        })
        .sort((a, b) => {
            const roomA = { ...rooms[a] };
            const roomB = { ...rooms[b] };
            roomA.listPosition = roomA.listPosition ? roomA.listPosition : 0;
            roomB.listPosition = roomB.listPosition ? roomB.listPosition : 0;

            return roomA.listPosition - roomB.listPosition;
        });
}
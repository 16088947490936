import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  makeStyles, IconButton, Divider, Dialog, CircularProgress, Grid
} from '@material-ui/core';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import FileCopyRoundedIcon from '@material-ui/icons/FileCopyRounded';

import insyncHttps from '../../../insyncHttps/insyncHttps';

import { appContext } from '../../../contexts/app';
import { useCouponList, CouponsUserAdminContext, useCouponsChargebee } from './CouponsUserAdminContext';
import CustomButton from '../../CustomButton';
import CustomNavBar from '../../CustomNavBar';
import AddCoupon from './AddCoupon';
import EditCoupon from './EditCoupon';
import { useAuth } from '../../../contexts/v2/auth';

const useStyles = makeStyles({
  infoContainer: {
    background: 'white',
    borderRadius: 4,
    marginTop: 21,
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'SF Pro Display',
    border: '1px solid #EDEEF0',
    padding: 30,
  },
  title: {
    fontSize: 22,
    fontWeight: 'bold',
    margin: 0,
    wordBreak: 'break-all',
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 15
  },
  headInformation: {
    height: 37,
    display: 'flex',
    alignItems: 'center',
    paddingTop: 5,
    paddingBottom: 5,
  },
  bodyInformation: {
    height: 42,
    display: 'flex',
    alignItems: 'center',
    paddingTop: 5,
    paddingBottom: 5,
    borderTop: '1px solid #EDEEF0'
  },
  subTitle: {
    fontSize: 16,
    fontWeight: 'normal',
    margin: 0,
    color: '#979797',
    marginLeft: 10,
    marginRight: 10,
  },
  divContainer: {
    height: 33,
    paddingLeft: 10,
    paddingRight: 10,
    borderRight: '1px solid #EDEEF0',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  textCategory: {
    fontFamily: 'SF Pro Display',
    color: '#979797',
    fontSize: 14,
    fontWeight: 500,
  },
});

function CouponsAdmin() {
  const classes = useStyles();
  const history = useHistory();

  const { isSuperAdmin } = useAuth();
  const useCupons = useCouponList();
  const { couponList, getCouponList } = useCupons;
  const { coupons, getCouponsChargebee } = useCouponsChargebee();
  const { error } = useContext(appContext);

  const [loading, setLoading] = useState(false);
  const [dataCoupon, setDataCoupon] = useState('');
  const [urlSelector, setUrlSelector] = useState('');
  const [deleteCoupon, setDeleteCoupon] = useState('');
  const [addCouponOpen, setAddCouponOpen] = useState(false);
  const [editCouponOpen, setEditCouponOpen] = useState(false);
  // --------------------------------------------

  useEffect(() => {
    getCouponList();
    getCouponsChargebee();
  }, []);

  const copyData = (idCoupon) => () => {
    const cp = `${process.env.REACT_APP_PORTAL_APP_URL}/coupon/${idCoupon}`
    navigator.clipboard.writeText(cp)
    setUrlSelector(idCoupon)
  }

  const subminDeleteCoupon = (couponId) => () => {
    setLoading(true);
    insyncHttps.adminUser.deleteCoupon({ couponId })
      .then(result => {
        useCupons.getCouponList();
        setDeleteCoupon('')
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        error.setMessage(err.message);
      });
  }

  return (
    <CouponsUserAdminContext.Provider value={useCupons}>
      <CustomNavBar tabs={false} />
      <Grid
        container
        style={{ padding: 30, display: 'flex', fontFamily: "SF Pro Display-Bold" }}
        alignItems='center'
        direction='column'
        justifyContent='center'
      >
        <Grid
          item
          xs={11} lg={8} xl={7}
          style={{ width: '100%' }}
        >
          {useCupons.loading ?
            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
              <CircularProgress size={40} style={{ color: '#9DCD2A' }} />
            </div>
            :
            <div className={classes.infoContainer}>
              <div style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: 16
              }}>
                <p className={classes.title}> Coupons </p>
                <div style={{ display: 'flex', alignItems: 'center' }}>

                  <p className={classes.subTitle}>
                    Create new coupon
                  </p>
                  <IconButton
                    style={{ background: 'rgba(157, 162, 180, 0.08)', padding: 5 }}
                    onClick={() => {
                      setAddCouponOpen(true);
                    }}
                  >
                    <AddRoundedIcon style={{ color: '#9DA2B4' }} fontSize='medium' />
                  </IconButton>
                </div>

              </div>
              <Divider style={{ background: '#EDEEF0' }} />
              <div className={classes.headInformation}>
                <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                  <div style={{ width: '25%' }} className={classes.divContainer}>
                    <p className={classes.textCategory}>Title</p>
                  </div>
                  <div style={{ width: '10%' }} className={classes.divContainer}>
                    <p className={classes.textCategory}>Percentage</p>
                  </div>
                  <div style={{ width: '55%' }} className={classes.divContainer}>
                    <p className={classes.textCategory}>Link</p>
                  </div>
                  <div style={{ width: '15%', borderRight: 'none' }} className={classes.divContainer}>
                    <p className={classes.textCategory}>Actions</p>
                  </div>
                </div>
              </div>
              {Object.keys(couponList).map((copId) => {
                const item = couponList[copId]
                return (
                  <div className={classes.bodyInformation} key={copId}>
                    <div style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                      <div style={{ width: '25%' }} className={classes.divContainer}>
                        <p style={{ fontSize: 15 }}>{item.name}</p>
                      </div>
                      <div style={{ width: '10%' }} className={classes.divContainer}>
                        <p style={{ fontSize: 15 }}>{item.percentageDiscount}%</p>
                      </div>
                      <div style={{ width: '55%' }} className={classes.divContainer}>
                        <IconButton onClick={copyData(copId)}>
                          <FileCopyRoundedIcon style={{ color: urlSelector === copId ? '#197FE8' : '#979797' }} />
                        </IconButton >
                        <a
                          style={{ fontSize: 15, color: '#197FE8' }}
                          href={`${process.env.REACT_APP_PORTAL_APP_URL}/coupon/${copId}`}
                          target='_blank'
                        >
                          {`${process.env.REACT_APP_PORTAL_APP_URL}/coupon/${copId}`}
                        </a>
                      </div>
                      {deleteCoupon && deleteCoupon === copId ?
                        <div
                          style={{ width: '15%', borderRight: 'none', justifyContent: 'center' }}
                          className={classes.divContainer}
                        >
                          {loading ?
                            <CircularProgress size={20} style={{ color: '#9DCD2A' }} />
                            :
                            <CustomButton
                              width='full'
                              label={'Delete'}
                              backgroundred={'#F86F46'}
                              height={38}
                              onClick={subminDeleteCoupon(copId)}
                            />
                          }
                        </div>
                        :
                        <div
                          style={{ width: '15%', borderRight: 'none', justifyContent: 'center' }}
                          className={classes.divContainer}
                        >
                          <IconButton
                            style={{ width: 40, height: 40 }}
                            onClick={() => {
                              setEditCouponOpen(true);
                              setDataCoupon(item)
                            }}
                            disabled={loading}
                          >
                            <EditRoundedIcon style={{ color: '#9DA2B4' }} />
                          </IconButton>
                          <IconButton
                            disabled={loading}
                            style={{ width: 40, height: 40 }}
                            onClick={() => {
                              setLoading(false)
                              setDeleteCoupon(copId)
                            }}
                          >
                            <DeleteRoundedIcon style={{ color: '#D8DAE1' }} />
                          </IconButton>
                        </div>
                      }
                    </div>
                  </div>
                )
              })}
              <Dialog open={addCouponOpen}>
                <AddCoupon
                  useCupons={useCupons}
                  coupons={coupons}
                  handleClose={() => {
                    setAddCouponOpen(false);
                  }}
                />
              </Dialog>
              <Dialog open={editCouponOpen}>
                <EditCoupon
                  coupons={coupons}
                  useCupons={useCupons}
                  dataCoupon={dataCoupon}
                  handleClose={() => {
                    setEditCouponOpen(false);
                  }}
                />
              </Dialog>
            </div>
          }
        </Grid>
      </Grid>
    </CouponsUserAdminContext.Provider>

  )
}

export default CouponsAdmin;

import React, { useState, useContext, useEffect } from 'react';

import { makeStyles, Tabs, Tab, Box, Grid } from '@material-ui/core';

import { usePaging, PagingContext } from "./Paging/PagingContext";
import { usePatientTracking, PatientTrackingContext } from "./PatientTracking/PatientTrackingContext";
import { OfficeContext } from "./OfficeContext";
import ErrorStateDisplay from "../../ErrorStateDisplay/ErrorStateDisplay";

import Rooms from './PatientTracking/Rooms';
import MeaningButtons from './Paging';
import Messaging from './Messaging';
import PatientTracking from './PatientTracking';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`scrollable-auto-tabpanel-${index}`}
            aria-labelledby={`scrollable-auto-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}


const useStyles = makeStyles({
    title: {
        fontSize: 24,
        fontWeight: 'bold',
        margin: 0,
        wordBreak: 'break-all',
        fontFamily: 'SF Pro Display',
    },
    customTabs: {
        '& .MuiTab-root': {
            textTransform: 'none',
        },
        '& .MuiTabs-indicator': {
            height: 0,
            backgroundColor: 'transparent',
        }
    },
    customTab: {
        minWidth: 'fit-content',
        marginLeft: 20,
        marginRight: 20,
        borderRadius: 25,
        minHeight: 26,
        paddingTop: 0,
        paddingBottom: 0,
        fontFamily: 'SF Pro Display',
    }
});

function AplicationSettings() {
    const classes = useStyles();
    const { officeId } = useContext(OfficeContext);
    const paging = usePaging(officeId);
    const patientTracking = usePatientTracking(officeId);
    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        paging.getPaging();
        patientTracking.getPatientTracking();
    }, []);

    if (paging.error) {
        return (
            <ErrorStateDisplay
                title="Error Getting Paging"
                message={paging.error}
                retry={paging.getPaging}
            />
        );
    }

    if (patientTracking.error) {
        return (
            <ErrorStateDisplay
                title="Error Getting PatientTracking"
                message={patientTracking.error}
                retry={patientTracking.getPatientTracking}
            />
        );
    }

    return (
        <Grid
            container
            style={{
                paddingBottom: 30,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 20,
            }}
        >
            <Tabs
                value={value}
                onChange={handleChange}
                className={classes.customTabs}
            >
                <Tab
                    value={0}
                    label="Users"
                    className={classes.customTab}
                    style={{
                        background: value === 0 ? '#197FE8' : '#F8F9FB',
                        color: value === 0 ? 'white' : '#979797'
                    }}
                />
                <Tab
                    value={1}
                    label="Rooms"
                    className={classes.customTab}
                    style={{
                        background: value === 1 ? '#197FE8' : '#F8F9FB',
                        color: value === 1 ? 'white' : '#979797'
                    }}
                />
                <Tab
                    value={2}
                    label="Meaning buttons"
                    className={classes.customTab}
                    style={{
                        background: value === 2 ? '#197FE8' : '#F8F9FB',
                        color: value === 2 ? 'white' : '#979797'
                    }}
                />
                <Tab
                    value={3}
                    label="Patient tracking"
                    className={classes.customTab}
                    style={{
                        background: value === 3 ? '#197FE8' : '#F8F9FB',
                        color: value === 3 ? 'white' : '#979797'
                    }}
                />
            </Tabs>
            <Grid
                item
                xs={11} lg={10} xl={9}
                style={{ width: '100%' }}
            >
                <TabPanel value={value} index={0}>
                    <Messaging />
                </TabPanel>
            </Grid>
            <Grid
                item
                xs={11} lg={8} xl={6}
                style={{ width: '80%' }}
            >
                <TabPanel value={value} index={1}>
                    <PatientTrackingContext.Provider value={patientTracking}>
                        <Rooms />
                    </PatientTrackingContext.Provider>
                </TabPanel>
            </Grid>
            <Grid
                item
                xs={11} lg={8} xl={6}
                style={{ width: '80%' }}
            >
                <TabPanel value={value} index={2}>
                    <PagingContext.Provider value={paging}>
                        <MeaningButtons />
                    </PagingContext.Provider>
                </TabPanel>
            </Grid>
            <Grid
                item
                xs={11} lg={8} xl={7}
                style={{ width: '100%' }}
            >
                <TabPanel value={value} index={3}>
                    <PatientTracking />
                </TabPanel>
            </Grid>
        </Grid>
    );
}

export default AplicationSettings;

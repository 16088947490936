import React, { useState, useContext, useEffect } from "react";
import { IconButton, makeStyles, CircularProgress, Modal } from "@material-ui/core";
import DesktopMacRoundedIcon from '@material-ui/icons/DesktopMacRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import RefreshRoundedIcon from '@material-ui/icons/RefreshRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';

import { OfficeContext } from "../OfficeContext";
import { appContext } from "../../../../contexts/app";
import { DevicesContext, useDevices } from "./DevicesContext";

import insyncHttps from "../../../../insyncHttps/insyncHttps";
import ChangeState from './ChangeState';

const useStyles = makeStyles({
   infoContainer: {
      borderRadius: 4,
      paddingTop: 34,
      paddingBottom: 34,
      paddingLeft: 31,
      paddingRight: 26,
      background: 'white',
      border: '1px solid #EDEEF0',
      marginTop: 30,
      userSelect: 'none',
      fontFamily: 'SF Pro Display'
   },
   title: {
      fontSize: 24,
      fontWeight: 'bold',
      margin: 0,
      wordBreak: 'break-all'
   },
   subTitle: {
      fontSize: 16,
      fontWeight: 'normal',
      margin: 0,
      color: '#979797',
      marginLeft: 10
   },
   numberLicenses: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: 25,
      width: 25,
      background: '#F7F8F9',
      color: '#197FE8',
      borderRadius: 15,
   },
   inUseLisc: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingTop: 15,
      paddingBottom: 15,
   },
   containerEditDelete: {
      display: 'flex',
      alignItems: 'center',
      paddingTop: 10,
      paddingBottom: 10,
      justifyContent: 'space-between'
   },
});

export default function (props) {

   const classes = useStyles();
   const { officeId } = useContext(OfficeContext);
   const deviceList = useDevices(officeId);
   const { error } = useContext(appContext);

   const [changeOpen, setChangOpen] = useState(false);
   const [loadingAction, setLoadingAction] = useState(false);
   const [form, setForm] = useState({
      state: false,
      session: false,
      hostname: '',
      user: '',
   });

   useEffect(() => {
      deviceList.getDevices();
   }, []);

   const handleEditDevice = (item) => () => {
      setForm({
         state: item.state,
         session: item.session,
         hostname: item.hostname,
         user: item.user,
      })
      setChangOpen(true)
   }

   const handleChange = (event) => {
      setForm({ ...form, [event.target.name]: event.target.checked });
   };
   const handleSubmit = (event) => {

      const body = {
         officeId,
         ...form
      }
      setLoadingAction(true)
      insyncHttps.insyncUser
         .stateDevice(body)
         .then(result => {
            deviceList.getDevices();
            setLoadingAction(false);
            setChangOpen(false);
         })
         .catch(err => {
            setLoadingAction(false);
            error.setMessage(err.message);
         });
   }
   const refreshDevice = () => deviceList.getDevices();


   return (
      <DevicesContext.Provider value={deviceList}>

         <div className={classes.infoContainer}>
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
               <p className={classes.title} style={{ marginBottom: 10 }}>
                  Devices
               </p>
               <div style={{ display: 'flex', marginBottom: 10 }}>
                  <DesktopMacRoundedIcon style={{ color: '#197FE8' }} />
                  <div className={classes.numberLicenses}>
                     {Object.keys(deviceList.devices).length}
                  </div>
                  <span style={{ marginLeft: 10 }}>Devices </span>
                  <div style={{ marginLeft: 10 }} onClick={refreshDevice}><RefreshRoundedIcon style={{ color: '#197FE8' }} /></div>
               </div>
            </div>
            {deviceList.loading ? (
               <div style={{ display: 'flex', marginTop: 30, justifyContent: 'center' }}>
                  <CircularProgress size={30} style={{ color: '#9DCD2A' }} />
               </div>
            ) : (
               <div>
                  {Object.keys(deviceList.devices).length > 0 ?
                     <DevList devices={deviceList.devices} handleEditDevice={handleEditDevice} />
                     :
                     <p style={{ color: '#515151', fontSize: 20, fontFamily: 'SF Pro Display' }}>
                        No active devices
                     </p>
                  }
                  <Modal
                     style={{ display: "flex" }}
                     open={changeOpen}
                     disableAutoFocus={true}
                  >
                     <ChangeState
                        form={form}
                        loading={loadingAction}
                        handleChange={handleChange}
                        handleSubmit={handleSubmit}
                        handleClose={() => {
                           setChangOpen(false);
                        }}
                     />
                  </Modal>
               </div>
            )}
         </div>
      </DevicesContext.Provider>
   );
}

function DevList(props) {
   const classes = useStyles();

   const { devices, handleEditDevice } = props;

   return (
      <div>
         {Object.keys(devices).map(licenseId => {
            return (
               <div
                  key={licenseId}
                  style={{ borderTop: '1px solid #EDEEF0', fontFamily: 'SF Pro Display', }}
               >
                  <div style={{}}>

                     <div className={classes.inUseLisc}>
                        <div>
                           <p style={{ margin: 0, display: 'flex' }}>
                              <DesktopMacRoundedIcon style={{ color: '#979797' }} />&nbsp;&nbsp;
                              {devices[licenseId].hostname}
                           </p>
                           <p style={{ margin: 0 }}>
                              User: &nbsp;{devices[licenseId].user}
                           </p>
                           <p style={{ margin: 0, display: 'flex' }}>
                              session:&nbsp; {devices[licenseId].session ? (
                                 <span style={{ color: "#9DCD2A" }}> Active </span>
                              ) : (
                                 <span style={{ color: "#EC5829" }}> Inactive </span>
                              )}
                           </p>
                           <p style={{ margin: 0 }}>
                              platform: &nbsp;{devices[licenseId].platform}
                           </p>
                           {/* <p style={{ margin: 0 }}>
                              state: &nbsp; {devices[licenseId].state ? (
                                 <span style={{ color: "#9DCD2A" }}> Active </span>
                              ) : (
                                 <span style={{ color: "#EC5829" }}> Inactive </span>
                              )}
                           </p> */}
                        </div>
                        <div>
                           <IconButton
                              style={{ height: 40, width: 40 }}
                              onClick={handleEditDevice(devices[licenseId])}
                           >
                              <EditRoundedIcon style={{ color: '#9DA2B4' }} />
                           </IconButton>
                        </div>
                     </div>

                  </div>
               </div>
            )
         })}
      </div>
   );
}
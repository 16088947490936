import firebase from "../../firebase";

export default function ({ couponId }) {
  return firebase.functions().httpsCallable("allFunctions")({
    funcRole: "superAdmin",
    funcName: "deleteCoupon",
    data: {
      couponId
    }
  });
}

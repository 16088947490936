import React, { useState, useContext, forwardRef, useEffect } from "react";
import { isEmpty } from "ramda";
import {
    Card,
    CardContent,
    CardActions,
    Divider,
    CircularProgress,
    makeStyles,
} from "@material-ui/core";

import { OfficeContext } from "../../OfficeContext";
import { PatientTrackingContext } from "../PatientTrackingContext";
import { appContext } from "../../../../../contexts/app";
import insyncHttps from "../../../../../insyncHttps/insyncHttps";
import { ingestEncharge } from '../../../../../utils/encharge';
import { useDashboard } from "../../../Dashboard/DashboardContext";

import CustomTextField from "../../../../CustomTextField";
import CustomButton from "../../../../CustomButton";
import { useAuth } from "../../../../../contexts/v2/auth";

const useStyles = makeStyles({
    cardContainer: {
        margin: "auto",
        paddingBottom: 38,
        paddingTop: 28,
        paddingLeft: 20,
        paddingRight: 20,
        minWidth: 400,
        maxWidth: 500,
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
            width: 4,
            height: 0,
        },
        '&::-webkit-scrollbar-track': {
            background: '#f1f1f1',
        },
        '&::-webkit-scrollbar-thumb': {
            background: '#DFE2E2',
            borderRadius: 4,
        },
        '&::-webkit-scrollbar-thumb:hover': {
            background: '#888',
            borderRadius: 4,
        },
        '& .MuiCardContent-root': {
            padding: 0
        }
    },
    title: {
        fontSize: 22,
        fontWeight: 'bold',
        margin: 0,
        wordBreak: 'break-all',
        fontFamily: 'SF Pro Display'
    },
    subTitle: {
        fontSize: 16,
        fontWeight: 'normal',
        margin: 0,
        color: '#979797',
        fontFamily: 'SF Pro Display'
    },
});

const AddRoom = forwardRef((props, ref) => {
    const { handleClose } = props;
    const classes = useStyles();

    const { user } = useAuth();
    const { error } = useContext(appContext);
    const { officeId } = useContext(OfficeContext);
    const { getPatientTracking, rooms: roomsList } = useContext(PatientTrackingContext);
    const { officesMeta, getOfficeList } = useDashboard();

    const [loading, setLoading] = useState(false);
    const [rooms, setRooms] = useState([
        {
            name: ""
        }
    ]);

    useEffect(() => {
        getOfficeList()
    }, []);

    const subminNewRoom = () => {
        setLoading(true);
        setLoading(true);
        if (!isEmpty(roomsList)) {
            insyncHttps.patientTracking
                .addRoomsToOffice({ officeId, rooms })
                .then(async result => {
                    if (result && result.data && result.data.status && result.data.status === 201) {
                        setLoading(false);
                        handleClose();
                        error.setMessage(result.data.message);
                    } else {
                        // ---------------------------------------------------
                        const payloadRegister = {
                            "name": "Registered room",
                            "user": {
                                "email": user.email,
                                // "userId": officeId,
                                "tags": "_room"
                            }
                        }
                        const [first] = Object.keys(officesMeta).map(id => id);
                        if(first === officeId){
                            await ingestEncharge(payloadRegister)
                        }
                        // ---------------------------------------------------
                        getPatientTracking();
                        setLoading(false);
                        handleClose();
                    }
                })
                .catch(err => {
                    console.error(err);
                    setLoading(false);
                    error.setMessage(err.message);
                });
        } else {
            insyncHttps.patientTracking
                .addRoomsToOffice({ officeId, rooms })
                .then(result => {
                    const newRoomId = result.data.roomId

                    insyncHttps.patientTracking
                        .setDefaultRoom({
                            officeId,
                            roomId: newRoomId
                        })
                        .then(result => {
                            getPatientTracking();
                            setLoading(false);
                            handleClose();
                        })
                        .catch(err => {
                            setLoading(false);
                            error.setMessage(err.message);
                        });
                })
                .catch(err => {
                    console.error(err);
                    setLoading(false);
                    error.setMessage(err.message);
                });
        }
    }

    return (
        <Card ref={ref} className={classes.cardContainer}>
            <CardContent>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginLeft: 25,
                        marginRight: 25
                    }}
                >
                    <p style={{ marginBottom: 20 }} className={classes.title}>
                        Add Rooms
                    </p>
                    <p
                        style={{ marginBottom: 20, cursor: 'pointer' }}
                        onClick={handleClose}
                        className={classes.subTitle}
                    >
                        Cancel
                    </p>
                </div>
                <Divider style={{ backgroundColor: '#EDEEF0' }} />
                <div style={{ marginBottom: 46, paddingLeft: 25, paddingRight: 25, fontFamily: 'SF Pro Display' }}>
                    <p style={{ fontSize: 16, marginBottom: 10, textAlign: 'left', marginTop: 30 }}>
                        Name for you new room
                    </p>
                    {rooms.map((room, index) => {
                        return (
                            <Room
                                key={index}
                                room={rooms[index]}
                                handleChange={room => {
                                    const newRooms = [...rooms];
                                    newRooms[index] = { ...room };
                                    setRooms(newRooms);
                                }}
                            />
                        );
                    })}
                </div>
                <Divider style={{ backgroundColor: '#EDEEF0' }} />
            </CardContent>
            <CardActions style={{ marginTop: 32, paddingLeft: 25, paddingRight: 25 }}>
                {loading ?
                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                        <CircularProgress size={30} style={{ color: '#9DCD2A' }} />
                    </div>
                    :
                    <CustomButton
                        disabled={loading}
                        width='full'
                        label='submit'
                        onClick={subminNewRoom}
                    />
                }
            </CardActions>
        </Card>
    );
});
export default AddRoom

function Room(props) {
    const room = props.room;
    const handleChange = props.handleChange;

    return (
        <CustomTextField
            placeholder="Name room"
            value={room.name}
            onChange={e => {
                room.name = e.target.value;
                handleChange(room);
            }}
        />
    );
}
import React, { useContext, useState, useEffect } from 'react';
import { update } from 'ramda';
import { IconButton, makeStyles, CircularProgress, Dialog } from '@material-ui/core';
import VolumeUpRoundedIcon from '@material-ui/icons/VolumeUpRounded';
import PhoneRoundedIcon from '@material-ui/icons/PhoneRounded';
import LockRoundedIcon from '@material-ui/icons/LockRounded';
import LockOpenRoundedIcon from '@material-ui/icons/LockOpenRounded';
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';

import { MessagingContext } from "./MessagingContext";
import { OfficeContext } from "../OfficeContext";
import { PlanContext } from "../PlanContext";
import insyncHttps from "../../../../insyncHttps/insyncHttps";
import { appContext } from "../../../../contexts/app";

import EditDataUser from './EditDataUSer';
import CustomCheckBox from '../../../CustomCheckBox';
import { IcArrowUp, IcArrowDown } from '../../../IconSVG';

const useStyles = makeStyles({
    bodyInformation: {
        height: 74,
        display: 'flex',
        alignItems: 'center',
        paddingTop: 5,
        paddingBottom: 5,
        fontFamily: 'SF Pro Display',
        borderTop: '1px solid #EDEEF0'
    },
    typography: {
        fontSize: 16,
        textAlign: 'center',
        margin: 0
    },
    containerColor: {
        width: '65%',
        height: 30,
        borderRadius: 3,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        fontWeight: 'bold',
        fontSize: 12,
        boxShadow: '0px 1px 1px rgba(0, 0, 0, 0.25)',
        paddingLeft: 10,
        paddingRight: 10
    },
    divContainer: {
        height: 33,
        paddingLeft: 10,
        paddingRight: 10,
        borderRight: '1px solid #EDEEF0',
        display: 'flex',
        alignItems: 'center',
    },
    icons: {
        color: '#9DA2B4',
        marginRight: 5
    }
});

function BodyInformation(props) {
    const { orderedUserIds, moveUserDown, moveUserUp } = props;
    const classes = useStyles();

    const { users, loading, getMessaging } = useContext(MessagingContext);
    const { error } = useContext(appContext);
    const { officeId } = useContext(OfficeContext);
    const { getPlanById } = useContext(PlanContext);

    const [dialogEdit, setDialogEdit] = useState(false);
    const [dataUser, setDataUser] = useState(false);
    const [dataNew, setDataNew] = useState([]);

    useEffect(() => {

        if (orderedUserIds && orderedUserIds.length > 0) {
            const resul = orderedUserIds.map(t => ({
                ...users[t],
                paging: (users[t] && users[t].hasOwnProperty('type') && users[t].type === 'ambos') || users[t].type === 'paging' ? true : false,
                messaging: (users[t] && users[t].hasOwnProperty('type') && users[t].type === 'ambos') || users[t].type === 'messaging' ? true : false

            }))
            setDataNew(resul)
        } else {
            setDataNew([])
        }
    }, [orderedUserIds])

    const deleteUSer = (userId) => () => {
        insyncHttps.messaging
            .removeUser({ officeId, userId })
            .then(result => {
                getPlanById();
                getMessaging();
            })
            .catch(err => {
                console.error(err);
                error.setMessage(err.message);
            });
    }

    const handleChange = (prop, item) => (event) => {
        const indexArray = dataNew.findIndex((t) => t.id === item.id);
        let newDataArray = { ...item, [prop]: event.target.checked };
        const newAdd = update(indexArray, newDataArray, dataNew)
        setDataNew(newAdd)
        const result = (prop === 'paging' && event.target.checked && !item.messaging) || (prop === 'messaging' && !event.target.checked && item.paging) ? 'paging' :
            (prop === 'messaging' && event.target.checked && !item.paging) || (prop === 'paging' && !event.target.checked && item.messaging) ? 'messaging' :
                (prop === 'messaging' && event.target.checked && item.paging) || (prop === 'paging' && event.target.checked && item.messaging) ? 'ambos' :
                    ''
        insyncHttps.messaging
            .updateUser({
                ...item,
                officeId,
                userId: item.id,
                type: result
            })
            .then(result => { getMessaging() })
            .catch(err => { error.setMessage(err.message) });
    };

    return (
        <div>
            {loading ?
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress size={30} style={{ color: '#9DCD2A' }} />
                </div>
                :
                dataNew.map(user => {

                    return (
                        <div className={classes.bodyInformation} key={user.id}>
                            <div style={user.hasOwnProperty('available') && !user.available ? { pointerEvents: 'none', opacity: 0.4, display: 'flex', alignItems: 'center', width: '100%' } : { display: 'flex', alignItems: 'center', width: '100%' }}>

                                <div style={{ width: '20%' }} className={classes.divContainer}>
                                    <div className={classes.typography}>
                                        {user && user.name ?
                                            <span>{user.name}</span> : ''
                                        }
                                        {user && user.lastName ?
                                            <span style={{ marginLeft: 10 }}>{user.lastName}</span> : ''
                                        }
                                    </div>
                                </div>

                                <div
                                    className={classes.divContainer}
                                    style={{ justifyContent: 'center', width: '8%' }}
                                >
                                    <p className={classes.typography}>
                                        {user && user.initials ? user.initials : ''}
                                    </p>
                                </div>

                                <div
                                    className={classes.divContainer}
                                    style={{ justifyContent: 'center', width: '8%' }}
                                >
                                    <div
                                        className={classes.containerColor}
                                        style={{ background: user && user.bgColor ? user.bgColor : '#9DA2B4' }}
                                    />
                                </div>

                                <div
                                    className={classes.divContainer}
                                    style={{ justifyContent: 'center', width: '5%' }}
                                >
                                    <VolumeUpRoundedIcon className={classes.icons} />
                                    <p className={classes.typography}>
                                        {user && user.soundId ? user.soundId : ''}
                                    </p>
                                </div>

                                <div
                                    className={classes.divContainer}
                                    style={{ justifyContent: 'center', width: '15%' }}
                                >
                                    <PhoneRoundedIcon className={classes.icons} />
                                    <p className={classes.typography}>
                                        {user && user.phoneNumber ? user.phoneNumber : ''}
                                    </p>
                                </div>

                                <div
                                    className={classes.divContainer}
                                    style={{
                                        justifyContent: 'center', width: '6%', minWidth: 50
                                    }}
                                >
                                    <CustomCheckBox
                                        onClick={handleChange('paging', user)}
                                        checked={user.paging}
                                        name='paging'
                                    />
                                </div>

                                <div
                                    className={classes.divContainer}
                                    style={{ justifyContent: 'center', width: '6%', minWidth: 50 }}
                                >
                                    <CustomCheckBox
                                        onClick={handleChange('messaging', user)}
                                        checked={user.messaging}
                                        name='messaging'
                                    />
                                </div>

                                <div
                                    style={{ justifyContent: 'center', width: '6%', minWidth: 60 }}
                                    className={classes.divContainer}
                                >
                                    {user && user.passwordProtected ?
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <LockRoundedIcon className={classes.icons} />
                                            <p className={classes.typography} style={{ color: '#979797' }} >
                                                ****
                                            </p>
                                        </div>
                                        :
                                        <LockOpenRoundedIcon className={classes.icons} />
                                    }
                                </div>

                                <div
                                    style={{ justifyContent: 'center', width: '6%', minWidth: 70 }}
                                    className={classes.divContainer}
                                >
                                    <IconButton
                                        style={{ width: 40, height: 40 }}
                                        onClick={() => {
                                            moveUserUp(user.id);
                                        }}
                                    >
                                        <IcArrowUp
                                            width='7'
                                            height='15'
                                            fill={'#9DA2B4'}
                                        />
                                    </IconButton>
                                    <IconButton
                                        style={{ width: 40, height: 40 }}
                                        onClick={() => {
                                            moveUserDown(user.id);
                                        }}
                                    >
                                        <IcArrowDown
                                            width='7'
                                            height='15'
                                            fill='#9DA2B4'
                                        />
                                    </IconButton>
                                </div>
                            </div>
                            <div
                                style={{ borderRight: 'none', justifyContent: 'center', width: '6%', minWidth: 70 }}
                                className={classes.divContainer}
                            >
                                <IconButton
                                    style={user.hasOwnProperty('available') && !user.available ? { pointerEvents: 'none', opacity: 0.4, width: 40, height: 40 } : { width: 40, height: 40 }}
                                    onClick={() => {
                                        setDialogEdit(true);
                                        setDataUser(user)
                                    }}
                                >
                                    <EditRoundedIcon style={{ color: '#9DA2B4' }} />
                                </IconButton>
                                <IconButton
                                    style={{ width: 40, height: 40 }}
                                    onClick={deleteUSer(user.id)}
                                >
                                    <DeleteRoundedIcon style={{ color: '#D8DAE1' }} />
                                </IconButton>
                            </div>

                        </div>
                    )
                })
            }
            <Dialog
                open={dialogEdit}
            >
                <EditDataUser
                    handleClose={() => {
                        setDialogEdit(false);
                    }}
                    dataUser={dataUser}
                />
            </Dialog>
        </div>
    );
}

export default BodyInformation;

import React, { useEffect, useContext, useState } from "react";

import {
  makeStyles, Divider, Dialog, CircularProgress, Grid, Avatar,
  Button, useMediaQuery, Modal,
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import AllInclusiveIcon from '@material-ui/icons/AllInclusive';
import { useHistory } from 'react-router-dom';

import { PlanDetailsContext, usePlansList } from "./PlanDetailsContext";
import { PlanContext } from "../PlanContext";
import { OfficeContext } from "../OfficeContext";
import { appContext } from '../../../../contexts/app';
import { usePlansChargebee, usePaymentSources, useSubscriptions } from "../Billing/BillingContext";
import { mainRouterHistory } from "../../../../routerHistories";
import ErrorStateDisplay from "../../../ErrorStateDisplay/ErrorStateDisplay";
import insyncHttps from "../../../../insyncHttps/insyncHttps";

import CustomButton from '../../../CustomButton';
import CustomDialogActions from '../../../CustomDialogActions';
import DialogCustomPlan from './DialogCustomPlan';
import { isEmpty } from "ramda";
import { useAuth } from "../../../../contexts/v2/auth";

const useStyles = makeStyles({

  populated: {
    // boxShadow: '0px 0px 10px 0px rgb(0 0 0 / 50%)',
    margin: '10px 10px 10px 10px',
    padding: '30px 20px 30px 20px',
    borderRadius: 16
  },
  buttonLink: {
    fontFamily: "SF Pro Display",
    fontSize: 16,
    fontWeight: 500,
    backgroundColor: '#FF5C35',
    borderRadius: '7px 7px 7px 7px',
    padding: '10px 20px 10px 20px',
    textDecoration: 'inherit',
    color: '#fff'
  },
  customBubble: {
    backgroundColor: 'transparent',
    fontSize: 16,
    height: 24,
    fontWeight: 700,
    color: '#fff',
    marginRight: 4,
    fontFamily: "SF Pro Display",

    '& .interntal': {
      display: 'flex',
      alignItems: 'flex-start',
      padding: '3px 7px 3px 7px',
      borderRadius: 28,
    }
  },
});

function Plans() {
  const classes = useStyles();
  // --------------------------------------------
  const { officeId } = useContext(OfficeContext);
  const history = useHistory();
  const auth = useAuth();
  const { error } = useContext(appContext);
  const usePlans = usePlansList();
  const plansChargebee = usePlansChargebee();
  const paymentSources = usePaymentSources(officeId);
  const subscriptions = useSubscriptions(officeId);
  // --------------------------------------------
  const { getPlanById, planItem, errorPlan } = useContext(PlanContext);
  // --------------------------------------------
  const lgDown = useMediaQuery('(max-width:1280px)');
  const mdDown = useMediaQuery('(max-width:960px)');
  const smDown = useMediaQuery('(max-width:600px)');
  // --------------------------------------------
  const [dialogPlan, setDialogPlan] = useState(false);
  const [dataInitPlan, setDataInitPlan] = useState([]);
  const [dialogPayment, setDialogPayment] = useState(false);
  const [loadingSub, setLoadingSub] = useState(false);
  // --------------------------------------------

  useEffect(() => {
    usePlans.getListPlans();
    getPlanById();
    plansChargebee.getPlans();
    paymentSources.getSources();
    subscriptions.getSubs();
  }, []);

  useEffect(() => {
    function fetch() {
      if (usePlans.planList.length > 0 && !isEmpty(planItem) && planItem.planId !== '') {
        const data = usePlans.planList.map(t => ({
          ...t,
          selected: ((t.id === planItem.planId) || (planItem.customPlan && t.name === 'Enterprise') || (planItem.showBannerTrial && t.name === 'Enterprise'))
            ? true : false,
          isPayment: (planItem.showBannerTrial && t.name === 'Enterprise') ? false : true
        }));
        setDataInitPlan(data)
      } else if (usePlans.planList.length > 0 && !isEmpty(planItem) && planItem.planId === '') {
        const data = usePlans.planList.map(t => ({...t, isPayment: (planItem.showBannerTrial && t.name === 'Enterprise') ? false : true }));
        setDataInitPlan(data)
      } else {
        setDataInitPlan([])
      }

    }
    fetch();
  }, [usePlans.planList, planItem]);

  const subminPlan = (plan) => () => {
    const subscriptionId = subscriptions.subs && subscriptions.subs.length > 0 ? subscriptions.subs[0].id : '';
    if (plan.isInitial && plan.price === '0') {
      setLoadingSub(true);
      insyncHttps.billing
        .updateSubscription({
          chargebeePlanId: plan.chargebeePlanId,
          planQuantity: 1,
          planId: plan.id,
          officeId,
          subscriptionId,
          renew: false
        })
        .then(result => {
          setLoadingSub(false);
          history.push(`/office/${officeId}/billing`);
        })
        .catch(err => {
          error.setMessage(err.message);
          setLoadingSub(false);
        });
    } else {
      if (paymentSources.sources.length > 0) {
        setLoadingSub(true);
        insyncHttps.billing
          .updateSubscription({
            chargebeePlanId: plan.chargebeePlanId,
            planQuantity: 1,
            planId: plan.id,
            officeId,
            subscriptionId,
            renew: false
          })
          .then(result => {
            setLoadingSub(false);
            history.push(`/office/${officeId}/billing`);
          })
          .catch(err => {
            error.setMessage(err.message);
            setLoadingSub(false);
          });
      } else {
        setDialogPayment(true);
      }
    }
  }

  const onCustomPlan = () => {
    if (paymentSources.sources.length > 0) {
      setDialogPlan(true);
    } else {
      setDialogPayment(true);
    }
  }

  return (
    <PlanDetailsContext.Provider value={usePlans}>
      <Grid
        container
        style={{
          paddingBottom: 30,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 20,
        }}
      >
        <Grid
          item
          xs={11} lg={10} xl={9}
          style={{ width: '100%' }}
        >
          <div style={{ display: 'flex', flexFlow: 'row wrap', flexDirection: smDown ? 'column' : 'row', marginRight: 'auto', marginLeft: 'auto', position: 'relative' }}>

            {!usePlans.loadingPlans && dataInitPlan.length > 0 && dataInitPlan.map((t, index) => (
              <div key={index} style={{ width: smDown ? '100%' : mdDown ? '50%' : lgDown ? '25%' : '20%' }}>
                <div className={classes.populated} style={{ border: t.selected ? '4px solid #9DCD2A' : '4px solid #EDEEF0' }}>
                  <div style={{ marginBottom: 20, textAlign: 'let' }}>
                    <h2 style={{ color: '#3A3E4B', fontFamily: "SF Pro Display-Bold", fontStyle: 'normal', fontSize: 32, fontWeight: 900, margin: 0 }}>{t.name}</h2>
                  </div>
                  {t.selected && (
                    <div style={{ marginBottom: 30, textAlign: 'let' }}>
                      <h2 style={{ color: '#9DCD2A', fontFamily: "SF Pro Display-Bold", fontStyle: 'normal', fontSize: 16, fontWeight: 900, margin: 0 }}>Your current plan</h2>
                    </div>
                  )}
                  <div style={{ marginBottom: 20, textAlign: 'left' }}>
                    <h2 style={{ color: '#3A3E4B', fontFamily: "SF Pro Display-Bold", fontSize: 16, fontWeight: 900, margin: 0 }}>Paging / Messaging</h2>
                  </div>
                  <section style={{ width: '100%', marginTop: 6 }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Avatar className={classes.customBubble}>
                        <div className='interntal' style={{ backgroundColor: t.selected ? '#9DCD2A' : '#9DA2B4' }}>
                          {t.unlimited_users ? <AllInclusiveIcon style={{ width: "0.7em", height: "0.7em" }} /> : t.users}
                        </div></Avatar>
                      <div>
                        <h2 style={{ color: '#848B8D', fontFamily: "SF Pro Display", fontSize: 16, fontWeight: 400, margin: 0 }}>Users</h2>
                      </div>
                    </div>
                  </section>
                  <section style={{ width: '100%', marginTop: 6 }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Avatar className={classes.customBubble}>
                        <div className='interntal' style={{ backgroundColor: t.selected ? '#9DCD2A' : '#9DA2B4' }}>
                          {t.unlimited_rooms ? <AllInclusiveIcon style={{ width: "0.7em", height: "0.7em" }} /> : t.rooms}
                        </div>
                      </Avatar>
                      <div>
                        <h2 style={{ color: '#848B8D', fontFamily: "SF Pro Display", fontSize: 16, fontWeight: 400, margin: 0 }}>Rooms</h2>
                      </div>
                    </div>
                  </section>
                  <div style={{ marginBottom: 20, marginTop: 15, textAlign: 'left' }}>
                    <h2 style={{ color: '#3A3E4B', fontFamily: "SF Pro Display-Bold", fontSize: 16, fontWeight: 900, margin: 0 }}>Patient Tracking</h2>
                  </div>
                  <section>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Avatar className={classes.customBubble}><div className='interntal' style={{ backgroundColor: t.selected ? '#9DCD2A' : '#9DA2B4' }}>{t.unlimited_stages ? <AllInclusiveIcon style={{ width: "0.7em", height: "0.7em" }} /> : t.stages}</div></Avatar>
                      <div>
                        <h2 style={{ color: '#848B8D', fontFamily: "SF Pro Display", fontSize: 16, fontWeight: 400, margin: 0 }}>Tracking Stage</h2>
                      </div>
                    </div>
                  </section>
                  <div style={{ marginBottom: 20, marginTop: 15, textAlign: 'left' }}>
                    <h2 style={{ color: '#3A3E4B', fontFamily: "SF Pro Display-Bold", fontSize: 16, fontWeight: 900, margin: 0 }}>Concurrent Connections</h2>
                  </div>
                  <section>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Avatar className={classes.customBubble}>
                        <div className='interntal' style={{ backgroundColor: t.selected ? '#9DCD2A' : '#9DA2B4' }}>
                          {t.unlimited_connections ? <AllInclusiveIcon style={{ width: "0.7em", height: "0.7em" }} /> : t.connections}
                        </div>
                      </Avatar>
                      <div>
                        <h2 style={{ color: '#848B8D', fontFamily: "SF Pro Display", fontSize: 16, fontWeight: 400, margin: 0 }}>Connections</h2>
                      </div>
                    </div>
                  </section>
                  <div style={{ marginBottom: (!t.isPayment) ? 5: 10 }}>
                    <div style={{ paddingTop: 15, paddingBottom: 15 }}>
                      <Divider style={{ color: '#C9CBD947' }} />
                    </div>
                  </div>
                  <section style={{ marginBottom: 20, height: (!t.isPayment) ? 2: 45, display: 'flex' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      {t.price !== '' ?
                        <>
                          <div style={{ marginRight: 7, fontFamily: "SF Pro Display-Bold" }}>
                            {/* <img width="37" height="34" src="https://insyncapp.io/wp-content/uploads/2022/05/Features-1.png" class="attachment-large size-large" alt="" loading="lazy" />															 */}
                            <sup style={{ fontSize: 16, verticalAlign: 'sub' }}>$</sup><span style={{ fontSize: 45 }}>{t.price}</span>
                          </div>
                          <div>
                            <h2 style={{ color: '#3A3E4B', fontFamily: "SF Pro Display", fontSize: 16, fontWeight: 400, margin: 0 }}>License <br />
                              per month</h2>
                          </div>
                        </>
                        :
                        <div style={{ marginRight: 7, fontFamily: "SF Pro Display-Bold" }}>
                          <span style={{ fontSize: 27 }}>Call for Pricing</span>
                        </div>
                      }
                    </div>
                  </section>
                  <div style={{ marginBottom: 10, textAlign: 'center' }}>
                    {(!loadingSub && !t.selected && t.price !== '') && (
                      <Button
                        variant='outlined'
                        style={{ width: '100%', textTransform: 'inherit', border: '2px solid #197FE8', borderRadius: 8, color: '#197FE8', fontSize: 16, fontFamily: "SF Pro Display" }}
                        onClick={subminPlan(t)}
                        endIcon={<ArrowForwardIcon />}
                      >
                        Choose plan
                      </Button>
                    )}
                    {((!loadingSub && !t.selected && t.price === '') || (!loadingSub && !t.isPayment)) && (
                      <Button
                        href='https://insyncapp.io'
                        target="_blank"
                        role="button"
                        variant='outlined'
                        style={{ width: '100%', textTransform: 'inherit', border: '2px solid #197FE8', borderRadius: 8, color: '#197FE8', fontSize: 16, fontFamily: "SF Pro Display" }}
                        // onClick={subminPlan(t)}
                        endIcon={<ArrowForwardIcon />}
                      >
                        Contact US
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            ))}

            {loadingSub && (
              <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <CircularProgress size={40} style={{ color: '#9DCD2A', marginRight: 5 }} /> Processing ...
              </div>
            )}

            {usePlans.loadingPlans && dataInitPlan.length === 0 && (
              <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <CircularProgress size={40} style={{ color: '#9DCD2A' }} />
              </div>
            )}
            {usePlans.errorPlans && (
              <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <ErrorStateDisplay
                  title="Error Getting Plans List"
                  message={usePlans.errorPlans}
                  retry={usePlans.getListPlans}
                />
              </div>
            )}
            {errorPlan && (
              <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <ErrorStateDisplay
                  title="Error Getting Plan by Id"
                  message={errorPlan}
                  retry={getPlanById}
                />
              </div>
            )}

          </div>
        </Grid>
        <Grid
          item
          xs={11} lg={3} xl={3}
          style={{ marginTop: 30 }}
        >
          {!usePlans.loadingPlans && dataInitPlan.length > 0 && auth.isSuperAdmin && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <CustomButton
                label='Custom Plan'
                onClick={onCustomPlan}
                width='full'
              />
            </div>
          )}
          {dialogPlan && (
            <Dialog
              open={dialogPlan}
            >
              <Grid container justifyContent="center" align="center">
                <Grid item xs={12}>
                  <DialogCustomPlan
                    plansChargebee={plansChargebee}
                    subscriptions={subscriptions}
                    handleClose={() => {
                      setDialogPlan(false);
                      history.push(`/office/${officeId}/billing`);
                    }}
                  />
                </Grid>
              </Grid>
            </Dialog>
          )}
          {dialogPayment && (
            <Modal
              style={{ display: "flex" }}
              open={dialogPayment}
              onClose={() => {
                setDialogPayment(false);
              }}
            >
              <CustomDialogActions
                title="Payment Sources"
                description="No payment sources have been created"
                titleAccept="Go to create"
                titleCancel="Cancel"
                handleAction={() => {
                  history.push(`/office/${officeId}/billing`);
                }}
                handleClose={() => {
                  setDialogPayment(false);
                }}
                loading={false}
              />
            </Modal>
          )}
        </Grid>
      </Grid>
    </PlanDetailsContext.Provider>
  );
}

export default Plans;